import { Descriptions, Spin } from 'antd';
import React, { Component } from 'react';
import _ from 'lodash';
import { IsSamlLoginEnabled, getAuth0, getUserInfo } from '../../../utils/authservice';

class UserInfo extends Component {
    state = {
        userLoading: false,
        userInfo: null,
    }

    getUserInfo = async () => {
        this.setState({
            userLoading: true
        })
        try {
            const samlEnabled = IsSamlLoginEnabled();
            let userInfo = {};
            if (samlEnabled) {
                userInfo = getUserInfo();
            } else {
                const auth0 = await getAuth0();
                userInfo = await auth0.getUser();
            }
            this.setState({
                userInfo,
            })
        } catch (err) {
            console.error(err);
        } finally {
            this.setState({
                userLoading: false,
            });
        }
    }

    componentDidMount() {
        this.getUserInfo();
    }

    render() {
        const { userInfo, userLoading } = this.state;
        return (
            <div style={{ maxWidth: "400px" }}>
                {userLoading ? <Spin></Spin> : (
                    <Descriptions title="User Info" bordered column={1} size="small">
                        <Descriptions.Item label="Name">{_.get(userInfo, "name")}</Descriptions.Item>
                        <Descriptions.Item label="Email">{_.get(userInfo, "email")}</Descriptions.Item>
                        <Descriptions.Item label="Role">{_.get(userInfo, "http://role_name")}</Descriptions.Item>
                    </Descriptions>
                )}
            </div>
        );
    }
}

export default UserInfo;