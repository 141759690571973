import React, { Component } from "react";
import { Button, Flex, Select, Space } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/ext-language_tools";
import _ from "lodash";
import { PostRequest, PostRequestResolve } from "../../../utils/FetchHelper";
import { Message } from "../../../variables/general";
import URL from "../../../utils/url";
import SqlTable from "../SqlTable";
import "./Console.scss";
import DeploymentContext from "../../../common/ContextWrappers/Deployment/Context";
import DashboardContext from "../../../common/ContextWrappers/Dashboard/Context";
import withContext from "../../../hoc/withContext";
import { getDashboardHeader } from "../../../utils/globalDashboardUtils";

const { Option } = Select;

const ALL_DASHBOARD_OPTION = "all dashboards";
const ALL_DATABASE_OPTION = "all tenants";

class Console extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorValue: "",
      isLoading: false,
      result: [],
      database: ALL_DATABASE_OPTION,
      databaseList: [ALL_DATABASE_OPTION],
    };
  }

  controller = new window.AbortController();

  componentDidMount() {
    const { onChangeCallback } = this.props;
    onChangeCallback(this.onDeploymentSelect);
    this.onDeploymentSelect();
  }

  componentWillUnmount() {
    this.controller.abort();
    const { clearOnChangeCallback } = this.props;
    clearOnChangeCallback();
  }


  onDeploymentSelect = (value) => {
    const { selectedDeployment, isGlobalDashboard } = this.props;
    if (selectedDeployment) {
      this.setState({
        databaseList: [...(isGlobalDashboard ? [ALL_DASHBOARD_OPTION] : []), ALL_DATABASE_OPTION],
      }, () => {
        this.getDatabaseList();
      });
    }
  }

  getDatabaseList = async () => {
    const { selectedDeployment } = this.props;
    const payload = { serverDeployment: selectedDeployment };
    PostRequest(
      URL.listAccountNames,
      this.controller.signal,
      payload,
      (result) => {
        this.setState((prevState) => ({
          ...prevState,
          databaseList: [...prevState.databaseList, ...result],
        }));
      },
      (error) => {
        this.setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        Message.error("Error Listing Databases", _.get(error, "message", ""));
      }
    );
  }

  onChange = (editorValue) => {
    this.setState({ editorValue: editorValue });
  };

  onExecute = async () => {
    try {

      this.setState({ isLoading: true, result: "", columns: [] });
      const { editorValue, database } = this.state;
      const { selectedDeployment } = this.props;
      let finalResult = [];
      let headerKeys = new Set([]);;
      if (database === ALL_DASHBOARD_OPTION) {
        const { dashboardList } = this.props;
        const promises = [];
        _.forEach(dashboardList, d => {
          const deployment = _.get(d, "details.deploymentInfo.name");
          // if (!deployment) {
          //   return
          // }
          const payload = {
            query: editorValue,
            db: ALL_DATABASE_OPTION,
            serverDeployment: deployment,
          };
          promises.push(PostRequestResolve(
            URL.executeQuery,
            this.controller.signal,
            payload,
            getDashboardHeader(d)
          ));
        })
        headerKeys.add("Dashboard")
        headerKeys.add("Account")
        const results = await Promise.all(promises);
        if (_.isArray(results)) {
          _.forEach(results, (result, i) => {
            if (_.isArray(result)) {
              for (const k of _.keys(_.get(result, "[0]", []))) {
                headerKeys.add(k);
              }
              _.forEach(result, (r, index) => {
                finalResult.push(
                  { "Dashboard": _.get(dashboardList, [i, "name"]), "Row ID": index, ...r }
                );
              });
            } else {
              try {
                for (const k of _.keys(result)) {
                  headerKeys.add(k);
                }
              }
              catch (err) {
                console.log(err);
              }
              finalResult.push(
                {
                  "Dashboard": _.get(dashboardList, [i, "name"]),
                  ...result,
                }
              );
            }
          })
        }
      } else {
        const payload = {
          query: editorValue,
          db: database,
          serverDeployment: selectedDeployment,
        };
        const result = await PostRequestResolve(
          URL.executeQuery,
          this.controller.signal,
          payload
        );
        if (result && !_.get(result, "status", true)) {
          throw result
        }
        if (result && Array.isArray(result)) {
          finalResult = result.map((r, index) => {
            return { "Row ID": index, ...r };
          })
        }
        headerKeys.add("Account");
        for (const k of _.keys(_.get(result, "[0]", []))) {
          headerKeys.add(k);
        }
      }
      const columns = [];
      for (const m of headerKeys.keys()) {
        columns.push({
          title: this.titleParser(m),
          dataIndex: m,
          key: m
        });
      }
      this.setState({
        result: finalResult,
        columns,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      Message.error("Error Querying DB", _.get(error, "message", ""));
    }
  };

  titleParser(str) {
    var splitStr = str.toLowerCase().replaceAll('_', ' ').split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }


  onDbSelect(value) {
    this.setState((prevState) => ({
      ...prevState,
      database: value,
    }));
  }

  render() {
    const { editorValue, isLoading, result, databaseList, columns = [] } = this.state;
    return (
      <div className="console-container">
        <Flex style={{ paddingBottom: "8px" }}>
          <span style={{ margin: "auto 8px auto 0px"}}>
            Select Database
          </span>
          <Select
            showSearch
            className="db-selector"
            placeholder={databaseList.length === 1 ? "Loading..." : "Select Database"}
            optionFilterProp="children"
            disabled={databaseList.length === 1}
            onChange={(e) => this.onDbSelect(e)}
            defaultValue={ALL_DATABASE_OPTION}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {databaseList.map((name) => <Option value={name}>{name}</Option>)}
          </Select>
          <Space style={{ marginLeft: "auto" }}>
            <Button type="primary" shape="circle" iconPosition="end" icon={<CaretRightOutlined />} onClick={this.onExecute.bind(this)} loading={isLoading}/>
          </Space>
        </Flex>
        <div className="console-div">
          <AceEditor
            mode="mysql"
            theme="chrome"
            value={editorValue}
            onChange={this.onChange.bind(this)}
            name="console"
            editorProps={{ $blockScrolling: true }}
            width="100%"
            height="100%"
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              fontSize: 22,
            }}
            wrapEnabled
          />
        </div>
        <div className="stdout">
          {isLoading ? (
            "Executing..."
          ) : (
            <SqlTable columns={columns} data={result} />
          )}
        </div>
      </div>
    );
  }
}

export default withContext(withContext(Console, DeploymentContext), DashboardContext);
