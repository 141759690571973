import { Button, Tag } from "antd";
import { ExportOutlined } from '@ant-design/icons';

import _ from "lodash";

export const columns = [
    {
        title: "Id",
        dataIndex: "id",
        key: "id"
    },
    {
        title: "Status",
        key: "deploymentName",
        render: (i, d) => (_.get(d, "details.deploymentInfo.name") ? <Tag color="green">Live</Tag> : <Tag color="red">Ping Failed</Tag>)
    },
    {
        title: "Dashboard Name",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "API Endpoint",
        key: "apiEndpoint",
        dataIndex: "apiEndpoint",
        render: (value) => (
            <div>
                {<Button type="text" href={value}>{value} <ExportOutlined /></Button>}
            </div>
        )
    },
];
