import React, {Component} from 'react';
import {Card, Form, Input, Space} from "antd";
import CustomButton from "../../common/CustomButton/Button";
import RoutePaths from "../../utils/RoutePaths";


class CreateImage extends Component {
    state = {
        imageName: "",
        description: "",
        imagePath: "",
    };


    controller = new window.AbortController();

    componentWillUnmount() {
        this.controller.abort();
    }

    onFormFieldChange = (e) => {
        const {name, value} = e.target;
        this.setState(prevState => ({
            ...prevState,
            [name]: value,
        }))
    };

    createImage = () => {
        const {imageName, description, imagePath } = this.state;
        console.log("creating.", imageName, description, imagePath);
    };

    render() {
        const {imageName, description, imagePath } = this.state;
        return (
            <Card style={{ width: 600, margin: "32px auto" }}>
                <Form
                    name="basic"
                    title="accounts"
                    onFinish={this.createImage} i
                >
                    <Form.Item
                        name="NameField"
                        rules={[{ required: true, message: 'Please input your image name!' }]}
                    >
                        <Input
                            name="imageName"
                            placeholder="Image Name*"
                            value={imageName}
                            onChange={this.onFormFieldChange}
                            size={"large"}
                        />
                    </Form.Item>

                    <Form.Item
                        name="descriptionField"
                        rules={[{ required: true, message: 'Please input server deployment!' }]}
                    >
                        <Input
                            name="description"
                            placeholder="Description*"
                            value={description}
                            onChange={this.onFormFieldChange}
                            size={"large"}
                        />
                    </Form.Item>

                    <Form.Item
                        name="imagePathField"
                        rules={[{ required: true, message: 'Please input server deployment!' }]}
                    >
                        <Input
                            name="imagePath"
                            placeholder="Image Path*"
                            value={imagePath}
                            onChange={this.onFormFieldChange}
                            size={"large"}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <CustomButton
                                type="primary" htmlType="submit"
                            >
                                Create Image
                            </CustomButton>
                            <CustomButton
                                type="link"
                                href={RoutePaths.defaultImageRegistry}
                            >
                                Go back
                            </CustomButton>
                        </Space>
                    </Form.Item>
                </Form>
            </Card>
        );
    }
}

export default CreateImage;