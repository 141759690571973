import { createBrowserHistory } from 'history';

// Allows us access from other components without having to pass props
const History = createBrowserHistory();

export default History;

export const updateURLParam = (name, value) => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set(name, value);
  History.replace(`${window.location.pathname}?${urlParams.toString()}`);
};

export const getURLParam = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

// Persist single param
const filterHelper = (key) => getURLParam(key) ? `&${key}=${getURLParam(key)}` : ''

// Persist mutiple params
const getMultiplefilters = (filters) => filters.map(key => filterHelper(key)).join('')

// Persist query, page and size params if they exist
export const replaceURLParam = (name, value) => {
  if (getURLParam(name) === name) History.goBack();
  const urlParams = new URLSearchParams(window.location.search);
  let updatedParam = "";
    if (name === "query" || name === "page" || name === "size") {
      urlParams.set(name, value);
      updatedParam = urlParams.toString();
  } else {
    // PLEASE DO NOT CHANGE --> Contains filters to persist params
    updatedParam = `${name}=${value}${getMultiplefilters(["query", "page", "size"])}`;
  }
  History.replace(`${window.location.pathname}?${updatedParam}`);
};

export const deleteURLParam = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete(name);
  History.replace(`${window.location.pathname}?${urlParams.toString()}`);
}

export const roundValues = (value, decimals) => Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);

export const getAggregationTime = (startTime, endTime) => {
  const diff = (new Date(endTime) - new Date(startTime)) / (60 * 1000);
  // declared below are the time frames in minutes
  const t1 = 5;
  const t2 = 60;
  // const t3 = 4 * 60;
  // const t4 = 24 * 60;
  let aggregationValue = 600;
  if (diff <= t1) {
    aggregationValue = 1;
  } else if (diff <= t2) {
    aggregationValue = 10;
  // } else if (diff <= t3) {
  //   aggregationValue = 60;
  } else {
    aggregationValue = 600;
  }
  // diff b/w current and startTime time in hours
  const currentDiff = (new Date() - new Date(startTime)) / (60 * 60 * 1000);
  if (currentDiff <= 3) {
    return aggregationValue;
  }
  const diffInDays = currentDiff / 24;
  if (diffInDays <= 15) {
    return aggregationValue <= 60 ? 60 : aggregationValue;
  }
  if (diffInDays <= 63) {
    return aggregationValue <= 300 ? 300 : aggregationValue;
  }
  return 3600;
};

export const checkValidJSON = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export const isValidUrl = (str)  => {
  try {
    new URL(str)
    return true;
  } catch (err) {
    return false;
  }
}