import { compare } from "../../../variables/general";
import { getURLParam, replaceURLParam } from "../../../utils/Utilities";
import _ from "lodash";
import { StateCell } from "../../../common/Table/StateCell";

export const GATEWAY_TYPE_MAPPING = {
  "0": "UNKNOWN",
	"1": "SINGLE",
	"2": "CLUSTER"
};

export const GATEWAY_MODE_MAPPING = {
	"0": "EDGE",
	"1": "HUB"
};

export const GATEWAY_STATE_MAPPING = {
  "0": "UNKNOWN",
	"1": "DEPRECATED1",
	"2": "ACTIVE",
  "3": "INACTIVE",
  "4": "ACTIVE_PENDING",
  "5": "INACTIVE_PENDING",
  "6": "ENABLING",
  "7": "DELETE_PENDING",
};

export const columns = () => [
  {
    title: 'Name',
    dataIndex: ['Gateway', 'Name'],
    key: 'name',
    sortDirections: ['descend', 'ascend', 'descend'],
    sorter: (a, b) => compare(a.customKey, b.customKey),
    width: 240,
    defaultSortOrder: getURLParam('name'),
    onHeaderCell: () => ({
      onClick: () => replaceURLParam('name', getURLParam('name') === 'ascend' || !getURLParam('name') ? 'descend' :
        'ascend'),
    })
  },
  {
    title: 'Account',
    dataIndex: ['Gateway', 'AcctName'],
    key: 'acctName',
    sorter: (a, b) => compare(a.AccountsCount, b.AccountsCount),
    sortDirections: ['descend', 'ascend', 'descend'],
    width: 110,
    defaultSortOrder: getURLParam('AcctName'),
    onHeaderCell: () => ({
      onClick: () => replaceURLParam('AcctName', getURLParam('AcctName') === 'ascend' || !getURLParam('AcctName') ? 'descend' :
        'ascend'),
    })
  },
  {
    title: 'CSPAcctName',
    dataIndex: ['Gateway', 'CSPAcctName'],
    key: 'cSPAcctName',
    width: 150,
  },
  {
    title: 'Image Version',
    dataIndex: ['PlatformConfig', 'DPImageVersion'],
    key: 'dpImageVersion',
    width: 150,
  },
  {
    title: "Description",
    dataIndex: ['Gateway', 'Description'],
    key: 'description',
  }, {
    title: "Type",
    dataIndex: ['Gateway', 'Type'],
    render: val => _.get(GATEWAY_TYPE_MAPPING, val, val),
    key: 'type',
    width: 80,
  }, {
    title: "State",
    dataIndex: ['Gateway', 'State'],
    render: val => <StateCell text={_.get(GATEWAY_STATE_MAPPING, val, val)} />,
    key: 'state',
    width: 100,
  },
  {
    title: 'Mode',
    dataIndex: ['Gateway', 'Mode'],
    render: val => _.get(GATEWAY_MODE_MAPPING, val, val),
    key: 'mode',
    width: 80,
  }
];

export const gwSettingColumns = () => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 150,
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    width: 150,
  }
]

