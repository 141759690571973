import { Fragment } from "react";
import "./table.scss";

export const StateCell = (props)=> {
    const { text, state } = props || {};
    return text && (
        <Fragment>
            <span className={ text ? getClassName(state || text) : ""} />
            {text}
        </Fragment>
    );
}

const getClassName = (state) => {
    let className = ""
    if (state && typeof state === "string") {
        if (state.toUpperCase() === "ACTIVE") {
            className = "dot green-dot"
        } else if (state.toUpperCase() === "INACTIVE") {
            className = "dot red-dot"
        } else {
            className = "dot yellow-dot"
        }
    } else {
        className = ""
    }
    return className;
}
