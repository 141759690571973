import React, {Component, Fragment} from 'react';
import {Route} from "react-router-dom";
import RoutePaths from '../../utils/RoutePaths';
import RuleSet from "./RuleSet";

class VendorRuleset extends Component {
    render() {
        return (
            <Fragment>
                <Route
                    exact
                    path={RoutePaths.defaultVendorRuleset}
                    component={RuleSet}
                />
            </Fragment>
        );
    }
}

export default VendorRuleset;