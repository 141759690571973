import React, { Component, Fragment } from 'react';
import URL from "../../utils/url";
import { columns } from "./columns";
import { Message } from "../../variables/general";
import _ from 'lodash';
import { PostRequest } from '../../utils/FetchHelper';
import ValtixTable from '../../common/Table/Table';
import { Alert, Row, Space, Spin } from 'antd';
import CustomButton from '../../common/CustomButton/Button';
import { ExportOutlined } from '@ant-design/icons';
import withContext from '../../hoc/withContext';
import GlobalDashboardContext from '../../common/ContextWrappers/Dashboard/Context';

class Deployments extends Component {
    controller = new window.AbortController();

    state = {
        allDashboardData: [],
        loadingDeployments: false
    };

    componentDidMount() {
        this.listDeployments();
    }

    componentWillUnmount() {
        if (this.controller) this.controller.abort();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedDashboard !== this.props.selectedDashboard) {
            this.listDeployments()
        }
    }


    listDeployments = async () => {
        this.setState({
            loadingDeployments: true,
        });
        PostRequest(URL.listdeployments, this.controller.signal, null, (resp) => {
            // this.addCustomKey(gateways);
            const allDashboardData = _.get(resp, "globalData") !== undefined ? _.get(resp, "globalData", []) : [{
                name: "Deployments",
                data: resp || {},
            }]
            this.setState(prevState => ({
                ...prevState,
                allDashboardData,
                loadingDeployments: false,
            }));
        }, (err) => {
            this.setState({
                loadingDeployments: false,
            });
            Message.error("Deployments", err);
        }, false);
    }

    render() {
        const { allDashboardData, loadingDeployments } = this.state;
        return (
            <Fragment>
                <Row style={{ marginBottom: '8px' }}>
                    <Space>Deployments {loadingDeployments ? <Spin /> : <></>}</Space>
                    <Space align="end" style={{ marginLeft: "auto" }}>
                        <CustomButton shape="round" type="primary" onClick={this.listDeployments}>Refresh</CustomButton>
                    </Space>
                </Row>
                {_.map(allDashboardData, (dashboardData) => (
                    _.get(dashboardData, "data.error") !== undefined ?
                        <Alert type="error" message={_.get(dashboardData, "name")} description={_.get(dashboardData, "data.error")} /> :
                        <ValtixTable
                            key={_.get(dashboardData, "name")}
                            title={() => (
                                <Row>
                                    <Space>{_.get(dashboardData, "name")}</Space>
                                    <Space align="end" style={{ marginLeft: "auto" }}>
                                        <CustomButton shape="round" type="primary" href={_.get(dashboardData, "endpoint")}>Open Dashboard <ExportOutlined /></CustomButton>
                                    </Space>
                                </Row>
                            )}
                            name="deployments"
                            loading={loadingDeployments}
                            data={_.get(dashboardData, "data.deployments", [])}
                            columns={columns}
                        />
                ))}
            </Fragment>
        );
    }
}

export default withContext(Deployments, GlobalDashboardContext);