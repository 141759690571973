import React, { Component } from 'react';
import { Card, Form, Input, Space, Select } from "antd";
import CustomButton from "../../../common/CustomButton/Button";
import { PostRequestResolve } from "../../../utils/FetchHelper";
import URL from "utils/url";
import { UserOutlined, } from '@ant-design/icons';
import {
    Message,
    validateEmpty,
    validateName
} from "../../../variables/general";
import RoutePaths from "../../../utils/RoutePaths";
import History from '../../../utils/history';
import _ from "lodash";

class AddTenant extends Component {
    state = {
        formData: {
            accounts: "",
            imageName: "",
            serverDeployment: undefined,
        },
        isEdit: false,
        validations: [],
        modal: { status: "", message: "" },
        isLoading: false,
        accounts: [],
        serverDeployments: [],
    };

    formValidator = {
        accounts: {
            validator: (_, value) => validateEmpty(value),
        },
        serverDeployment: {
            validator: (_, value) => validateEmpty(value),
        },
        imageName: {
            validator: (_, value) => validateName(value, false),
        },
    }


    controller = new window.AbortController();

    componentDidMount() {
        const { image, isEdit, selectedRecord } = this.props;
        if (isEdit) {
            this.setState(prevState => ({
                ...prevState,
                formData: {
                    ...prevState.formData,
                    accounts: _.get(selectedRecord, "Accounts", ""),
                    serverDeployment: _.get(selectedRecord, "ServerDeployment", ""),
                },
                isEdit: true,
            }));
        }
        this.setState(prevState => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                imageName: _.get(image, "Name", []),
            },
        }));
        this.getAccounts();
    }

    componentWillUnmount() {
        this.controller.abort();
    }

    getAccounts = (callback) => {
        const payload = {
            serverDeployment: "",
        };
        PostRequestResolve(URL.listAccounts, this.controller.signal, payload).then((resp) => {
            if (resp.accounts) {
                this.setState(prevState => ({
                    ...prevState,
                    accounts: _.map(resp.accounts, "Name"),
                    serverDeployments: _.uniq(_.map(resp.accounts, "ServerDeployment")),
                }));
            }
        }).catch((err) => {
            Message.error("List Accounts", err);
        });
    }

    onFormFieldChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                [name]: value,
            }
        }));
    };

    addUpdateImage = () => {
        const { formData, isEdit } = this.state;
        const { onClose } = this.props;
        const payload = {
            imageToDeployment: {
                ...formData,
            }
        };
        
        this.setState((prevState) => ({
            ...prevState,
            loading: true,
        }));
        PostRequestResolve(isEdit ? URL.updatePrivateImage: URL.createPrivateImage, this.controller.signal, payload)
            .then((resp) => {
                this.setState((prevState) => ({
                    ...prevState,
                    loading: false,
                }));
                Message.success(
                    `${isEdit ? "Update" : "Create"} Image`,
                    `Image ${_.get(formData, "imageName", 
                    )} ${isEdit ? "updated" : "created"} successfully`,
                );
                History.goBack()
                typeof onClose === "function" && onClose(true);
            }).catch((err) => {
                this.setState((prevState) => ({
                    ...prevState,
                    loading: false,
                }));
                Message.error(`${isEdit ? "Update" : "Create"} Image`, err);
            });
    };

    render() {
        const { formData, loading, isEdit, isLoading, accounts, serverDeployments } = this.state;
        const { onClose } = this.props;

        return (
            <Card
                className="create-modal"
                title={`${isEdit ? "Update" : "Add"} Tenant`}
                extra={
                    <CustomButton
                      className="close-button"
                      type="ghost"
                      onClick={() => onClose(false)}
                      disabled={loading}
                    >
                        X
                    </CustomButton>
                }
                bordered
            >
                {(
                    <Form
                        name="privateImage"
                        onFinish={this.addUpdateImage}
                        fields={Object.keys(formData).map(k => ({
                            name: k,
                            value: k === "accounts" ? _.get(formData, `${k}`, "").split(",").filter(f => f !== "") : formData[k],
                        }))}
                    >
                        <Form.Item
                            name="imageName"
                            rules={[this.formValidator.imageName]}
                            hasFeedback
                        >
                            <Input
                                name="imageName"
                                placeholder="Image Name"
                                onChange={this.onFormFieldChange}
                                disabled
                                size={"large"}
                                prefix={<UserOutlined className="form-item-icon" />}
                            />
                        </Form.Item>
                        <Form.Item
                            name="accounts"
                            rules={[this.formValidator.accounts]}
                            hasFeedback
                        >
                            <Select
                                size="large"
                                placeholder="Accounts"
                                mode="multiple"
                                prefix={<UserOutlined className="form-item-icon" />}
                                name="accounts"
                                onChange={(value) => {
                                    this.onFormFieldChange({ target: { name: "accounts", value: value.join(",") } });
                                }}
                                options={
                                    accounts.map(acc => ({
                                        label:acc, value: acc,
                                    }))
                                }
                            />
                        </Form.Item>

                        <Form.Item
                            name="serverDeployment"
                            rules={[this.formValidator.serverDeployment]}
                            hasFeedback
                        >
                            <Select
                                size="large"
                                showSearch
                                placeholder="Server Deployment"
                                prefix={<UserOutlined className="form-item-icon" />}
                                name="serverDeployment"
                                onChange={(value) => {
                                    this.onFormFieldChange({ target: { name: "serverDeployment", value } });
                                }}
                                options={
                                    _.sortBy(serverDeployments).map(acc => ({
                                        label:acc, value: acc,
                                    }))
                                }
                            />
                        </Form.Item>
                        <Form.Item>
                            <Space>
                                <CustomButton
                                    type="primary" htmlType="submit" disabled={loading || isLoading} loading={loading}
                                >
                                    {isEdit ? "Update" : "Add"}
                                </CustomButton>
                                {isEdit ? (
                                    <CustomButton type="primary" onClick={() => History.goBack()}>
                                        Go Back
                                    </CustomButton>
                                ) : (
                                    <CustomButton
                                        type="link"
                                        href={RoutePaths.listImageRegistry}
                                        disabled={loading}
                                    >
                                        Go back
                                    </CustomButton>
                                )}
                            </Space>
                        </Form.Item>
                    </Form>
                )}
            </Card>
        );
    }
}

export default AddTenant;
