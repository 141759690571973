import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import { PostRequest } from '../../../utils/FetchHelper';
import URL from '../../../utils/url';
import DeploymentContext from './Context';
import GlobalDashboardContext from '../Dashboard/Context';
import withContext from '../../../hoc/withContext';

class DeploymentContextWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deploymentsList: [],
      selectedDeployment: "",
      onChangeDeployment: this.onChangeDeployment,
      onChangeCallback: this.onChangeCallback,
      callBackFunc: () => { },
      clearOnChangeCallback: this.clearOnChangeCallback,
      showServerDevelopment: false,
      loadingDevelopmentList: false,
      showServerDevelopmentSelectModal: false,
      closeServerDeploymentSelectModal: this.closeServerDeploymentSelectModal,
    };
  }


  controller = new window.AbortController();

  getServerDeployments = () => {
    this.setState({
      loadingDevelopmentList: true,
    });
    PostRequest(URL.listServerDeployments, this.controller.signal, null, (resp) => {
      let selectedDeployment = "";
      if (_.isArray(resp) && resp.length === 1) {
        selectedDeployment = _.get(resp, "0")
      }
      const { callBackFunc } = this.state;
      this.setState(prevState => ({
        ...prevState,
        loadingDevelopmentList: false,
        deploymentsList: resp,
        ...(selectedDeployment ? { selectedDeployment } : {}),
        ...(prevState.showServerDevelopment && !selectedDeployment ? { showServerDevelopmentSelectModal: true } : {})
      }), callBackFunc)
    }, (err) => {
      console.error(err);
      this.setState({
        loadingDevelopmentList: false,
      })
    })
  };

  onChangeCallback = (callBackFunc) => {
    this.setState(prevState => ({
      ...prevState,
      callBackFunc,
      showServerDevelopment: true,
      ...(!prevState.selectedDeployment ? { showServerDevelopmentSelectModal: true } : {})
    }))
  }

  clearOnChangeCallback = () => {
    this.setState(prevState => ({
      ...prevState,
      showServerDevelopment: false,
      callBackFunc: () => { },
    }))
  }

  closeServerDeploymentSelectModal = () => {
    this.setState({
      showServerDevelopmentSelectModal: false,
    })
  }

  onChangeDeployment = (value) => {
    const { callBackFunc } = this.state;
    this.setState({
      selectedDeployment: value,
    }, callBackFunc);
  }

  componentDidMount() {
    this.getServerDeployments()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedDashboard !== this.props.selectedDashboard) {
      this.getServerDeployments();
    }
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  static propTypes = {
    children: PropTypes.node,
  }

  static defaultProps = {
    children: <Fragment />,
  };

  render() {
    const { children } = this.props;
    return (
      <DeploymentContext.Provider value={this.state}>
        <Fragment key={"deploymentContext"}>
          {children}
        </Fragment>
      </DeploymentContext.Provider>
    );
  }
}

export default withContext(DeploymentContextWrapper, GlobalDashboardContext);