/* eslint-disable dot-notation */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// eslint-disable-next-line
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import React, { Component } from "react";
import * as _ from "lodash";

am4core.useTheme(am4themes_animated);

class HealthChart extends Component {
    componentDidMount() {
        this.generateChart();
    }


    componentDidUpdate(prevProps) {
        const { data } = this.props;
        if (!_.isEqual(prevProps.data, data)) {
            if (this.chart) {
            this.chart.dispose();
            }
            this.generateChart();
        }
    }
    generateChart = () => {
        const { data, id, onReady = () => {} } = this.props;
        const chart = am4core.create(id, am4charts.XYChart);

        this.chart = chart;
        chart.data = data;
        chart.paddingRight = 20;
        // Create axes
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 50;
        dateAxis.renderer.grid.template.location = 0.5;
        dateAxis.baseInterval = {
            count: this.props.aggregationTime === 10 ? 70 : (Number(this.props.aggregationTime) + 10),
            timeUnit: "second"
        }
        dateAxis.cursorTooltipEnabled = false

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.grid.template.disabled = true;

        // Create series
        var series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "MetricValue";
        series.dataFields.dateX = "Timestamp";
        series.strokeWidth = 3;
        series.connect = false;
        series.tensionX = 0.8;
        series.fillOpacity = 0.2;
        series.events.on("datavalidated", () => {
        const values = series.dataItems.values;
        for(let i = 0; i < values.length - 1; i+=1){
            if(dateAxis.makeGap(values[i+1], values[i])) {
            let range = dateAxis.axisRanges.create();
            range.date = values[i]["dateX"];
            range.endDate = values[i+1]["dateX"];
            range.axisFill.fill = am4core.color("#f00");
            range.axisFill.fillOpacity = 1;
            range.grid.strokeOpacity = 1;
            }
        }
        });
        series.tooltipText = "{dateX}";
        dateAxis.dateFormatter.dateFormat = "dd-MMM HH:mm:ss"


        var range = valueAxis.createSeriesRange(series);
        range.value = 0;
        range.endValue = 100;
        range.contents.stroke = chart.colors.getIndex(2);
        range.contents.fill = range.contents.stroke;
        range.contents.fillOpacity = 0.2;

        chart.scrollbarX = new am4core.Scrollbar();
        chart.cursor = new am4charts.XYCursor()
        chart.cursor.snapToSeries = series;
        chart.cursor.lineY.disabled = true;

        chart.events.on('ready', () => {
            onReady();
          });

    }

    componentWillUnmount() {
        if (this.chart) {
        this.chart.dispose();
        }
    }

    render() {
        const { id } = this.props;
    return (
      <div id={id} style={{ width: "100%", height: "300px" }}></div>
    );
  }
}
export default HealthChart;