import React, { Component, Fragment } from "react";
import { Col, Modal, Row, Space } from "antd";
import _ from "lodash";
import URL from "../../../utils/url";
import ValtixTable from "../../../common/Table/Table";
import { columns } from "./Columns";
import { Message } from "../../../variables/general";
import { PostRequest, PostRequestResolve } from "../../../utils/FetchHelper";
import CustomButton from "../../../common/CustomButton/Button";
import CreateAddress from "./CreateAddress";

class ListAddresses extends Component {
  controller = new window.AbortController();

  state = {
    addressList: [],
    addressGroupsList: [],
    loadingAddressList: false,
    showTerminateInstanceModal: false,
    showCreateAddressGroup: false,
    showDeleteAddressModal: false,
  };

  componentDidMount() {
    this.getAddressList();
  }
  
  tableRef = React.createRef();

  toggleLoading = (callback) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        loadingAddressList: !prevState.loadingAddressList,
      }),
      () => {
        typeof callback === "function" && callback();
      }
    );
  };

  addCustomKey = (list) => {
    list.forEach((d) => {
      let value = _.get(d, "addressGroupInfo.name", "");
      d.ID = value;
    });
  };

  parseAddressResponse = (data) => {
    return(data.map(l => ({
            ...l,
            id: _.get(l, "addressGroupInfo.id"),
            name: _.get(l, "addressGroupInfo.name"),
            disabledText: "Cannot Update Auto-Generated Address Group",
            type: _.get(l, "addressGroupInfo.isBackendAddressGroup") ? "proxy" : "src",
          })).reduce((acc, element) => {
            if (_.get(element, "addressGroupInfo.name") === 'any') {
              return [element, ...acc];
            }
            return [...acc, element];
          }, []))
  }

  getAddressList = (callback) => {
    const { acctName = "" } = this.props;
    const payload = {
      common: {
        acctName,
      }
    };
    this.toggleLoading(() => {
      PostRequest(
        URL.listAddress,
        this.controller.signal,
        payload,
        (resp) => {
          const addressList = this.parseAddressResponse(_.get(resp, "addressGroupswGateway", []).filter(f => !_.get(f, "addressGroupInfo.addressGroups")));
          const addressGroupsList = this.parseAddressResponse(_.get(resp, "addressGroupswGateway", []));
          this.addCustomKey(addressList);
          this.setState((prevState) => ({
            ...prevState,
            addressList,
            addressGroupsList,
            loadingAddressList: false,
          }));
        },
        (err) => {
          this.toggleLoading();
          Message.error("List Addresses", err);
        }
      );
    });
  };


  componentWillUnmount() {
    this.controller.abort();
  }

  selectAddress = (address) => {
    this.setState({
      selectedAddress: _.uniqBy(address, "id"),
    })
  };


   openCreatAddressGroupModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showCreateAddressGroup: true,
      showEditAddressGroup: false,
    }));
   };
  
  openEditAddressGroupModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showCreateAddressGroup: false,
      showEditAddressGroup: true,
    }));
  }

  openDeleteAddressModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showCreateAddressGroup: false,
      showEditAddressGroup: false,
      showDeleteAddressModal: true,
    }));
  }
  
  closeCreateAddressModal = (reload) => {
        this.setState((prevState) => ({
          ...prevState,
          showCreateAddressGroup: false,
        }), this.getAddressList);
  };

  closeEditAddressModal = (reload) => {
        this.tableRef?.current?.clearAllSelections();
        this.setState((prevState) => ({
          ...prevState,
          showEditAddressGroup: false,
          selectedAddress: [],
        }), this.getAddressList);
  };

  closeDeleteAddressModal = (deleteUser) => {
    if (deleteUser === true) {
      this.deleteAddress();
    } else {
      this.setState({
        showDeleteAddressModal: false,
      });
    }
  };

  deleteAddress = async () => {
    this.setState({
      deleteAddressLoading: true,
    });
    const { selectedAddress } = this.state;
    if (!selectedAddress) return;
    const deletePromises = selectedAddress.map(({ id }) => {
    const payload = {
      common: {
        acctName: this.props.acctName,
      },
      id,
    };
      return PostRequestResolve(URL.deleteAddress, this.controller.signal, payload)
    })
      
    Promise.all(deletePromises).then(() => {
        Message.success("Delete Address", "Success");
        this.toggleLoading(this.getAddressList);
        this.setState({
          showDeleteAddressModal: false,
          selectedAddress: [],
          deleteAddressLoading: false,
        }, this.getAddressList);
      })
        .catch((err) => {
          this.setState((prevState) => ({
            ...prevState,
            deleteAddressLoading: false,
            loadingAddressList:false,
            showDeleteAddressModal: false,
          }));
          Message.error("Delete Address", err);
        }); 
  }
  

  render() {
    const {
      addressList,
      addressGroupsList,
      loadingAddressList,
      showCreateAddressGroup,
      showEditAddressGroup,
      selectedAddress = [],
      showDeleteAddressModal,
      deleteAddressLoading,
    } = this.state;
    const { acctName } = this.props;
    return (
      <Fragment>
        <Row>
        <Col span={24}>
            {<Row justify="end">
              <Space>
                <CustomButton
                  type="primary"
                  onClick={this.openCreatAddressGroupModal}
                  enableOnlyForAdmin
                >
                  Create
                </CustomButton>
                <Space>
                <CustomButton
                  type="primary"
                  onClick={this.openEditAddressGroupModal}
                  disabled={selectedAddress.length !== 1 || _.get(selectedAddress, "[0].addressGroupInfo.type") !== 1}
                  enableOnlyForAdmin
                >
                  Edit
                </CustomButton>
                <CustomButton
                  type="primary"
                  danger
                  onClick={this.openDeleteAddressModal}
                  disabled={selectedAddress.length === 0}
                  enableOnlyForAdmin
                >
                  Delete Address
                </CustomButton>
              </Space>
              </Space>
            </Row>}
            {showCreateAddressGroup && (
            <CreateAddress
              addressList={addressList}
              accountName={acctName}
              onClose={this.closeCreateAddressModal}
            />
            )}
            {showEditAddressGroup && (
            <CreateAddress
              addressList={addressList}
              addressGroupsList={addressGroupsList}
              accountName={acctName}
              onClose={this.closeEditAddressModal}
              selectedAddressGroup={selectedAddress}
            />
            )}
        <ValtixTable
          ref={this.tableRef}
          title={() => "Address Groups"}
          name="listAddresses"
          loading={loadingAddressList}
          data={addressGroupsList}
          columns={columns()}
          rowKey="id"
          onRowSelect={this.selectAddress}
          enableMultiRowSelection
        />
        <Modal
            title="Delete Address"
            visible={showDeleteAddressModal}
            okText="Delete"
            okButtonProps={{
              danger: true,
              disabled: deleteAddressLoading,
            }}
            cancelButtonProps={{
              disabled: deleteAddressLoading,
            }}
            onOk={() => this.closeDeleteAddressModal(true)}
            onCancel={() => this.closeDeleteAddressModal()}
            confirmLoading={deleteAddressLoading}
          >Are you sure?</Modal>

          </Col>
          </Row>
      </Fragment>
    );
  }
}

export default ListAddresses;
