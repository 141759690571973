import React, { Component } from 'react';
import { Card, Form, Input } from "antd";
import CustomButton from "../../../common/CustomButton/Button";
import { PostRequestResolve } from "../../../utils/FetchHelper";
import URL from "../../../utils/url";
import './setting.scss';
import {
    Message,
    validateName
} from "../../../variables/general";
import PropTypes from "prop-types";
import CustomSelect from 'common/CustomSelect';
import _ from 'lodash';


class CreateSetting extends Component {
    state = {
        formData: {
            name: "",
            value: "",
            description: "",
        },
        validations: [],
        showModal: false,
        loading: false,
        modal: { status: "", message: "" },
    };

    formRef = React.createRef()

    formValidator = {
        name: {
            validator: (_, value) => validateName(value, false),
        },
        value: {
            validator: (_, value) => validateName(value, false, 300),
        },
    }

    componentDidMount() {
        const { setting } = this.props;
        if (setting) {
            const formData = {
                    name: _.get(setting, "Name", ""),
                    value: _.get(setting, "Value", ""),
                    description: _.get(setting, "Description", ""),
            }
            this.setState({
                formData: formData,
                edit : true,
            }, () => {
                this.formRef.current?.setFieldsValue(formData);
            })
            
        }
    }


    controller = new window.AbortController();

    componentWillUnmount() {
        this.controller.abort();
    }


    onFormFieldChange = (e) => {
        const { name } = e.target;
        const { value } = e.target;
        this.setState(prevState => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                [name]: value,
            }
        }));
    };

    onClose = () => {
        const { onClose } = this.props;
        const { modal: { status } } = this.state;
        this.setState({showModal: false}, () => status === "success" && onClose && onClose(true));
    }

    createSetting = () => {
        const { account } = this.props;
        const { formData, edit } = this.state;
        const {name, value, description} = formData;
        const payload = {
            acctName: account,
            name, value, description,
        };
        this.setState((prevState) => ({
            ...prevState,
            loading: true,
        }), () => {
            PostRequestResolve(edit ? URL.updateAccountSetting :URL.createAccountSetting, this.controller.signal, payload)
                .then((resp) => {
                    this.setState((prevState) => ({
                        ...prevState,
                        loading: false,
                        modal: {
                            status : "success",
                            message: ""
                        }
                    }));
                    if(edit) {
                        Message.success("Update Setting", `Setting ${name} updated successfully`);
                    } else {
                        Message.success("Create Setting", `Setting ${name} created successfully`);
                    }
                    this.onClose()
                    // this.onClose()
                    // }), () => {onClose && onClose()});
                }).catch((err) => {
                this.setState((prevState) => ({
                    ...prevState,
                    loading: false,
                    modal: {
                        status : "error",
                        message: ""
                    }
                }));
                if(edit) {
                    Message.error("Update Setting", err);
                } else {
                    Message.error("Create Setting", err);
                }
            });
        });
    }

    render() {
        const { formData, loading, edit } = this.state;
        const { onClose, availableSettings = [] } = this.props;
        
        return (
            <div>
                <Card
                    className="create-modal setting"
                    title={edit ? "Edit" : "Create"}
                    extra={
                        <CustomButton className="close-button" type="ghost" onClick={onClose}>x</CustomButton>
                    }
                    bordered
                >

                    <Form
                        ref={this.formRef}
                        name="Settings"
                        style={{ width: "500px", margin: "auto"}}
                        onFinish={this.createSetting}
                        initialValues={{...formData}}
                        
                    >
                        <Form.Item
                            name="name"
                            rules={[this.formValidator.name]}
                            hasFeedback
                        >
                            <CustomSelect
                                name="name"
                                disabled={edit}
                                onChange={this.onFormFieldChange}
                                value={_.get(formData, "name")}
                                size="large"
                                placeholder="Name"
                                options={_.map(availableSettings, "Name")}
                            />
                        </Form.Item>
                        <Form.Item
                            name="value"
                            rules={[this.formValidator.value]}
                            hasFeedback
                        >
                            <Input
                                name="value"
                                placeholder="Value"
                                onChange={this.onFormFieldChange}
                                size={"large"}/>
                        </Form.Item>
                        <Form.Item
                            name="description"
                            hasFeedback
                        >
                            <Input
                                name="description"
                                placeholder="Description"
                                onChange={this.onFormFieldChange}
                                size={"large"}/>
                        </Form.Item>


                        <Form.Item>
                            <CustomButton disabled={loading} loading={loading} type="primary" htmlType="submit">
                                {edit ? "Update" : "Create"}
                            </CustomButton>
                        </Form.Item>

                    </Form>
                </Card>
            </div>
        );
    }
}

CreateSetting.propTypes = {
    account: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    setting: PropTypes.object,
};

export default CreateSetting;