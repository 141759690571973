import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoggedIn } from "utils/authservice";
import RoutePaths from "../../utils/RoutePaths";

const PrivateRoute = (props) => {
  if (isLoggedIn()) {
    return <Route {...props} />;
  }
  return (<Redirect to={RoutePaths.login} />);
};

export default PrivateRoute;
