import React, { Component } from "react";
import { Card, Form, Input, Select, Space, Row } from "antd";
import ValtixTable from "common/Table/Table";
import CustomButton from "../../../common/CustomButton/Button";
import { PostRequestResolve } from "../../../utils/FetchHelper";
import URL from "../../../utils/url";
import {
  Message,
  validateSelect,
} from "../../../variables/general";
import PropTypes from "prop-types";
import { gwSettingColumns } from "./Columns";
import _ from "lodash";

class ModifySettings extends Component {
  state = {
    formData: {
      gatewayName: this.props.selectedGateway,
      acctName: this.props.acctName,
      name: "",
      value: "",
    },
    loadingSettings: false,
    gatewaySettings: [],
    availableSettings: [],
    validations: [],
    loading: false,
    modal: { status: "", message: "" },
  };

  formValidator = {
    settings: {
      validator: (_, value) => validateSelect(value, this.state.gatewaySettings),
    },
  };

  controller = new window.AbortController();

  componentDidMount() {
    this.listGatewaySettings();
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  onFormFieldChange = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  onClose = () => {
    const { onClose } = this.props;
    onClose(false);
  };

  listGatewaySettings = async () => {
    try {
      const { formData } = this.state;
      const { gatewayName, acctName } = formData;
      this.setState({ loadingSettings: true });
      const list = await PostRequestResolve(
        URL.listGatewaySettings,
        this.controller.signal,
        { gatewayName, acctName }
      );
      this.setState({
        availableSettings: _.get(list, "availableSettings", []).map(({ name }) => ({
          key: name,
          label: name,
          value: name,
        })),
        gatewaySettings: _.get(list, "gatewaySettings", []),
        loadingSettings: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({ loadingSettings: false });
    }
  };

  modifyGatewaySettings = (action) => {
    const { formData } = this.state;
    const { gatewayName, name, acctName, value } = formData;
    const payload = {
      common: {
        acctName,
      },
      gatewayName,
      delete: action === "delete",
      setting: {
        name: name,
        value,
      }
    };
    this.setState(
      (prevState) => ({
        ...prevState,
        loading: true,
      }),
      () => {
        PostRequestResolve(
          URL.updateGatewaySettings,
          this.controller.signal,
          payload
        )
          .then((resp) => {
            this.setState((prevState) => ({
              ...prevState,
              loading: false,
              modal: {
                status: "success",
                message: "",
              },
            }));
            Message.success(
              "Modify settings",
              `Settings modified successfully`
            );
            this.onClose();
          })
          .catch((err) => {
            this.setState((prevState) => ({
              ...prevState,
              loading: false,
              modal: {
                status: "error",
                message: "",
              },
            }));
            Message.error("Modify Settings", err);
          });
      }
    );
  };

  formRef = React.createRef();

  selectGwSetting = (selectedSetting) => {
    this.formRef.current.setFieldValue("name", _.get(selectedSetting, "name"));
    this.formRef.current.setFieldValue("value", _.get(selectedSetting, "value"));
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        ...selectedSetting,
      }
    }));
  };

  render() {
    const { formData, gatewaySettings, availableSettings, loadingSettings, loading } = this.state;
    const { name, value } = formData;
    return (
      <div>
        <Card
          className="create-modal setting"
          title="Gateway Settings"
          extra={
            <CustomButton
              className="close-button"
              type="ghost"
              onClick={this.onClose}
            >
              x
            </CustomButton>
          }
          bordered
        >
          <div style={{ height: "40vh", overflowY: "auto" }}>
            <ValtixTable
              name="settings"
              loading={loadingSettings}
              data={gatewaySettings}
              rowKey="name"
              columns={gwSettingColumns()}
              onRowSelect={this.selectGwSetting}
              enableSingleRowSelection
            />
          </div>
          <Form
            ref={this.formRef}
            name="Settings"
            style={{ width: "500px", margin: "auto" }}
          >
            <Form.Item
              name="name"
            >
              <Select
                allowClear
                showSearch
                placeholder="Select Setting"
                options={availableSettings}
                onChange={(value) => this.onFormFieldChange("name", value)}
              />
            </Form.Item>
            <Form.Item name="value">
              <Input
                placeholder="Value"
                onChange={(e) => this.onFormFieldChange("value", e?.target?.value)}
              />
            </Form.Item>
          </Form>
          <Row justify="end">
            <Space>
              <CustomButton
                disabled={loadingSettings || loading || !name}
                loading={loadingSettings}
                type="danger"
                onClick={() => this.modifyGatewaySettings("delete")}
              >
                Delete Setting
              </CustomButton>
              <CustomButton
                disabled={loadingSettings || loading || !name || !value}
                loading={loadingSettings}
                type="primary"
                onClick={() => this.modifyGatewaySettings()}
              >
                Save Setting
              </CustomButton>
            </Space>
          </Row>
        </Card>
      </div>
    );
  }
}

ModifySettings.propTypes = {
  account: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setting: PropTypes.object,
};

export default ModifySettings;
