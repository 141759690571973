import React from "react";
import PropTypes from "prop-types";
import {Button, Tooltip} from "antd";
import { isAdminUser } from "../../utils/authservice";

export default function CustomButton(props) {
    const {
        size, shape, type, disabled,
        loading, block, href, icon,
        className, children, onClick, htmlType, danger,
        enableOnlyForAdmin,
    } = props;
    const button = (
        <Button
            size={size}
            type={type}
            shape={shape}
            disabled={disabled || (enableOnlyForAdmin ? !isAdminUser() : false)}
            loading={loading || false}
            block={block || false}
            href={href}
            icon={icon}
            className={`custom-button ${className || ""}`}
            onClick={onClick}
            htmlType={htmlType}
            danger={danger}
        >
            {children}
        </Button>
    );
    return (
        enableOnlyForAdmin && !isAdminUser() ? (
            <Tooltip title="Not allowed for read user">{button}</Tooltip>
        ) : button
    );
}

CustomButton.propTypes = {
    size: PropTypes.oneOf(["large", "middle", "small"]),
    shape: PropTypes.oneOf(["circle", "round"]),
    type: PropTypes.oneOf(["primary", "ghost", "dashed", "link", "text", "default"]),
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    block: PropTypes.bool,
    href: PropTypes.string,
    icon: PropTypes.node,
    className: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func,
    htmlType: PropTypes.string,
    enableOnlyForAdmin: PropTypes.bool,
};
