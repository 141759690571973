import { createBrowserHistory } from 'history';

// allows us access from other components without having to pass props
const History = createBrowserHistory();

export default History;

// no url param is preserved 
export const hardRedirect = (url, state="") => {
    History.push(url, state);
};
  

// one url param will be preserved
export const redirectTo = (url, state="") => {
    const searchParams = window.location.search;
    if (searchParams.length > 0) {
        const params = searchParams.split('=');
        const name = params[0].slice(1);
        const value = params[1];
        History.push(`${url}?${name}=${value}`, state);
    } else {
        History.push(url, state);
    }
}

// one url param will be preserved
export const replaceWith = (url, state="") => {
    const searchParams = window.location.search;
    if (searchParams.length > 0) {
        const params = searchParams.split('=');
        const name = params[0].slice(1);
        const value = params[1];
        History.replace(`${url}?${name}=${value}`, state);
    } else {
        History.replace(url, state);
    }
}
