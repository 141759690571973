import React, { Component } from 'react';
import { Card, Form, Input, Space, Select } from "antd";
import CustomButton from "../../common/CustomButton/Button";
import { PostRequestResolve } from "../../utils/FetchHelper";
import URL from "utils/url";
import { UserOutlined, } from '@ant-design/icons';
import './accounts.scss';
import {
    Message,
    validateDescription,
    validateEmpty,
    validateName
} from "../../variables/general";
import RoutePaths from "../../utils/RoutePaths";
import History, { redirectTo } from '../../utils/history';
import _ from "lodash";
import withContext from '../../hoc/withContext';
import DeploymentContext from '../../common/ContextWrappers/Deployment/Context';

const { Option } = Select;

class CreateAccount extends Component {
    state = {
        formData: {
            id: "",
            accountName: "",
            serverDeployment: undefined,
            description: "",
            company: "",
            state: "inactive",
            organization: "",
        },
        isEdit: false,
        validations: [],
        modal: { status: "", message: "" },
        isLoading: false
    };

    formValidator = {
        accountName: {
            validator: (_, value) => validateName(value, false),
        },
        serverDeployment: {
            validator: (_, value) => validateDescription(value, 128),
        },
        state: {
            validator: (_, value) => validateEmpty(value),
        }
    }


    controller = new window.AbortController();

    componentDidMount() {
        const { account, isEdit } = this.props;
        if (isEdit && _.get(account, "ID")) {
            this.setState(prevState => ({
                ...prevState,
                formData: {
                    ...prevState.formData,
                    id: account.ID,
                    accountName: account.Name,
                    serverDeployment: account.ServerDeployment || undefined,
                    description: account.Description || undefined,
                    company: account.Company || undefined,
                    state: account.State || undefined,
                    organization: account.Organization || undefined,
                },
                isEdit: true,
            }));
        }
    }

    componentWillUnmount() {
        this.controller.abort();
    }

    onFormFieldChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                [name]: value,
            }
        }));
    };

    createAccount = () => {
        const { formData, isEdit } = this.state;
        const { onClose } = this.props;
        const { accountName, serverDeployment, company, description, state, organization } = formData;
        const payload = {
            name: accountName,
            company,
            description,
            state,
            organization,
            ...!isEdit ? {
                server_deployment: serverDeployment,
            } : {},
        };
        
        this.setState((prevState) => ({
            ...prevState,
            loading: true,
        }));
        PostRequestResolve(isEdit ? URL.updateAccount : URL.createAccount, this.controller.signal, payload)
            .then((resp) => {
                this.setState((prevState) => ({
                    ...prevState,
                    loading: false,
                }));
                Message.success(
                    `${isEdit ? "Update" : "Create"} Account`,
                    `Account ${accountName} ${isEdit ? "updated" : "created"} successfully`,
                );
                typeof onClose === "function" && onClose(true);
                redirectTo(`${RoutePaths.getAccount}/${accountName}`);
            }).catch((err) => {
                this.setState((prevState) => ({
                    ...prevState,
                    loading: false,
                }));
                Message.error(`${isEdit ? "Update" : "Create"} Account`, err);
            });
    };

    render() {
        const { formData, loading, isEdit, isLoading } = this.state;
        const { onClose, deploymentsList = [] } = this.props;

        return (
            <Card
                className="create-modal"
                title={`${isEdit ? "Update" : "Create"} Account`}
                extra={
                    <CustomButton
                      className="close-button"
                      type="ghost"
                      onClick={() => onClose(false)}
                      disabled={loading}
                    >
                        X
                    </CustomButton>
                }
                bordered
            >
                {(
                    <Form
                        name="accounts"
                        onFinish={this.createAccount}
                        fields={Object.keys(formData).map(k => ({
                            name: k,
                            value: formData[k],
                        }))}
                        size="middle"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                    >
                        <Form.Item
                            label="Account Name"
                            name="accountName"
                            rules={[this.formValidator.accountName]}
                            hasFeedback
                        >
                            <Input
                                name="accountName"
                                placeholder="Account Name"
                                onChange={this.onFormFieldChange}
                                disabled={isEdit}
                                prefix={<UserOutlined className="form-item-icon" />}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Company"
                            name="company"
                            hasFeedback
                        >
                            <Input
                                name="company"
                                placeholder="Company"
                                onChange={this.onFormFieldChange}
                                prefix={<UserOutlined className="form-item-icon" />}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Organization"
                            name="organization"
                            hasFeedback
                        >
                            <Input
                                name="organization"
                                placeholder="Organization"
                                onChange={this.onFormFieldChange}
                                prefix={<UserOutlined className="form-item-icon" />}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Description"
                            name="description"
                            hasFeedback
                        >
                            <Input.TextArea
                                name="description"
                                placeholder="Description"
                                onChange={this.onFormFieldChange}
                                prefix={<UserOutlined className="form-item-icon" />}
                            />
                        </Form.Item>

                        <Form.Item
                            label="State"
                            name="state"
                            hasFeedback
                        >
                            <Select
                                size="large"
                                placeholder="State"
                                prefix={<UserOutlined className="form-item-icon" />}
                                name="state"
                                onChange={(value) => {
                                    this.onFormFieldChange({ target: { name: "state", value } });
                                }}
                                options={[
                                    { label: 'Active', value: 'active' },
                                    { label: 'Inactive', value: 'inactive' },
                                    { label: 'Trial', value: 'trial' },
                                    { label: 'Paid', value: 'paid' },
                                    { label: 'Free', value: 'free' },
                                    { label: 'Internal', value: 'internal' },
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Server Deployment"
                            name="serverDeployment"
                            rules={[this.formValidator.serverDeployment]}
                            hasFeedback
                        >
                            <Select
                                name="serverDeployment"
                                placeholder="Server Deployment"
                                onChange={(value) => {
                                    this.onFormFieldChange({ target: { name: "serverDeployment", value } });
                                }}
                                disabled={isEdit}
                                prefix={<UserOutlined className="form-item-icon" />}
                                showSearch
                            >
                                {_.map(deploymentsList, (name) => <Option value={name}>{name}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Space>
                                <CustomButton
                                    type="primary" htmlType="submit" disabled={loading || isLoading} loading={loading}
                                >
                                    {isEdit ? "Update" : "Create Account"}
                                </CustomButton>
                                {isEdit ? (
                                    <CustomButton type="primary" onClick={() => History.goBack()}>
                                        Go Back
                                    </CustomButton>
                                ) : (
                                    <CustomButton
                                        type="link"
                                        href={RoutePaths.defaultAccounts}
                                        disabled={loading}
                                    >
                                        Go back
                                    </CustomButton>
                                )}
                            </Space>
                        </Form.Item>
                    </Form>
                )}
            </Card>
        );
    }
}

export default withContext(CreateAccount, DeploymentContext);
