const RoutePaths = {
  homePage: "/admin/overview",
  login: "/login",
  about: "/admin/about",

  dashboards: "/admin/dashboards",
  /* BEGIN image registry */
  deployments: "/admin/deployments",
  /* END image registry */

  /* BEGIN image registry */
  defaultImageRegistry: "/admin/imageregistry",
  listImageRegistry: "/admin/imageregistry/list",
  getImageRegistry: "/admin/imageregistry/get",
  createImageRegistry: "/admin/imageregistry/create",
  /* END image registry */

  /* BEGIN accounts */
  defaultAccounts: "/admin/accounts",
  listAccounts: "/admin/accounts/list",
  createAccounts: "/admin/accounts/create",
  editAccount: "/admin/accounts/edit",
  getAccount: "/admin/accounts/get",
  /* END accounts */

  defaultVendorRuleset: "/admin/ruleset",

  /* BEGIN users */
  unverifiedUsers: "/admin/unverifiedUsers",
  /* END users */
  
  sqlConsole: "/admin/sql-console",
  serverHealth: "/admin/server-health",
  auditLogs: "/admin/audit-logs",
  userQuarantine: "/admin/user-quarantine"
};

export default RoutePaths;