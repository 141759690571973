import React, { useEffect, useRef, useState } from 'react';
import URL from "utils/url";
import { PostRequestResolve } from '../../../utils/FetchHelper';
import { Card, Input, Row, Space, Tag, Typography } from 'antd';
import _ from 'lodash';
import { Message } from '../../../variables/general';
import CustomButton from '../../../common/CustomButton/Button';

// updateProperties function call post request to update properties
const updateProperties = (acctName, updatedProperties) => {
    const payload = {
        acctName: acctName,
        ...updatedProperties,
    };
    // Call post request to update properties
    PostRequestResolve(URL.updateAccountProperties, null, payload)
        .then((response) => {
            Message.success("Properties updated successfully", _.get(response, "message"));
        }).catch((error) => {
            console.error("Failed to update properties", error);
            Message.error("Failed to update properties", error);
        });
};

// React Account Properties component
const AccountProperties = ({ properties, acctName }) => {
    const [localProperties, setLocalProperties] = React.useState(properties);

    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);
    useEffect(() => {
        editInputRef.current?.focus();
    }, [editInputValue]);

    //   remove email from properties
    const handleClose = (removedEmail) => {
        const newEmails = _.filter(_.get(localProperties, "samlProperties.emailDomains", []), (email) => email !== removedEmail);
        setLocalProperties({ ...localProperties, samlProperties: { emailDomains: newEmails } });
    };
    const showInput = () => {
        setInputVisible(true);
    };
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };
    const handleInputConfirm = () => {
        const currentEmails = _.get(localProperties, "samlProperties.emailDomains", []) || [];
        if (inputValue && !_.includes(currentEmails, inputValue)) {
            setLocalProperties({ ...localProperties, samlProperties: { emailDomains: [...currentEmails, inputValue] } });
        }
        setInputVisible(false);
        setInputValue('');
    };
    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value);
    };
    const handleEditInputConfirm = () => {
        const currentEmails = _.get(localProperties, "samlProperties.emailDomains", []) || [];
        const newEmails = [...currentEmails];
        newEmails[editInputIndex] = editInputValue;
        setLocalProperties({ ...localProperties, samlProperties: { emailDomains: newEmails } });
        setEditInputIndex(-1);
        setEditInputValue('');
    };
    const tagPlusStyle = {
        height: 22,
        borderStyle: 'dashed',
    };
    return (
        <>
            <Row style={{ width: "100%" }}>
                <Typography.Title level={4} style={{ margin: "auto 0px" }}>Account Properties</Typography.Title>
                <Space style={{ marginLeft: "auto" }}>
                    <CustomButton
                        type="primary"
                        enableOnlyForAdmin
                        onClick={() => { updateProperties(acctName, localProperties) }}

                    >Save Properties</CustomButton>
                </Space>
            </Row>
            <Card title="SAML Properties" style={{ marginTop: "8px" }}>
                <Typography>Email Domains</Typography>
                {_.map(_.get(localProperties, "samlProperties.emailDomains", []), (email, index) => {
                    if (editInputIndex === index) {
                        return (
                            <Input
                                ref={editInputRef}
                                key={email}
                                size="small"
                                value={editInputValue}
                                onChange={handleEditInputChange}
                                onBlur={handleEditInputConfirm}
                                onPressEnter={handleEditInputConfirm}
                            />
                        );
                    }
                    const tagElem = (
                        <Tag
                            key={email}
                            closable
                            style={{
                                userSelect: 'none',
                            }}
                            onClose={() => handleClose(email)}
                        >
                            <span
                                onDoubleClick={(e) => {
                                    setEditInputIndex(index);
                                    setEditInputValue(email);
                                    e.preventDefault();
                                }}
                            >
                                {email}
                            </span>
                        </Tag>
                    );
                    return tagElem;
                })}
                {inputVisible ? (
                    <Input
                        ref={inputRef}
                        type="text"
                        size="small"
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleInputConfirm}
                        onPressEnter={handleInputConfirm}
                    />
                ) : (
                    <Tag style={tagPlusStyle} onClick={showInput}>
                        Add Email Domain
                    </Tag>
                )}
            </Card>

        </>
    );
};

export default AccountProperties;