import _ from "lodash";

export const DownloadCsv = (data, headers, fileName, colMap, useColMap) => {
  if (headers && headers.length > 0 ) {
    let csvContent = "data:text/csv;charset=utf-8,";
    if (colMap && !useColMap) {
      // rename column headers
      _.forEach(headers, col => {
        csvContent += `"${_.get(colMap, col, "")}",`;
      })
    }
    else if (useColMap) {
      // useColMap -> if set to true uses colMap as headers while exporting csv
      // this can also be used to filter specific columns in combination with colMap
      _.forEach(_.keys(colMap), col => {
        csvContent += `"${_.get(colMap, col, "")}",`;
      })
    }
    else {
      _.forEach(headers, col => {
        csvContent += `"${col}",`;
      })
    }
    csvContent += "\r\n";
    _.forEach(data, row => {
      let cols = "";
      let parseHeaders = headers;
      if (useColMap) {
        parseHeaders = _.keys(colMap);
      }
      _.forEach(parseHeaders, col => {
        const c = _.get(row, col);
        if (typeof c === "string") {
          cols += `"${c}",`;
        } else if (c === undefined) {
          cols += ",";
        } else {
          cols += `${c},`;
        }
      })
      csvContent += `${cols}\r\n`;
    });

    csvContent = csvContent.replaceAll(",\r\n", "\r\n")

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
  }
}