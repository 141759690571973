import React, {Component, Fragment} from 'react';
import _ from "lodash";
import { filterList } from "variables/general";
import ValtixTable from "../../common/Table/Table";
import { columns } from "./Columns";
import SearchBar from "../../common/SearchBar/Search";
import GetRequest from "utils/FetchHelper";
import URL from '../../utils/url';
import { Message } from "../../variables/general";
import "./UnverifiedUsers.scss";
import { replaceURLParam } from '../../utils/Utilities';


class ListUsers extends Component {

    state = {
        users: [],
        filteredData: [],
        loading: false,
    };

    controller = new window.AbortController();

    constructor(props) {
        super(props);
        replaceURLParam('created_at', "descend");
    }

    componentDidMount() {
        this.getUnverifiedUsers();
    }

    componentWillUnmount() {
        if (this.controller) this.controller.abort();
    }

    getUnverifiedUsers = () => {
        this.setState({
            loading: true,
        })
        GetRequest(URL.listUnverifiedUsers, this.controller.signal).then((resp) => {
            this.setState({
                users: _.get(resp, "users", []),
                filteredData: _.get(resp, "users", []),
                loading: false,
            });
        }).catch((err) => {
            this.setState({
                loading: false,
            });
            Message.error("List Unverified Users", err);
        });

    }

    onSearch = (value, callback) => {
        const { users } = this.state;
        this.setState((prevState => ({
            ...prevState,
            filteredData: filterList(users, value),
        })), () => {
            callback && callback();
        });
    }


    render() {
        const { filteredData, loading } = this.state;

        return (
            <Fragment>
                <div className="users-header">
                    <SearchBar
                        placeholder="Unverified Users"
                        allowClear
                        width={400}
                        onSearch={this.onSearch}
                    />
                    <div className="total-count">Total Results : {_.size(filteredData)}</div>
                </div>
                <ValtixTable
                    name="listUnverifiedUsers"
                    loading={loading}
                    data={filteredData}
                    columns={columns}
                />
            </Fragment>
        );
    }
}

export default ListUsers;
