import _ from "lodash";

const IS_GLOBAL_DASHBOARD = "IsGlobalDashboard";
const SELECTED_DASHBOARD = "SelectedDashboard";

export const IsGlobalDashboard = () => localStorage.getItem(IS_GLOBAL_DASHBOARD) === "true";
export const SetIsGlobalDashboard = (value) => localStorage.setItem(IS_GLOBAL_DASHBOARD, value);

export const GetSelectedDashboard = () => localStorage.getItem(SELECTED_DASHBOARD)
export const SetSelectedDashboard = (value) => localStorage.setItem(SELECTED_DASHBOARD, value)

export const getDashboardHeaderForAPI = () => {
    const selectedDashboard = GetSelectedDashboard();
    const isGlobalDashboard = IsGlobalDashboard();
    if (isGlobalDashboard && selectedDashboard !== "") {
        return {
            "Dashboard": selectedDashboard,
        }
    }
    return {}
}

export const getDashboardHeader = (dashboard) => {
    if (_.get(dashboard, "id")) {
        return {
            "Dashboard": _.get(dashboard, "id"),
        }
    }
    return {}
}
