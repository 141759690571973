import React, { Component } from "react";
import { PostRequestResolve } from "utils/FetchHelper";
import URL from "utils/url";
import { Alert, Col, Row, Space, Spin, Modal } from "antd";
import CustomButton from "common/CustomButton/Button";
import "./AccountDetails.scss";
import * as _ from "lodash";
import { Message } from "variables/general";
import History from "utils/history";
import CreateSetting from "../settings/CreateSetting";
import ListGateways from "../gateways/ListGateways";
import { roundValues } from "utils/Utilities";
import { Tabs } from 'antd';
import AccountDetailsSummary from "./AccountDetailsSummary";
import DefaultSettings from "./DefaultSettings";
import AccountSettings from "./AccountSettings";
import Configuration from "./Configuration";
import CreateAccount from "../CreateAccount";
import ListAddresses from "../addresses/ListAddresses";
import { isValidUrl } from "../../../utils/Utilities";
import ListUsers from "../users/ListUsers";
import AccountProperties from "../properties";
const { TabPane } = Tabs;

class AccountDetails extends Component {
  controller = new window.AbortController();

  state = {
    accountDetails: {},
    loading: false,
    showEditAccountModal: false,
    showCreateSettingModal: false,
    accountSummaryLoading: false,
    accountSettings: [],
    availableSettings: [],
    defaultAccountSettings: [],
    account: {}
  };

  componentDidMount() {
    this.toggleLoading(this.getAccountDetails);
    this.getAccountSummary();
  }

  getAccountSummary = () => {
    this.setState({
      accountSummaryLoading: true,
    });
    const endTime = Math.round(new Date().getTime() / 1000);
    const startTime = Math.round((new Date(new Date() - (24 * 60 * 60 * 1000))).getTime() / 1000); // Last 1 Day
    const payload = {
      acctName: this.getAcctName(),
      startTime: { seconds: startTime },
      endTime: { seconds: endTime },
    };
    PostRequestResolve(URL.getAccountSummary, this.controller.signal, payload)
      .then((resp) => {
        const { usages, ...rest } = resp;
        const currentMonth = this.getTotalUsage(_.get(usages, "currentMonth.entries.[0].usageRecords", []));
        const lastMonth = this.getTotalUsage(_.get(usages, "lastMonth.entries.[0].usageRecords", []));
        this.setState({
          accountSummary: {
            ...rest,
            usages: {
              currentMonth,
              lastMonth,
            }
          },
          accountSummaryLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          accountSummaryLoading: false,
        });
        Message.error("Account Summary", err);
      });
  };

  getTotalUsage = (usageRecords) => {
    let totalHours = 0;
    usageRecords.forEach((record) => {
      totalHours += _.get(record, "baseInstanceHours", 0);
    });
    return roundValues(totalHours, 2);
  };

  downloadSummary = (accountSummary = []) => {
    if (accountSummary && Array.isArray(accountSummary) && accountSummary.length > 0) {
      let csvContent = "data:text/csv;charset=utf-8,";
      _.forEach(accountSummary, row => {
        csvContent += (_.get(row, "title") || `,"${_.get(row, "label", "")}","${_.get(row, "value", "")}"\r\n`);
      })
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${this.getAcctName()}.csv`);
      document.body.appendChild(link); // Required for FF

      link.click();
    }
  }

  getAccountDetails = () => {
    const payload = {
      name: this.getAcctName(),
    };
    PostRequestResolve(URL.getAccountDetails, this.controller.signal, payload)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          accountDetails: resp,
          loading: false,
        }));
      })
      .catch((err) => {
        this.setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
        Message.error("Account Details", err);
      });
  };

  getAccountSettings = () => {
    const payload = {
      acctName: this.getAcctName(),
    };
    PostRequestResolve(URL.listAccountSettings, this.controller.signal, payload)
      .then((resp) => {
        const settings = _.get(resp, "settings", []).map(d => ({
          ...d,
          ID: d.Name,
        }));
        this.setState((prevState) => ({
          ...prevState,
          accountSettings: settings,
          defaultAccountSettings: _.get(resp, "defaultSettings", []),
          availableSettings: _.get(resp, "availableSettings", []),
          loading: false,
        }));
      })
      .catch((err) => {
        this.setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
        Message.error("Account Settings", err);
      });
  };

  toggleLoading = (callback) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        loading: true,
      }),
      () => {
        typeof callback === "function" && callback();
      }
    );
  };

  getAcctName = () => {
    const {
      match: { params },
    } = this.props;
    return params.acctName;
  };

  componentWillUnmount() {
    this.controller.abort();
  }

  upgradeAccount = () => {
    const payload = {
      name: this.getAcctName(),
    };
    PostRequestResolve(URL.upgradeAccount, this.controller.signal, payload)
      .then((resp) => {
        this.setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
        Message.success(
          "Upgrade Account",
          `Upgraded ${this.getAcctName()} successfully`
        );
      })
      .catch((err) => {
        this.setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
        Message.error("Upgrade Account", err);
      });
  };

  openUpgradeModal = () => {
    this.toggleLoading(this.upgradeAccount);
  };

  openEditAccountModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showEditAccountModal: true,
      showCreateSettingModal: false,
      showEditSettingModal: false,
    }));
  };

  openCreateSettingModal = () => {
    this.setState({
      showCreateSettingModal: true,
      showEditSettingModal: false,
    });
  }

  openEditSettingModal = () => {
    this.setState({
      showCreateSettingModal: false,
      showEditSettingModal: true,
    });
  }

  openDeleteAccountSettingsModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDeleteAccountSettingsModal: true,
    }));
  };

  closeEditAccountModal = (reload) => {
    this.setState((prevState) => ({
      ...prevState,
      showEditAccountModal: false,
    }));
    if (reload === true) {
      this.toggleLoading(this.getAccountDetails);
    }
  };

  closeCreateSettingModal = (reload) => {
    this.setState((prevState) => ({
      ...prevState,
      showCreateSettingModal: false,
      showEditSettingModal: false,
    }));
    if (reload === true) {
      this.toggleLoading(this.getAccountSettings);
    }
  };

  closeEditSettingModal = (reload) => {
    this.setState((prevState) => ({
      ...prevState,
      showCreateSettingModal: false,
      showEditSettingModal: false,
    }));
    if (reload === true) {
      this.toggleLoading(this.getAccountSettings);
    }
  };

  closeDeleteAccountSettingsModal = (deleteAccountSettings) => {
    if (deleteAccountSettings === true) {
      this.deleteAccountSettings();
    } else {
      this.setState({
        showDeleteAccountSettingsModal: false,
      });
    }
  };

  deleteAccountSettings = () => {
    this.setState({
      deleteAccountSettingsLoading: true,
    });
    const { selectedSetting } = this.state;
    if (!selectedSetting) return;
    const { Name, Value } = selectedSetting;
    const payload = {
      acctName: this.getAcctName(),
      accountSetting: {
        name: Name,
        value: Value
      }
    };
    PostRequestResolve(URL.deleteAccountSetting, this.controller.signal, payload)
      .then((resp) => {
        Message.success("Delete Account Setting", "Success");
        this.toggleLoading(this.getAccountSettings);
        this.setState({
          showDeleteAccountSettingsModal: false,
          selectedSetting: null,
          deleteAccountSettingsLoading: false,
        });
      })
      .catch((err) => {
        this.setState((prevState) => ({
          ...prevState,
          deleteAccountSettingsLoading: false,
        }));
        Message.error("Delete Account Setting", err);
      });
  }

  selectSetting = (setting) => {
    this.setState(prevState => ({
      ...prevState,
      selectedSetting: setting,
    }));
  };

  acctColumns = [
    {
      label: "ID",
      value: "ID",
    },
    {
      label: "State",
      value: "State",
    },
    {
      label: "Name",
      value: "Name",
    },
    {
      label: "Description",
      value: "Description",
    },
    {
      label: "Company",
      value: "Company",
    },
    {
      label: "Organization",
      value: "Organization",
    },
    {
      label: "Date Created",
      value: (acctDetails) =>
        acctDetails["CreatedTime"] &&
        (acctDetails["CreatedTime"] === "0001-01-01T00:00:00Z"
          ? ""
          : new Date(acctDetails["CreatedTime"]).toUTCString()),
    },
    {
      label: "Server Deployment",
      value: "ServerDeployment",
    },
  ];

  openSreDashboard = () => {
    const { accountDetails } = this.state;
    const sreUrl = _.get(accountDetails, "SREDashboardURL", "")

    if (!sreUrl) {
      Message.error("SRE Dashboard", "Url not found")
      return
    }

    // Launch New Tab
    if (isValidUrl(sreUrl)) {
      window.open(sreUrl, "_blank", "noreferrer");
    } else {
      Message.error("SRE Dashboard", "No valid url not found")
      return
    }
    console.log(sreUrl)
  }

  render() {
    const {
      accountDetails,
      loading,
      selectedSetting,
      showEditAccountModal,
      showCreateSettingModal,
      showEditSettingModal,
      accountSettings,
      availableSettings,
      defaultAccountSettings,
      accountSummary,
      accountSummaryLoading,
      showDeleteAccountSettingsModal,
      deleteAccountSettingsLoading,
    } = this.state;

    const accountSummaryCspAccount = _.map(_.get(accountSummary, "accounts", []), acc => ({
      label: _.upperCase(_.get(acc, "csp", "")),
      value: _.get(acc, "count", "0")
    }));
    const accountSummaryArray = [
      { label: "Current Month Usages", value: `${_.get(accountSummary, "usages.currentMonth", 0)} Hours` },
      { label: "Last Month Usages", value: `${_.get(accountSummary, "usages.lastMonth", 0)} Hours` },
      { label: "VPCs with Traffic", value: _.get(accountSummary, "resources.vpcWithTraffic", "0") },
      { label: "Running Gateways", value: _.get(accountSummary, "resources.gatewayRunning", "0") },
      { label: "Inactive Gateways", value: _.get(accountSummary, "resources.gatewayInactive", "0") },
      { label: "Service VPCs", value: _.get(accountSummary, "resources.serviceVpc", "0") },
      { label: "Spoke VPCs", value: _.get(accountSummary, "resources.spokeVpc", "0") },
      { label: "API Keys", value: _.get(accountSummary, "resources.apiKeys", "0") },
      { label: "Total Users", value: _.get(accountSummary, "users.total", "0") },
    ];
    return (
      <Row>
        <Col span={24}>
          {loading && (
            <Spin tip="Loading...">
              <Alert
                message="Account Details"
                description={this.getAcctName() + "'s account"}
                type="info"
              />
            </Spin>
          )}
          {!loading && (
            <Space direction="vertical" style={{ width: "100%" }}>
              <Row>
                <Space>
                  <CustomButton
                    type="primary"
                    onClick={() => History.goBack()}
                  >
                    Go Back
                  </CustomButton>
                  <CustomButton shape="round" type="primary" onClick={this.openEditAccountModal} >Edit
                  </CustomButton>
                </Space>
                <Space align="end" style={{ marginLeft: "auto" }}>
                  <CustomButton shape="round" type="primary" onClick={this.openSreDashboard}>SRE Dashboard</CustomButton>
                  <CustomButton shape="round" type="primary" onClick={() => this.toggleLoading(this.getAccountDetails)}>Refresh</CustomButton>
                </Space>
              </Row>
              <Tabs defaultActiveKey="1" >
                <TabPane tab="Account Details" key="1">
                  <AccountDetailsSummary
                    accountDetails={accountDetails}
                    accountSummaryCspAccount={accountSummaryCspAccount}
                    accountSummaryArray={accountSummaryArray}
                    accountSummaryLoading={accountSummaryLoading}
                    acctColumns={this.acctColumns}
                    downloadSummary={this.downloadSummary} />
                </TabPane>
                <TabPane tab="Configuration" key="2">
                  <Configuration accountDetails={accountDetails} />
                </TabPane>
                <TabPane tab="Settings" key="3">
                  <Tabs defaultActiveKey="2">
                    <TabPane tab="Default Settings" key="1">
                      <DefaultSettings defaultAccountSettings={defaultAccountSettings} />
                    </TabPane>
                    <TabPane tab="Account Settings" key="2">
                      <AccountSettings
                        getAccountSettings={this.getAccountSettings}
                        selectedSetting={selectedSetting}
                        openEditSettingModal={this.openEditSettingModal}
                        openCreateSettingModal={this.openCreateSettingModal}
                        loading={loading}
                        openDeleteAccountSettingsModal={this.openDeleteAccountSettingsModal}
                        accountSettings={accountSettings}
                        selectSetting={this.selectSetting} />
                    </TabPane>
                  </Tabs>
                </TabPane>
                <TabPane tab="Properties" key="4">
                  <AccountProperties
                    acctName={this.getAcctName()}
                    properties={_.get(accountDetails, "AccountProperties")}
                  />
                </TabPane>
                <TabPane tab="Users" key="5">
                  <ListUsers
                    name="usersList"
                    acctName={this.getAcctName()}
                  />
                </TabPane>
                <TabPane tab="Gateways" key="6">
                  <ListGateways
                    acctName={this.getAcctName()}
                  />
                </TabPane>
                <TabPane tab="Address Groups" key="7">
                  <ListAddresses
                    acctName={this.getAcctName()}
                  />
                </TabPane>
              </Tabs>
            </Space>
          )}
          {showEditAccountModal && (
            <CreateAccount
              isEdit={true}
              account={accountDetails}
              onClose={this.closeEditAccountModal}
            />
          )}
          {showCreateSettingModal && (
            <CreateSetting
              account={this.getAcctName()}
              onClose={this.closeCreateSettingModal}
              availableSettings={availableSettings}
            />
          )}
          {showEditSettingModal && (
            <CreateSetting
              account={this.getAcctName()}
              onClose={this.closeEditSettingModal}
              setting={selectedSetting}
            />
          )}
          <Modal
            title="Delete Account Settings"
            visible={showDeleteAccountSettingsModal}
            okText="Delete"
            okButtonProps={{
              danger: true,
              disabled: deleteAccountSettingsLoading,
            }}
            cancelButtonProps={{
              disabled: deleteAccountSettingsLoading,
            }}
            onOk={() => this.closeDeleteAccountSettingsModal(true)}
            onCancel={() => this.closeDeleteAccountSettingsModal()}
            confirmLoading={deleteAccountSettingsLoading}
          >Are you sure?</Modal>
        </Col>
      </Row>
    );
  }
}

export default AccountDetails;
