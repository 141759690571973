import "./AccountDetails.scss";
import React from "react";
import { Card, Col, Row, } from "antd";
import * as _ from "lodash";


class Configuration extends React.Component {
    render() {

        const {accountDetails } = this.props;
        return (
            <Card type="inner" title="Configuration">
                <Row>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <p>
                            <b>Active Gateways</b> :{" "}
                            {_.get(
                                accountDetails,
                                "Configuration.active_gateways",
                                0
                            )}
                        </p>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <p>
                            <b>Inactive Gateways</b> :{" "}
                            {_.get(
                                accountDetails,
                                "Configuration.inactive_gateways",
                                0
                            )}
                        </p>
                    </Col>
                    {_.get(
                        accountDetails,
                        "Configuration.tables_count",
                        {}
                    ) &&
                        Object.keys(
                            _.get(
                                accountDetails,
                                "Configuration.tables_count",
                                {}
                            )
                        ).map((k) => {
                            return (
                                <Col
                                    key={k.replace(" ", "")}
                                    xs={24}
                                    sm={24}
                                    md={12}
                                    lg={8}
                                    xl={6}
                                >
                                    <p>
                                        <b>{k}</b> :{" "}
                                        {
                                            _.get(
                                                accountDetails,
                                                "Configuration.tables_count",
                                                {}
                                            )[k]
                                        }
                                    </p>
                                </Col>
                            );
                        })}
                </Row>
            </Card>
        );
    }
}

export default Configuration;