import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Layout, Menu } from "antd";
import { hardRedirect } from '../../utils/history';
import GlobalDashboardContext from '../../common/ContextWrappers/Dashboard/Context';
import withContext from '../../hoc/withContext';
import { findSelectedMenuItem } from '../../routes';

class SideBar extends Component {
    state = {
        selectedKeys: [],
    }

    componentDidMount() {
        this.updatePath();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const path = window.location.pathname;
        const selected = findSelectedMenuItem(path);
        const { selectedKeys: prevSelected = [] } = prevState;
        if (selected.some(k => !prevSelected.includes(k) || prevSelected.some(k => !selected.includes(k)))) {
            this.updatePath();
        }
    }

    onClick = ({ key }) => {
        this.setState((prevState) => ({
            ...prevState,
            selectedKeys: [key],
        }));
        hardRedirect(key);
    }

    updatePath = () => {
        const path = window.location.pathname;
        const selected = findSelectedMenuItem(path);
        this.setState({
            selectedKeys: selected,
        });
    }

    render() {
        const { routes, children } = this.props;
        const { selectedKeys } = this.state;
        return (
            <Layout.Sider theme={"light"}>
                <Menu selectedKeys={selectedKeys} onClick={this.onClick} items={routes} mode='inline'/>
                {children}
            </Layout.Sider>
        );
    }
}

SideBar.propTypes = {
    routes: PropTypes.array,
    children: PropTypes.node,
}

SideBar.defaultProp = {
    routes: [],
}


export default withContext(SideBar, GlobalDashboardContext);