export const columns = [
    {
        title: 'Account',
        dataIndex: 'account',
        key: 'account',
    },
    {
        title: 'Profiles',
        dataIndex: 'profiles',
        key: 'profile',
    },
];