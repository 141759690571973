import React, { Component, Fragment } from 'react';
import URL from "../../utils/url";
import { columns } from "./Columns";
import { Message } from "../../variables/general";
import _ from 'lodash';
import { PostRequest } from '../../utils/FetchHelper';
import ValtixTable from '../../common/Table/Table';
import { Select, DatePicker, Space, Alert } from 'antd';
import dayjs from "dayjs";
import { DownloadCsv } from '../../utils/csvUtils';
import FontAwesome from 'react-fontawesome';
import DeploymentContext from '../../common/ContextWrappers/Deployment/Context';
import withContext from '../../hoc/withContext';

const { RangePicker } = DatePicker;

class AuditLogs extends Component {
    static contextType = DeploymentContext;
    controller = new window.AbortController();

    state = {
        auditLogs: [],
        loadingLogs: false,
        peek: false,
        orderDesc: true,
        accounts: [],
        loadingAccounts: false,
        selectedAccount: undefined,
    };

    componentDidMount() {
        const { onChangeCallback } = this.context;
        onChangeCallback(this.onDeploymentSelect);
        this.onDeploymentSelect();
    }

    componentWillUnmount() {
        const { clearOnChangeCallback } = this.context;
        this.controller.abort();
        clearOnChangeCallback();
    }

    onDeploymentSelect = (value) => {
        const { selectedDeployment } = this.context;
        if (!selectedDeployment) return;
        this.setState({
            accounts: [],
            filteredData: [],
        }, () => {
            this.getAuditLogs();
            this.getAccounts();
        });
    }

    toggleLoading = (callback) => {
        this.setState((prevState) => ({
            ...prevState,
            loadingLogs: !prevState.loadingLogs,
        }), () => {
            typeof callback === "function" && callback();
        })
    }

    getAccounts = () => {
        this.setState({
            loadingAccounts: true,
        });
        const { selectedDeployment } = this.context;
        PostRequest(URL.listAccountNames, this.controller.signal, { serverDeployment: selectedDeployment }, (resp) => {
            const accounts = _.map(resp || [], a => ({
                label: a,
                value: a,
            }));
            // this.addCustomKey(gateways);
            this.setState(prevState => ({
                ...prevState,
                accounts: accounts,
                loadingAccounts: false,
            }));
        }, (err) => {
            this.setState({
                loadingAccounts: false,
            });
            Message.error("Accounts", err);
        });
    }

    onAccountSelect = (value) => {
        this.setState({
            selectedAccount: value,
        }, () => {
            this.getAuditLogs();
        });
    }

    getAuditLogs = (callback) => {
        const { selectedDeployment } = this.context;
        const { selectedAccount } = this.state;
        if (!selectedAccount) {
            return
        }
        let { startTime, endTime } = this.state;
        if (!startTime || !endTime) {
            endTime = Math.round(new Date().getTime() / 1000);
            startTime = Math.round((new Date(new Date() - (24 * 60 * 60 * 1000))).getTime() / 1000);
            this.setState({
                startTime,
                endTime
            });
        }
        const payload = {
            serverDeployment: selectedDeployment,
            ...(selectedAccount ? { common: { acctName: selectedAccount } } : {}),
            startTime: { seconds: startTime },
            endTime: { seconds: endTime },
            numLogs: 50,
        }
        this.toggleLoading(() => {
            PostRequest(URL.getAuditLogs, this.controller.signal, payload, (resp) => {
                const logs = _.get(resp, "eventsList", []).map(e => JSON.parse(_.get(e, "message", "")));
                // this.addCustomKey(gateways);
                this.setState(prevState => ({
                    ...prevState,
                    auditLogs: logs,
                }));
                this.toggleLoading(callback);
            }, (err) => {
                this.toggleLoading();
                Message.error("Audit Logs", err);
            })
        })

    }


    onDateChange = (dates) => {
        try {
            const startTime = Math.round(dates[0].format("X"));
            const endTime = Math.round(dates[1].format("X"));
            this.setState({
                startTime,
                endTime
            }, () => {
                this.getAuditLogs();
            });
        } catch (err) {
            console.log(err);
        }
    }

    onDownload = () => {
        const { auditLogs } = this.state;
        const headers = columns().map(v => v.key);
        DownloadCsv(auditLogs, headers, "auditLogs");
    }

    render() {
        const { auditLogs, loadingLogs, accounts, loadingAccounts, selectedAccount } = this.state;
        return (
            <Fragment>
                <Space>
                    Account
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Select Account"
                        options={accounts}
                        loading={loadingAccounts}
                        value={selectedAccount}
                        onChange={this.onAccountSelect}
                        allowClear
                    />
                    Time Range
                    <RangePicker
                        showTime
                        defaultValue={[dayjs().add(-1, "days").add(-1, "seconds"), dayjs()]}
                        ranges={{
                            "Last 15 min": [dayjs().add(-1, "minutes").add(-1, "seconds"), dayjs()],
                            "Last 1 Day": [dayjs().add(-1, "days").add(-1, "seconds"), dayjs()],
                            "Last 7 Day": [dayjs().add(-7, "days").add(-1, "seconds"), dayjs()],
                            "Last 30 Day": [dayjs().add(-30, "days").add(-1, "seconds"), dayjs()],
                        }}
                        onChange={this.onDateChange}
                    />
                </Space>
                {!selectedAccount ? (
                    <Alert style={{ marginTop: "16px", padding: "16px" }} message="Please Select Account" type="warning" />
                ) : (
                    <ValtixTable
                        title={() => (<div style={{ display: 'flex' }}><span style={{ flex: '1' }}>Audit Logs</span><FontAwesome name="download" className="csv-download-button" onClick={this.onDownload}></FontAwesome></div>)}
                        name="auditlogs"
                        loading={loadingLogs}
                        data={auditLogs}
                        columns={columns()}
                    />
                )}
            </Fragment>
        );
    }
}

export default withContext(AuditLogs, DeploymentContext);