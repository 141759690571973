import {compare} from "../../variables/general";
import { getURLParam, replaceURLParam } from "../../utils/Utilities";
import { StateCell } from "../../common/Table/StateCell";

export const columns = [
    {
        title: 'ID',
        dataIndex: 'user_id',
        key: 'user_id',
        defaultSortOrder: getURLParam('user_id'),
        sorter: (a, b) => compare(a.user_id, b.user_id),
        sortDirections: ['descend', 'ascend', 'descend'],
        onHeaderCell: () => ({
            onClick: () => replaceURLParam('user_id', getURLParam('user_id') === 'ascend' || !getURLParam('user_id') ? 'descend' : 
          'ascend'),
        })
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        defaultSortOrder: getURLParam('name'),
        sorter: (a, b) => compare(a.name, b.name),
        sortDirections: ['descend', 'ascend', 'descend'],
        onHeaderCell: () => ({
            onClick: () => replaceURLParam('name', getURLParam('name') === 'ascend' || !getURLParam('name') ? 'descend' : 
          'ascend'),
        })
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 200,
        defaultSortOrder: getURLParam('email'),
        sorter: (a, b) => compare(a.email, b.email),
        sortDirections: ['descend', 'ascend', 'descend'],
        onHeaderCell: () => ({
            onClick: () => replaceURLParam('email', getURLParam('email') === 'ascend' || !getURLParam('email') ? 'descend' : 
          'ascend'),
        })
    },
    {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        defaultSortOrder: getURLParam('role'),
        sorter: (a, b) => compare(a.role, b.role),
        sortDirections: ['descend', 'ascend', 'descend'],
        onHeaderCell: () => ({
            onClick: () => replaceURLParam('role', getURLParam('role') === 'ascend' || !getURLParam('role') ? 'descend' : 
          'ascend'),
        })
    },
    {
      title: 'Tenant',
      dataIndex: 'tenant',
      key: 'tenant',
      defaultSortOrder: getURLParam('tenant'),
      sorter: (a, b) => compare(a.tenant, b.tenant),
      sortDirections: ['descend', 'ascend', 'descend'],
      onHeaderCell: () => ({
          onClick: () => replaceURLParam('tenant', getURLParam('tenant') === 'ascend' || !getURLParam('tenant') ? 'descend' : 
        'ascend'),
      })
  },
    {
        title: 'Last Login',
        dataIndex: 'last_login',
        key: 'last_login',
        defaultSortOrder: getURLParam('last_login'),
        sorter: (a, b) => compare(a.last_login, b.last_login),
        sortDirections: ['descend', 'ascend', 'descend'],
        onHeaderCell: () => ({
            onClick: () => replaceURLParam('last_login', getURLParam('last_login') === 'ascend' || !getURLParam('last_login') ? 'descend' : 
          'ascend'),
        })
    },
    {
        title: 'Date Created',
        dataIndex: 'created_at',
        key: 'created_at',
        render: val => val === "0001-01-01T00:00:00Z" ? "" : new Date(val).toUTCString(),
        width: 250,
        defaultSortOrder: !window.location.search ? "descend" : getURLParam('created_at'),
        sorter: (a, b) => compare(a.created_at, b.created_at),
        sortDirections: ['descend', 'ascend', 'descend'],
        onHeaderCell: () => ({
            onClick: () => replaceURLParam('created_at', getURLParam('created_at') === 'ascend' || !getURLParam('created_at') ? 'descend' : 
          'ascend'),
        })
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (value) => (<StateCell text={value ? "Active" : "Inactive"} />)
    },
];
