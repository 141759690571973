import React from "react";
import PropTypes from "prop-types";

const DashboardContext = React.createContext({
    dashboardList: [],
    selectedDashboard: "",
    onChangeDashboard: () => { },
    isGlobalDashboard: false,
    loadingDashboardList: false,
});

export default DashboardContext;

export const DashboardContextPropTypes = {
    dashboardList: PropTypes.array.isRequired,
    selectedDashboard: PropTypes.string.isRequired,
    onChangeDashboard: PropTypes.func.isRequired,
    isGlobalDashboard: PropTypes.bool.isRequired,
    loadingDashboardList: PropTypes.bool.isRequired,
};
