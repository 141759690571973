// Changing base url based on environment
// for development environment picking base url
// from either .env.development or .env.development.local
const BASE_URL = (process.env.NODE_ENV === 'development') ? "" : `${window.location.origin}`;
/**
 * NOTE: Check src/setupProxy.js to see if proxy is created for newly added urls
 *       if not please update `URLS_REGEX` and restart the server
 */
const URL = {
  getAuth0Settings: `${BASE_URL}/api/admin/settings`,
  getAppDetails: `${BASE_URL}/about`,
  getCaptchaKey: `${BASE_URL}/captcha/key`,
  getAccessTokenOnRefresh: `${BASE_URL}/api/token/refresh`,

  listDashboards: `${BASE_URL}/api/dashboards/list`,

  /* BEGIN ACCOUNTS APIs */
  accountsSummary: `${BASE_URL}/api/account/summary`,
  getAccountSummary: `${BASE_URL}/api/account/get/summary`,
  listAccounts: `${BASE_URL}/api/account/list`,
  listAccountNames: `${BASE_URL}/api/account/names/list`,
  listAccountSettings: `${BASE_URL}/api/account/settings/list`,
  getAccountDetails: `${BASE_URL}/api/account/get`,
  createAccount: `${BASE_URL}/api/account/create`,
  updateAccount: `${BASE_URL}/api/account/update`,
  upgradeAccount: `${BASE_URL}/api/account/upgrade`,
  deleteAccount: `${BASE_URL}/api/account/delete`,
  createAccountSetting: `${BASE_URL}/api/account/settings/create`,
  updateAccountSetting: `${BASE_URL}/api/account/settings/update`,
  deleteAccountSetting: `${BASE_URL}/api/account/settings/delete`,
  updateAccountProperties: `${BASE_URL}/api/account/properties/update`,
  listdeployments: `${BASE_URL}/api/deployments/list`,
  listServerDeployments: `${BASE_URL}/api/serverdeployments/list`,
  getServerDeployment: `${BASE_URL}/api/account/serverdeployments/current/get`,
  /* END ACCOUNTS APIs */

  /* BEGIN IMAGE REGISTRY APIs*/
  listImages: `${BASE_URL}/api/imageregistry/list`,
  getImage: `${BASE_URL}/api/imageregistry/get`,
  createImage: `${BASE_URL}/api/imageregistry/create`,
  imageRegistrySummary: `${BASE_URL}/api/imageregistry/summary`,
  createPrivateImage: `${BASE_URL}/api/imageregistry/private/account/create`,
  updatePrivateImage: `${BASE_URL}/api/imageregistry/private/account/update`,
  deletePrivateImage: `${BASE_URL}/api/imageregistry/private/account/delete`,
  /* END IMAGE REGISTRY APIs*/

  /* BEGIN USER APIs*/
  createUser: `${BASE_URL}/api/user/create`,
  updateUser: `${BASE_URL}/api/user/update`,
  deleteUser: `${BASE_URL}/api/user/delete`,
  listUsers: `${BASE_URL}/api/users/list`,
  listUnverifiedUsers: `${BASE_URL}/api/users/unverified/list`,
  unverifiedUsersSummary: `${BASE_URL}/api/users/unverified/summary`,
  activeUsersSummary: `${BASE_URL}/api/users/active/summary`,
  migrateSamlUsers: `${BASE_URL}/api/user/saml/migrate`,
  /* END USER APIs*/
  
  /* BEGIN VENDOR RULESET APIs*/
  getVendorRuleset: `${BASE_URL}/api/vendorruleset/get`,
  listVendorRulesets: `${BASE_URL}/api/vendorruleset/list`,
  /* END VENDOR RULESET APIs*/

  /* BEGIN SQL QUERY APIs*/
  executeQuery: `${BASE_URL}/api/sqlquery/execute`,
  /* END SQL QUERY APIs*/

  /* BEGIN GATEWAY APIs*/
  gatewaySummary: `${BASE_URL}/api/gateway/summary`,
  listGateways: `${BASE_URL}/api/gateway/list`,
  triggerInbandDiagnostics: `${BASE_URL}/api/gateway/instance/debug/inband_diagnostics`,
  terminateInstance: `${BASE_URL}/api/gateway/instance/terminate`,
  listGatewayInstanceIds: `${BASE_URL}/api/gateway/instance/list`,
  addInstances: `${BASE_URL}/api/gateway/instance/create`,
  listGatewaySettings: `${BASE_URL}/api/gateway/setting/list`,
  updateGatewaySettings: `${BASE_URL}/api/gateway/setting/update`,
  /* END GATEWAY APIs*/

  /* BEGIN ADDRESS APIs*/
  listAddress: `${BASE_URL}/api/address/list`,
  createAddress: `${BASE_URL}/api/address/create`,
  deleteAddress: `${BASE_URL}/api/address/delete`,
  updateAddress: `${BASE_URL}/api/address/update`,
  /* END ADDRESS APIs*/

  /* BEGIN USER QUARATINE */
  listUserQuarantine: `${BASE_URL}/api/userquarantine/list`,
  approveUserQuarantine: `${BASE_URL}/api/userquarantine/approve`,
  deleteUserQuarantine: `${BASE_URL}/api/userquarantine/delete`,
  /* END USER QUARANTINE*/

  /* BEGIN SERVER HEALTH APIs*/
  getServerHealth: `${BASE_URL}/api/serverhealth/get`,
   /* END SERVER HEALTH APIs*/

  /* BEGIN LOGS APIs*/
  getAuditLogs: `${BASE_URL}/api/logs/audit/get`,
  /* END LOGS APIs*/
};

// Using module.export since it is required by setupProxy.js
module.exports = URL;
