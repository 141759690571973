import {compare} from "../../variables/general";
import {hardRedirect} from "../../utils/history";
import RoutePaths from "../../utils/RoutePaths";
import { StateCell } from '../../common/Table/StateCell';
import { getURLParam, replaceURLParam } from "../../utils/Utilities";

export const columns = () => [
    {
        title: 'ID',
        dataIndex: 'ID',
        key: 'id',
        fixed: 'left',
        sorter: (a, b) => a.ID - b.ID,
        sortDirections: ['descend', 'ascend', 'descend'],
        width: 80,
        defaultSortOrder: getURLParam('id'),
        onHeaderCell: () => ({
            onClick: () => replaceURLParam('id', getURLParam('id') === 'ascend' || !getURLParam('id') ? 'descend' : 
          'ascend'),
        })
    },
    {
        title: 'Name',
        dataIndex: 'Name',
        key: 'name',
        sortDirections: ['descend', 'ascend', 'descend'],
        sorter: (a, b) => compare(a.customKey, b.customKey, true),
        width: 240,
        defaultSortOrder: getURLParam('name'),
        onHeaderCell: () => ({
            onClick: () => replaceURLParam('name', getURLParam('name') === 'ascend' || !getURLParam('name') ? 'descend' : 
          'ascend'),
        })
    },
    {
        title: "Description",
        dataIndex: 'Description',
        key: 'description',
        width: 150,
    },{
        title: "Type",
        dataIndex: 'Type',
        key: 'type',
        width: 80,
    },{
        title: "Published",
        dataIndex: 'IsPrivate',
        key: 'published',
        render: value => (typeof value === "boolean" && (value ? "Private" : "Public")),
        width: 100,
    },
    {
        title: 'Latest',
        dataIndex: 'Latest',
        key: 'latest',
        sorter: (a, b) => compare(a.Latest, b.Latest),
        sortDirections: ['descend', 'ascend', 'descend'],
        width: 110,
        defaultSortOrder: getURLParam('latest'),
        render: value => (typeof value === "boolean" && (value ? "True" : "False")),
        onHeaderCell: () => ({
            onClick: () => replaceURLParam('latest', getURLParam('latest') === 'ascend' || !getURLParam('latest') ? 'descend' : 
          'ascend'),
        })
    },
    {
        title: 'Accounts',
        dataIndex: 'AccountsCount',
        key: 'accounts',
        sorter: (a, b) => compare(a.AccountsCount, b.AccountsCount),
        sortDirections: ['descend', 'ascend', 'descend'],
        width: 110,
        defaultSortOrder: getURLParam('accounts'),
        onHeaderCell: () => ({
            onClick: () => replaceURLParam('accounts', getURLParam('accounts') === 'ascend' || !getURLParam('accounts') ? 'descend' : 
          'ascend'),
        })
    },
    {
        title: 'Gateways',
        dataIndex: 'GatewaysCount',
        key: 'gateways',
        sorter: (a, b) => compare(a.GatewaysCount, b.GatewaysCount),
        sortDirections: ['descend', 'ascend', 'descend'],
        width: 110,
        defaultSortOrder: getURLParam('gateways'),
        onHeaderCell: () => ({
            onClick: () => replaceURLParam('gateways', getURLParam('gateways') === 'ascend' || !getURLParam('gateways') ? 'descend' : 
          'ascend'),
        })
    },{
        title: "Path",
        dataIndex: 'Path',
        key: 'path',
        width: 820,
        ellipsis: true,
    }
];

export const AccountsColumns = () => [
    {
        title: 'Account',
        dataIndex: 'account',
        key: 'account',
        width: 240,
        render: (value) => ({
            children: value,
            props: {
                className: "link-cell",
                onClick: () => {
                    hardRedirect(`${RoutePaths.getAccount}/${value}`);
                }
            },
        }),
        sorter: (a, b) => compare(a.account, b.account),
        sortDirections: ['descend', 'ascend', 'descend'],
        defaultSortOrder: getURLParam('account'),
        onHeaderCell: () => ({
            onClick: () => replaceURLParam('account', getURLParam('account') === 'ascend' || !getURLParam('account') ? 'descend' : 
          'ascend'),
        })
    },
    {
        title: 'Gateway',
        dataIndex: 'gateway',
        key: 'gateway',
        sorter: (a, b) => compare(a.gateway, b.gateway),
        sortDirections: ['descend', 'ascend', 'descend'],
        defaultSortOrder: getURLParam('gateway'),
        onHeaderCell: () => ({
            onClick: () => replaceURLParam('gateway', getURLParam('gateway') === 'ascend' || !getURLParam('gateway') ? 'descend' : 
          'ascend'),
        })
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        sorter: (a, b) => compare(a.state, b.state),
        sortDirections: ['descend', 'ascend', 'descend'],
        render: val => <StateCell text={val} />,
        defaultSortOrder: getURLParam('state'),
        onHeaderCell: () => ({
            onClick: () => replaceURLParam('state', getURLParam('state') === 'ascend' || !getURLParam('state') ? 'descend' : 
          'ascend'),
        })
    },
];


export const PrivateDeploymentColumns = () => [
    {
        title: 'Account',
        dataIndex: 'Accounts',
        key: 'Accounts',
        width: 240,
        render: (value) => ({
            children: value,
        }),
        sorter: (a, b) => compare(a.Accounts, b.Accounts),
        sortDirections: ['descend', 'ascend', 'descend'],
        defaultSortOrder: getURLParam('account'),
        onHeaderCell: () => ({
            onClick: () => replaceURLParam('account', getURLParam('account') === 'ascend' || !getURLParam('account') ? 'descend' : 
          'ascend'),
        })
    },
    {
        title: 'Server Deployment',
        dataIndex: 'ServerDeployment',
        key: 'ServerDeployment',
        width: 240,
        render: (value) => ({
            children: value,
            props: {
                className: "link-cell",
                onClick : () => 
                    hardRedirect(`${RoutePaths.getAccount}/${value}`),
            },
        }),
        sorter: (a, b) => compare(a.ServerDeployment, b.ServerDeployment),
        sortDirections: ['descend', 'ascend', 'descend'],
        defaultSortOrder: getURLParam('server-deployment'),
        onHeaderCell: () => ({
            onClick: () => replaceURLParam('server-deployment', getURLParam('server-deployment') === 'ascend' || !getURLParam('server-deployment') ? 'descend' : 
          'ascend'),
        })
    },
];