import { Auth0Client } from '@auth0/auth0-spa-js';
import jwtDecode from "jwt-decode";
import GetRequest, { PostRequest } from './FetchHelper';
import { redirectTo, replaceWith } from "./history";
import RoutePaths from "./RoutePaths";
import _ from "lodash";
import URL from './url';
import { Button, notification } from 'antd';

export const AUTH_LOGOUT_REDIRECT_URI = `${window.location.origin}${RoutePaths.login}`;

export const ACCESS_TOKEN_KEY = 'access-token';
export const REFRESH_TOKEN_KEY = 'refresh-token';

const TOKEN_EXPIRY_KEY = 'exp';

const ACCOUNT_ROLE = 'http://role_name';

const ACCOUNT_NAME = "http://account_name";

const ACCOUNT_EMAIL = "http://email";

export const SAML_ENABLED = "samlEnabled";

export const storage = window.localStorage;

// Refresh Token should be more than expire time otherwise it returns same token, also auth0 should have login
export const getRefreshTime = expiresIn => (expiresIn + 1) * 1000;

// store timers based on { expiryKey: timer }
const tokenRefreshTimer = {};

const disableTokenRefresh = (expiryKey) => {
  const timer = _.get(tokenRefreshTimer, expiryKey);
  if (timer) {
    clearTimeout(timer);
    tokenRefreshTimer[expiryKey] = undefined;
  }
};

const disableAllRefreshToken = () => {
  _.forEach(tokenRefreshTimer, (v) => {
    clearTimeout(v);
  })
}

export const checkEnableTokenRefresh = (refreshFunc) => {
  try {
    const expiresAt = getExpireAt();
    if (expiresAt === null) {
      return;
    }
    console.log(expiresAt);
    const expiresIn = parseInt(expiresAt, 10) - new Date().getTime();
    const intervalTime = getRefreshTime(parseInt(expiresIn / 1000, 10))

    console.info(`refreshing token in ${intervalTime}`);
    // disable any previous timers
    disableTokenRefresh(TOKEN_EXPIRY_KEY);

    tokenRefreshTimer[TOKEN_EXPIRY_KEY] = setTimeout(refreshFunc, 180000);
  } catch (err) {
    console.error(err);
  }
};

let Auth0 = undefined;

const getAuth0Client = (domain, clientId, audience) => new Auth0Client({
  domain: domain,
  client_id: clientId,
  redirect_uri: `${window.location.origin}/callback`,
  scope: 'openid',
  cacheLocation: 'localstorage',
  audience,
});

export const getAuth0 = async () => {
  if (!Auth0) {
    await GetRequest(URL.getAuth0Settings, null).then(settings => {
      const { domain, clientId, audience } = (settings || {})
      Auth0 = getAuth0Client(domain, clientId, audience);
    }).catch(err => {
      console.log("Error while getting auth settings " + err)
    })
  }
  return Auth0;
}


// refresh token
export const refreshToken = async () => {
  // if no token is present clearing timeout
  if (getToken() === "") {
    disableTokenRefresh(TOKEN_EXPIRY_KEY);
    return;
  }
  try {
    await fetchToken();
  } catch (err) {
    openErrorNotification(err);
    console.log(err);
  }
};

// handles fetching of new token
const fetchToken = async () => {
  const samlEnabled = IsSamlLoginEnabled();
  if (!samlEnabled) {
    // auth0
    const auth0 = await getAuth0();
    const authResult = await auth0.getTokenSilently({
      detailedResponse: true,
    });
    const accessToken = _.get(authResult, "access_token");
    setSession(accessToken);
    checkEnableTokenRefresh(refreshToken);
  } else {
    const controller = new window.AbortController();
    const refresh_Token = storage.getItem(REFRESH_TOKEN_KEY);
    if (refresh_Token) {
      PostRequest(URL.getAccessTokenOnRefresh, controller.signal, { refreshToken: refresh_Token }, (resp) => {
        setSession(resp.accessToken, resp.refreshToken);
        checkEnableTokenRefresh(refreshToken);
      }, (err) => {
        console.log("Error while getting access token on refresh " + err)
      }, true);
    }
  }
}


export const auth0Login = async () => {
  const auth0 = await getAuth0();
  await auth0.loginWithRedirect();
};

export const setSession = (accessToken, refreshToken) => {
  storage.setItem(ACCESS_TOKEN_KEY, accessToken);
  storage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  // navigate to the home route
  replaceWith(RoutePaths.homePage);
};

const openErrorNotification = (err) => {
  const notificationKey = `open${Date.now()}`;
  const btn = (
    <Button type="primary" size="small" onClick={() => {
      replaceWith(`${RoutePaths.login}`);
      notification.close(notificationKey);
    }}>
      Login Manually
    </Button>
  );
  notification.error({
    message: 'Refresh Token Fails',
    description: `${err}`,
    btn,
    duration: 0,
    key: notificationKey,
  })
}

export const handleAuthentication = async () => {
  try {
    const auth0 = await getAuth0();
    await auth0.handleRedirectCallback();
    await fetchToken();
  } catch (err) {
    replaceWith(`${RoutePaths.login}?error=${err}`);
    console.log(err);
    alert(`Error: ${err.error}. Check the console for further details.`);
  }
};


export const logout = () => {
  const samlEnabled = IsSamlLoginEnabled();
  disableAllRefreshToken();
  removeLocalData();
  if (samlEnabled) {
    redirectTo("/login");
  } else {
    getAuth0().then(auth0 =>
      auth0.logout({
        returnTo: AUTH_LOGOUT_REDIRECT_URI,
      }));
  }
};

export const IsSamlLoginEnabled = () => storage.getItem(SAML_ENABLED) === "true";


export default auth0Login;

export const isLoggedIn = () => {
  try {
    const expiresAt = getExpireAt();
    // check if token is present and also it is not expired
    if (expiresAt && (new Date().getTime() < expiresAt)) {
      return true;
    }
  } catch (err) { 
    console.error(err)
  }
  // removing user data
  removeLocalData();
  return false;
};

// Retrieving data from session storage
export const getToken = () => storage.getItem(ACCESS_TOKEN_KEY);

// Removing user data from storage
const removeLocalData = () => {
  const items = [
    ACCESS_TOKEN_KEY,
    TOKEN_EXPIRY_KEY,
    REFRESH_TOKEN_KEY,
    SAML_ENABLED
  ];
  items.map(item => (storage.removeItem(item)));
};

const getTokenValueForKey = (key) => {
  try {
    const token = getToken();
    if (token) {
      const decoded = jwtDecode(token);
      return decoded[key];
    }
  } catch (err) {
    console.error(err);
  }
  return undefined
}

export const isAdminUser = () => {
  try {
    return getTokenValueForKey(ACCOUNT_ROLE) === "admin";
  } catch (err) {
    console.error(err);
  }
  return false;
}

export const getExpireAt = () => {
  try {
    return getTokenValueForKey(TOKEN_EXPIRY_KEY) * 1000 || 0;
  } catch (err) {
    console.error(err);
  }
  return 0;
};

export const getUserInfo = () => {
  const token = getToken();
  if (token) {
    const decoded = jwtDecode(token);
    return {
      name: decoded[ACCOUNT_NAME],
      email: decoded[ACCOUNT_EMAIL],
      [ACCOUNT_ROLE]: decoded[ACCOUNT_ROLE]
    }
  }
}
