import React from "react";
import { Card, Col, Row, Space, Descriptions, } from "antd";
import "./AccountDetails.scss";
import * as _ from "lodash";
import FontAwesome from "react-fontawesome";


class AccountDetailsSummary extends React.Component{

    render() {
        
        const { accountDetails, accountSummaryCspAccount, accountSummaryArray, accountSummaryLoading, acctColumns, downloadSummary}=this.props;
        
        return (
        <Row gutter={16}>
            <Col span={12}>
                <Card title="Account Details" style={{ height: "100%" }}>
                    <Descriptions bordered labelStyle={{ fontWeight: "600" }} column={1} size="small">
                        {acctColumns.map(({ label, value }) => (
                            <Descriptions.Item label={label} key={value}>
                                {typeof value === "function"
                                    ? value(accountDetails)
                                    : accountDetails[value]}
                            </Descriptions.Item>
                        ))}
                    </Descriptions>
                </Card>
            </Col>
            <Col span={12} style={{ height: "100%" }}>
                <Card title="Account Summary" extra={(
                    <div
                        role="presentation"
                        onClick={() => downloadSummary([
                            { title: "CSP Accounts" },
                            ...accountSummaryCspAccount,
                            { title: "Usages" },
                            ...accountSummaryArray
                        ])}
                        className="csv-download-button"
                    >
                        <FontAwesome
                            className="download"
                            name="download"
                        />
                    </div>
                )} loading={accountSummaryLoading}>
                    <Space direction="vertical" style={{ width: "100%" }} size={16}>
                        <Descriptions bordered labelStyle={{ fontWeight: "600" }} size="small" title="CSP Accounts">
                            {_.map(accountSummaryCspAccount, acc => (
                                <Descriptions.Item label={_.get(acc, "label")} key={_.get(acc, "value")}>{_.get(acc, "value")}</Descriptions.Item>
                            ))}
                        </Descriptions>
                        <Descriptions bordered labelStyle={{ fontWeight: "600" }} column={1} size="small" title="Usages">
                            {_.map(accountSummaryArray, acc => (
                                <Descriptions.Item label={_.get(acc, "label")} key={_.get(acc, "value")}>{_.get(acc, "value")}</Descriptions.Item>
                            ))}
                        </Descriptions>
                    </Space>
                </Card>
            </Col>
        </Row>
        );
                  
    }
}

export default AccountDetailsSummary;