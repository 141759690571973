import React, { Component } from 'react';
import { PostRequestResolve } from "../../utils/FetchHelper";
import URL from "../../utils/url";
import { Card, Col, Row, Space, Statistic } from 'antd';
import _ from 'lodash';
import { redirectTo } from '../../utils/history';
import { Typography } from 'antd';

const { Title } = Typography;


class Overview extends Component {
    abortController = new window.AbortController();

    state = {
        accountSummary: {},
        accountSummaryLoading: true,
        unverifiedUsersSummary: {},
        unverifiedUsersSummaryLoading: true,
        gatewaySummary: {},
        gatewaySummaryLoading: true,
        imageRegistrySummary: {},
        imageRegistrySummaryLoading: true,
        activeUsersSummary: {},
        activeUsersSummaryLoading: true,
    };

    componentDidMount() {
        const { selectedDashboard, appSettings = {} } = this.props;
        const { isGlobalDashboard = false } = appSettings;
        if (!isGlobalDashboard || (isGlobalDashboard && selectedDashboard)) {
            this.fetchData();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedDashboard !== this.props.selectedDashboard) {
            this.fetchData();
        }
    }

    componentWillUnmount() {
        if (this.controller) this.controller.abort();
    }

    async fetchData() {
        if (this.abortController) {
            this.abortController.abort()
            this.abortController = new window.AbortController();
        }
        this.getAccountSummary();
        this.getGatewaySummary();
        this.getImageRegistrySummary();
        this.getUnverifiedUsersSummary();
        this.getActiveUsersSummary();
    }

    getAccountSummary = () => {
        PostRequestResolve(URL.accountsSummary, this.abortController.signal, {})
            .then((resp) => {
                this.setState({
                    accountSummary: resp,
                    accountSummaryLoading: false,
                });
            })
            .catch((err) => {
                this.setState({
                    accountSummaryLoading: false,
                });
            });
    }

    getUnverifiedUsersSummary = (headers) => {
        PostRequestResolve(URL.unverifiedUsersSummary, this.abortController.signal, {}, headers)
            .then((resp) => {
                this.setState({
                    unverifiedUsersSummary: resp,
                    unverifiedUsersSummaryLoading: false,
                });
            })
            .catch((err) => {
                this.setState({
                    unverifiedUsersSummaryLoading: false,
                });
            });
    }

    getActiveUsersSummary = (headers) => {
        PostRequestResolve(URL.activeUsersSummary, this.abortController.signal, {}, headers)
            .then((resp) => {
                this.setState({
                    activeUsersSummary: resp,
                    activeUsersSummaryLoading: false,
                });
            })
            .catch((err) => {
                this.setState({
                    activeUsersSummaryLoading: false,
                });
            });
    }

    getGatewaySummary = (headers) => {
        PostRequestResolve(URL.gatewaySummary, this.abortController.signal, {}, headers)
            .then((resp) => {
                this.setState({
                    gatewaySummary: resp,
                    gatewaySummaryLoading: false,
                });
            })
            .catch((err) => {
                this.setState({
                    gatewaySummaryLoading: false,
                });
            });
    }

    getImageRegistrySummary = (headers) => {
        PostRequestResolve(URL.imageRegistrySummary, this.abortController.signal, {}, headers)
            .then((resp) => {
                this.setState({
                    imageRegistrySummary: resp,
                    imageRegistrySummaryLoading: false,
                });
            })
            .catch((err) => {
                this.setState({
                    imageRegistrySummaryLoading: false,
                });
            });
    }

    onSelectDashboard = (record) => {
        const id = _.get(record, "id");
        const ping = _.get(record, "details.deploymentInfo.name");
        if (id && ping) {
            const { onChangeDashboard } = this.props;
            onChangeDashboard && onChangeDashboard(id)
        }
    }

    render() {
        const {
            accountSummary, accountSummaryLoading,
            unverifiedUsersSummary, unverifiedUsersSummaryLoading,
            gatewaySummary, gatewaySummaryLoading,
            imageRegistrySummary, imageRegistrySummaryLoading,
            activeUsersSummary, activeUsersSummaryLoading,
        } = this.state;
        return (
            <>
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Title level={5} style={{ marginTop: 0 }}>Common Database</Title>
                    <Row gutter={8}>
                        <Col span={6}>
                            <Card size="small" style={{ cursor: "pointer" }} title="Accounts" onClick={() => redirectTo("/admin/accounts")}>
                                <Row gutter={8}>
                                    <Col span={8}>
                                        <Statistic title="Total" value={_.get(accountSummary, "total", " ")} loading={accountSummaryLoading} />
                                    </Col>
                                    <Col span={8}>
                                        <Statistic title="Today" value={_.get(accountSummary, "today", " ")} loading={accountSummaryLoading} />
                                    </Col>
                                    <Col span={8}>
                                        <Statistic title="Last Week" value={_.get(accountSummary, "lastWeek", " ")} loading={accountSummaryLoading} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card size="small" style={{ cursor: "pointer" }} title="Images" onClick={() => redirectTo("/admin/imageregistry/list")}>
                                <Row gutter={8}>
                                    <Col span={4}>
                                        <Statistic title="Total" value={_.get(imageRegistrySummary, "total", " ")} loading={imageRegistrySummaryLoading} />
                                    </Col>
                                    <Col span={10}>
                                        <Statistic title="Latest" valueRender={() => (
                                            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }} title={_.get(imageRegistrySummary, "latestImage", " ")}>
                                                {_.get(imageRegistrySummary, "latestImage", " ")}
                                            </div>
                                        )}
                                            loading={imageRegistrySummaryLoading} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Title level={5}>Auth0</Title>
                    <Row gutter={8}>
                        <Col span={6}>
                            <Card size="small" style={{ cursor: "pointer" }} title="Unverified Users" onClick={() => redirectTo("/admin/unverifiedUsers")}>
                                <Row gutter={8}>
                                    <Col span={8}>
                                        <Statistic title="Total" value={_.get(unverifiedUsersSummary, "total", " ")} loading={unverifiedUsersSummaryLoading} />
                                    </Col>
                                    <Col span={8}>
                                        <Statistic title="Today" value={_.get(unverifiedUsersSummary, "today", " ")} loading={unverifiedUsersSummaryLoading} />
                                    </Col>
                                    <Col span={8}>
                                        <Statistic title="Last Week" value={_.get(unverifiedUsersSummary, "lastWeek", " ")} loading={unverifiedUsersSummaryLoading} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card size="small" style={{ cursor: "default" }} title="Active Users">
                                <Row gutter={8}>
                                    <Col span={24}>
                                        <Statistic title="Last 30 Days" value={_.get(activeUsersSummary, "last30days", " ")} loading={activeUsersSummaryLoading} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Title level={5}>Deployment</Title>
                    <Row gutter={8}>
                        <Col span={4}>
                            <Card size="small" style={{ cursor: "default" }} title="Gateways">
                                <Row gutter={8}>
                                    <Col span={24}>
                                        <Statistic title="Total" value={_.get(gatewaySummary, "total", " ")} loading={gatewaySummaryLoading} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Space>
            </>
        );
    }
}

export default Overview;