import { Card, Statistic } from 'antd';
import _ from 'lodash';
import { Component } from 'react';
import { PostRequestResolve } from "../../utils/FetchHelper";
import URL from "../../utils/url";
import withContext from '../../hoc/withContext';
import DashboardContext from '../../common/ContextWrappers/Dashboard/Context';


class AppDetails extends Component {
  controller = new window.AbortController();

  state = {
    appDetailsLoading: false,
    appDetails: {},
  };

  componentDidMount() {
    if (process.env.NODE_ENV !== 'development') {
      this.getAppDetails();
    }
  }

  componentWillUnmount() {
    if (this.controller) this.controller.abort();
  }

  getAppDetails = () => {
    this.setState({
      appDetailsLoading: true,
    });
    PostRequestResolve(URL.getAppDetails, this.controller.signal, {})
      .then((resp) => {
        this.setState({
          appDetails: resp,
          appDetailsLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          appDetailsLoading: false,
        });
      });
  }

  render() {
    const {
      appDetails, appDetailsLoading
    } = this.state;
    const { isGlobalDashboard } = this.props;
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {isGlobalDashboard && <Card style={{ cursor: "default " }}>
          <Statistic title="Dashboard Type" value={"Global Admin Dashboard"} loading={appDetailsLoading} />
        </Card>}
        <Card style={{ cursor: "default" }}>
          <Statistic title="UI Version" value={_.get(appDetails, "uiVersion", " ")} loading={appDetailsLoading} />
          <Statistic title="Controller" value={_.get(appDetails, "deploymentInfo.softwareVersion", " ")} loading={appDetailsLoading} />
        </Card>
      </div>
    );
  }
}

export default withContext(AppDetails, DashboardContext);