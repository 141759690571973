import React, { Component } from 'react';
import { columns } from "./columns";
import _ from 'lodash';
import ValtixTable from '../../common/Table/Table';
import withContext from '../../hoc/withContext';
import GlobalDashboardContext from '../../common/ContextWrappers/Dashboard/Context';


class Dashboard extends Component {
    onSelectDashboard = (record) => {
        const id = _.get(record, "id");
        const ping = _.get(record, "details.deploymentInfo.name");
        if (id && ping) {
            const { onChangeDashboard } = this.props;
            onChangeDashboard && onChangeDashboard(id)
        }
    }

    render() {
        const { isGlobalDashboard, dashboardList = [] } = this.props;
        return (
            <>
                {isGlobalDashboard ? (
                    <ValtixTable
                        name="dashboards"
                        data={dashboardList}
                        columns={columns}
                        onRowClick={this.onSelectDashboard}
                    />
                ) : <>This feature is Not Supported</>}
            </>
        );
    }
}

export default withContext(Dashboard, GlobalDashboardContext);