
import { Component, Fragment } from 'react';
import withContext from '../../../hoc/withContext';
import { Modal, Result } from "antd";
import DeploymentContext from 'common/ContextWrappers/Deployment/Context';

class DeploymentModal extends Component {
    static contextType = DeploymentContext;
    render() {
        const { children } = this.props;
        const { showServerDevelopmentSelectModal, closeServerDeploymentSelectModal } = this.context
        return (
            <Fragment>
                {children}
                <Modal
                    title="Please Select Deployment"
                    open={showServerDevelopmentSelectModal}
                    onCancel={() => closeServerDeploymentSelectModal()}
                    footer={[]}
                >
                    <Result status="warning" >
                        Please select server deployment to proceed
                    </Result>
                </Modal>
            </Fragment>
        );
    }
}

export default withContext(DeploymentModal, DeploymentContext)