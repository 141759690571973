import React, { Component, Fragment } from 'react';
import ValtixTable from "../../common/Table/Table";
import { getColums } from "./Columns";
import SearchBar from "../../common/SearchBar/Search";
import { PostRequestResolve } from "utils/FetchHelper";
import URL from './../../utils/url';
import { filterList } from "variables/general";
import CustomButton from "../../common/CustomButton/Button";
import { Message } from "../../variables/general";
import { updateURLParam, getURLParam, deleteURLParam } from "../../utils/Utilities";
import CreateAccount from './CreateAccount';
import { Modal, Row, Space, Switch, Tooltip } from "antd";
import _ from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import GetRequest from '../../utils/FetchHelper';
import withContext from '../../hoc/withContext';
import DeploymentContext from '../../common/ContextWrappers/Deployment/Context';


class ListAccounts extends Component {
    static contextType = DeploymentContext;

    state = {
        accounts: [],
        selectedAccount: "",
        loading: false,
        editingKey: "",
        selectedRecord: "",
        showCreateModal: false,
        showDeleteModal: false,
        recaptchaResponse: null,
        captchaKey: undefined,
    };

    controller = new window.AbortController()

    recaptcharef = React.createRef();

    constructor(props) {
        super(props);
        updateURLParam('dateCreated', "descend");
    }

    componentDidMount() {
        const { onChangeCallback } = this.context;
        onChangeCallback(this.onDeploymentSelect);
        this.getCaptchaKey();
        this.onDeploymentSelect();
    }


    onDeploymentSelect = () => {
        const { selectedDeployment } = this.context;
        if (selectedDeployment) {
            this.getAccounts();
        }
    }

    componentWillUnmount() {
        this.controller.abort();
        const { clearOnChangeCallback } = this.context;
        clearOnChangeCallback();
    }

    toggleLoading = (callback) => {
        this.setState((prevState) => ({
            ...prevState,
            loading: !prevState.loading,
        }), () => {
            typeof callback === "function" && callback();
        });
    }

    getCaptchaKey = () => {
        GetRequest(URL.getCaptchaKey, this.controller.signal)
            .then((resp) => {
                this.setState(prevState => ({
                    ...prevState,
                    captchaKey: _.get(resp, "siteKey", ""),
                }));
            }).catch((err) => {
                Message.error("Getting Captcha Key", err);
            })
    }

    getAccounts = () => {
        this.setState({
            loading: true,
            accounts: [],
            filteredData: [],
        });
        const { selectedDeployment } = this.context;
        const { filters } = this.state;
        const payload = {
            serverDeployment: selectedDeployment,
            filters
        };
        PostRequestResolve(URL.listAccounts, this.controller.signal, payload).then((resp) => {
            const accounts = _.values(_.get(resp, "accounts", {}));
            this.setState({
                loading: false,
                accounts: accounts,
                filteredData: filterList(this.cdoTenantFilter(accounts), getURLParam("search")),
            });
        }).catch((err) => {
            this.setState({
                loading: false,
            });
            Message.error("List Accounts", err);
        });

    }

    deleteAccount = (callback, errorCallback) => {
        const { selectedRecord, recaptchaResponse } = this.state;
        const payload = {
            name: _.get(selectedRecord, "Name", ""),
            recaptchaResponse,
        };
        if (!payload.name) {
            typeof errorCallback === "function" && errorCallback();
        }
        this.toggleLoading(() => {
            PostRequestResolve(URL.deleteAccount, this.controller.signal, payload).then((resp) => {
                Message.success("Delete Account", "Successfully deleted " + payload.name);
                this.toggleLoading(callback);
            }).catch((err) => {
                this.toggleLoading(errorCallback);
                Message.error("Delete Account", err);
            });
        });

    }

    cdoTenantFilter = (accounts = []) => {
        const cdoTenant = getURLParam("cdoTenant");
        if (cdoTenant) {
            return _.filter(accounts, a => _.includes(_.toLower(_.get(a, "Name",)), "cisco"))
        }
        return _.filter(accounts, a => !_.includes(_.toLower(_.get(a, "Name",)), "cisco"))
    }

    oncdoTenantToggle = (value) => {
        if (value) {
            updateURLParam("cdoTenant", value);
        } else {
            deleteURLParam("cdoTenant");
        }
        this.onSearchTable();
    }

    onSearchTable = (value, callback) => {
        const { accounts } = this.state;
        this.setState(({
            filteredData: filterList(this.cdoTenantFilter(accounts), value),
            selectedRecord: null,
        }), () => {
            if (value) updateURLParam("search", value);
            else {
                deleteURLParam("search");
            }
            callback && callback();
        });
    }

    onFilter = (name, value, callback) => {
        this.setState((prevState => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                [name]: value,
            },
        })), () => {
            if (value) updateURLParam(name, value);
            else {
                deleteURLParam(name);
            }
            callback && callback();
            this.getAccounts();
        });
    }

    selectRecord = (record) => {
        this.setState(prevState => ({
            ...prevState,
            selectedRecord: record
        }));
    }

    handleCaptchaChange = (value) => {
        this.setState(prevState => ({
            ...prevState,
            recaptchaResponse: value,
        }));
    }


    openCreateModal = () => {
        this.setState((prevState) => ({
            ...prevState,
            showCreateModal: true,
        }));
    }

    openDeleteModal = () => {
        this.setState((prevState) => ({
            ...prevState,
            showDeleteModal: true,
        }));
    }

    closeCreateModal = (reload) => {
        this.setState((prevState) => ({
            ...prevState,
            showCreateModal: false,
        }), () => {
            if (reload) {
                this.getAccounts();
            }
        });
    }

    closeDeleteModal = (confirm = false) => {
        if (confirm) {
            this.deleteAccount(() => {
                this.setState((prevState) => ({
                    ...prevState,
                    selectedRecord: null,
                    showDeleteModal: false,
                    recaptchaResponse: null,
                }), this.getAccounts);
                this.recaptcharef && this.recaptcharef.current && this.recaptcharef.current.reset();
            })
        } else {
            this.setState((prevState) => ({
                ...prevState,
                showDeleteModal: false,
                recaptchaResponse: null,
            }));
            this.recaptcharef && this.recaptcharef.current && this.recaptcharef.current.reset();
        }
    }

    render() {
        const {
            loading, showCreateModal, selectedRecord, showDeleteModal,
            recaptchaResponse, captchaKey, filteredData,
        } = this.state;

        return (
            <Fragment>
                <Row style={{ marginBottom: "8px" }}>
                    <SearchBar
                        placeholder="Search in Table"
                        allowClear
                        width={400}
                        defaultValue={getURLParam("search") || ""}
                        onSearch={this.onSearchTable}
                    />
                    <SearchBar
                        placeholder="Filter Email"
                        allowClear
                        width={400}
                        defaultValue={getURLParam("email") || ""}
                        onSearch={(value, callback) => this.onFilter("email", value, callback)}
                    />
                    <Tooltip
                        title="Filter accounts containing 'cisco' in their name"
                    >
                        <Switch
                            style={{ margin: "auto 8px" }}
                            onChange={this.oncdoTenantToggle}
                            checkedChildren="Cisco Tenants"
                            unCheckedChildren="Valtix Tenants"
                            defaultChecked={getURLParam("cdoTenant") || false}
                            loading={loading} />
                    </Tooltip>
                </Row>
                <Row>
                    <Space>
                        <CustomButton
                            type="primary"
                            onClick={this.openCreateModal}
                            enableOnlyForAdmin
                        >Create Account
                        </CustomButton>
                        <CustomButton
                            type="primary"
                            danger
                            onClick={this.openDeleteModal}
                            disabled={!selectedRecord}
                            enableOnlyForAdmin
                        >
                            Delete Account
                        </CustomButton>
                    </Space>
                </Row>
                <ValtixTable
                    name="listAccounts"
                    loading={loading}
                    data={filteredData}
                    columns={getColums(!!getURLParam("email"))}
                    onRowSelect={this.selectRecord}
                    enableSingleRowSelection
                />
                {showCreateModal && (<CreateAccount onClose={this.closeCreateModal} />)
                }
                <Modal
                    title="Delete Account"
                    visible={showDeleteModal}
                    okText="Delete"
                    okButtonProps={{
                        danger: true,
                        disabled: !recaptchaResponse,
                    }}
                    cancelButtonProps={{
                        disabled: loading,
                    }}
                    onOk={() => this.closeDeleteModal(true)}
                    onCancel={() => this.closeDeleteModal(false)}
                    confirmLoading={loading}
                >
                    Are you sure. Do you want to delete <b>{_.get(selectedRecord, "Name", "")}</b>?
                    {(captchaKey) && (
                        <ReCAPTCHA
                            ref={this.recaptcharef}
                            sitekey={captchaKey}
                            onChange={this.handleCaptchaChange}
                        />
                    )}
                </Modal>
            </Fragment>
        );
    }
}

export default withContext(ListAccounts, DeploymentContext);
