import React, { Component, Fragment } from "react";
import { Row, Space } from "antd";
import _ from "lodash";
import URL from "../../../utils/url";
import ValtixTable from "../../../common/Table/Table";
import { columns } from "./Columns";
import { Message } from "../../../variables/general";
import { PostRequest } from "../../../utils/FetchHelper";
import CustomButton from "../../../common/CustomButton/Button";
import { getURLParam, replaceURLParam, deleteURLParam } from "utils/Utilities";
import { filterList } from "variables/general";
import SearchBar from "common/SearchBar/Search";
import TerminateInstance from "./TeminateInstanceModal";
import ModifySettings from "./ModifySettingsModal";
import AddInstanceModal from "./AddInstanceModal";

class ListGateways extends Component {
  controller = new window.AbortController();

  state = {
    gateways: [],
    filteredGateways: [],
    loadingGateways: false,
    showTerminateInstanceModal: false,
    showModifySettingsModal: false,
  };

  componentDidMount() {
    this.getGatewayList(() => {
      this.setState((prevState) => ({
        ...prevState,
        filteredGateways: filterList(prevState.gateways, getURLParam("query")),
      }));
    });
  }

  toggleLoading = (callback) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        loadingGateways: !prevState.loadingGateways,
      }),
      () => {
        typeof callback === "function" && callback();
      }
    );
  };

  addCustomKey = (list) => {
    list.forEach((d) => {
      let value = _.get(d, "Gateway.Name", "");
      d.ID = value;
    });
  };

  getGatewayList = (callback) => {
    const { acctName = "" } = this.props;
    const payload = {
      acctName: acctName,
    };
    this.toggleLoading(() => {
      PostRequest(
        URL.listGateways,
        this.controller.signal,
        payload,
        (resp) => {
          const gateways = resp;
          this.addCustomKey(gateways);
          this.setState((prevState) => ({
            ...prevState,
            gateways: gateways,
            filteredGateways: gateways,
          }));
          this.toggleLoading(callback);
        },
        (err) => {
          this.toggleLoading();
          Message.error("List Gateways", err);
        }
      );
    });
  };

  /**
   *
   * @param {String} gatewayName Gateway Name
   */
  triggerInbandDiagnostics = () => {
    const { selectedGateway } = this.state;
    const { acctName = "" } = this.props;
    const gatewayName = _.get(selectedGateway, "Gateway.Name");
    if (!gatewayName || !acctName) {
      return;
    }
    const payload = {
      gatewayName: gatewayName,
      acctName: acctName,
    };
    PostRequest(
      URL.triggerInbandDiagnostics,
      this.controller.signal,
      payload,
      () => {
        Message.success("Trigger Inband Diagnostics", "Trigger Successfully");
      },
      (err) => {
        Message.error("Trigger Inband Diagnostics", err);
      }
    );
  };

  componentWillUnmount() {
    this.controller.abort();
  }

  selectGateway = (gateway) => {
    this.setState({
      selectedGateway: gateway,
    });
  };

  handleTerminateInstanceModel = (showTerminateInstanceModal = false) => {
    this.setState({
      showTerminateInstanceModal,
    });
  };

  addInstance = () =>{
    this.setState({ showAddInstanceModal: true });
  }

  closeAddInstanceModal = () =>{
    this.setState({ showAddInstanceModal: false });
  } 
  
  modifySettings = () =>{
    this.setState({ showModifySettingsModal: true });
  }

  closeModifySettingsModal = () =>{
    this.setState({ showModifySettingsModal: false });
  }

  addData = (az) => {
    const { selectedGateway } = this.state;
    this.setState({ loadingInstances: true });
    const { acctName = "" } = this.props;
    const gatewayName = _.get(selectedGateway, "Gateway.Name");
    if (!gatewayName || !acctName) {
      return;
    }
    const payload = {
      common: {
        acctName,
      },
      gatewayName: gatewayName,
      availabilityZone: az.trim(),
    };
    PostRequest(
      URL.addInstances,
      this.controller.signal,
      payload,
      (resp) => {
        Message.success(`Add instance`, "Added Successfully");
        this.setState({ showAddInstanceModal: false, loadingInstances: false });
      },
      (err) => {
          Message.error("Add Instance", err.message);
          this.setState({ showAddInstanceModal: false, loadingInstances: false });
        
      }
    );
  }

  refreshGateways = () => {
    this.getGatewayList();
  }

  onSearch = (value, callback) => {
    const { gateways } = this.state;
    this.setState((prevState => ({
        ...prevState,
        filteredGateways: filterList(gateways, value),
    })), () => {
        if (value) replaceURLParam("query", value);
        else {
            deleteURLParam("query");
        }
        callback && callback();
    });
}

  render() {
    const {
      filteredGateways,
      loadingGateways, loadingInstances,
      selectedGateway, showAddInstanceModal,
      showTerminateInstanceModal, showModifySettingsModal,
    } = this.state;
    const { acctName } = this.props;
    const gatewayState = _.get(selectedGateway, "Gateway.State", 8); return (
      <Fragment>
        <Row justify="space-between">
          <SearchBar
            placeholder="Gateways"
            allowClear
            width={400}
            onSearch={this.onSearch}
            defaultValue={getURLParam("query") || ""}
          />
        <Space justify="end">
          <CustomButton
              type="primary"
              onClick={this.modifySettings}
              disabled={!selectedGateway}
            >
              Modify Settings
            </CustomButton>
          <CustomButton
              type="primary"
              onClick={this.addInstance}
              disabled={!selectedGateway || gatewayState !== 2}
              enableOnlyForAdmin
            >
              Add Instance
            </CustomButton>
            <CustomButton
              type="primary"
              onClick={this.triggerInbandDiagnostics}
              disabled={!selectedGateway}
              enableOnlyForAdmin
            >
              Trigger Inband Diagnostics
            </CustomButton>
            <CustomButton
              type="primary"
              onClick={() => this.handleTerminateInstanceModel(true)}
              disabled={!selectedGateway}
              danger
              enableOnlyForAdmin
            >
              Terminate Instance
            </CustomButton>
          </Space>
        </Row>
        {showTerminateInstanceModal && (
          <TerminateInstance
            acctName={acctName}
            selectedGateway={_.get(selectedGateway, "Gateway.Name")}
            onClose={this.handleTerminateInstanceModel}
          />
        )}
        {showModifySettingsModal && (
          <ModifySettings
            acctName={acctName}
            selectedGateway={_.get(selectedGateway, "Gateway.Name")}
            onClose={this.closeModifySettingsModal}
          />
        )}
        {showAddInstanceModal && (
          <AddInstanceModal
          acctName={acctName}
          selectedGateway={selectedGateway}
          onClose={this.closeAddInstanceModal}
          addData={this.addData}
          loadingInstances={loadingInstances}
        />
        )}
        <ValtixTable
          title={() => "Gateways"}
          name="listGateways"
          loading={loadingGateways}
          data={filteredGateways}
          columns={columns()}
          onRowSelect={this.selectGateway}
          enableSingleRowSelection
        />
      </Fragment>
    );
  }
}

export default ListGateways;
