import React from 'react';
import './App.css';
import { Redirect, Route, Switch } from "react-router-dom";
import ContextWrappers from './common/ContextWrappers';
import Navbar from "./layout/Navbar/Navbar";
import SideBar from "./layout/SideBar/SideBar";
import { Layout } from "antd";
import RoutePaths from "./utils/RoutePaths";
import { getMenuItems, getPrivateRoutes } from "./routes";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import * as am4core from "@amcharts/amcharts4/core";
import { checkEnableTokenRefresh, refreshToken } from './utils/authservice';
import DeploymentModal from 'common/ContextWrappers/DeploymentModal';
import DashboardContext from './common/ContextWrappers/Dashboard/Context';
import _ from 'lodash';
am4core.addLicense("CH297319641");

const SwitchRoutes = ({ appSettings }) => (
    <Switch>
        {getPrivateRoutes(appSettings)}
        <Redirect from="/admin" to={_.get(appSettings, "isGlobalDashboard", false) ? RoutePaths.dashboards : RoutePaths.homePage} />
    </Switch>
);


class App extends React.Component {

    componentDidMount() {
        checkEnableTokenRefresh(refreshToken);
    }

    render() {
        return (
            <Layout style={{ height: "100%" }}>
                <ContextWrappers>
                    <DashboardContext.Consumer>
                        {({ isGlobalDashboard }) => {
                            const appSettings = {
                                "isGlobalDashboard": isGlobalDashboard,
                            };
                            return (
                                <>
                                    <Route path="/" component={Navbar} />
                                    <Layout >
                                        <SideBar routes={getMenuItems(appSettings)} />
                                        <Layout.Content style={{ padding: "16px", overflow: "auto" }}>
                                            <ErrorBoundary>
                                                <SwitchRoutes appSettings={appSettings} />
                                            </ErrorBoundary>
                                        </Layout.Content>
                                        <DeploymentModal />
                                    </Layout>
                                </>
                            );
                        }}
                    </DashboardContext.Consumer>
                </ContextWrappers>
            </Layout>
        );
    }
}

export default App;
