import React from "react";
import PropTypes from "prop-types";

const DeploymentContext = React.createContext({
  deploymentsList: [],
  selectedDeployment: "",
  onChangeDeployment: () => {},
  onChangeCallback: () => {},
  clearOnChangeCallback: () => {},
  showServerDevelopment: false,
});

export default DeploymentContext;

export const DeploymentContextPropTypes = {
  deploymentsList: PropTypes.array.isRequired,
  selectedDeployment: PropTypes.string.isRequired,
  onChangeDeployment: PropTypes.func.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
  clearOnChangeCallback: PropTypes.func.isRequired,
  showServerDevelopment: PropTypes.bool.isRequired,
};
