import React, { Component } from 'react';
import { Select, Tooltip } from 'antd';
import withContext from '../../hoc/withContext';
import GlobalDashboardContext from '../ContextWrappers/Dashboard/Context';
import _ from 'lodash';

const { Option } = Select;

class DashboardSelect extends Component {

    render() {
        const { dashboardList = [], isGlobalDashboard, selectedDashboard, loadingDashboardList, onChangeDashboard } = this.props;
        if (!isGlobalDashboard) {
            return <></>
        }
        return (
            <Tooltip
                title={isGlobalDashboard && !selectedDashboard ? "Please select dashboard" : null}
                placement='right' open={isGlobalDashboard && !selectedDashboard}
                color='red'
            >
                <Select
                    showSearch
                    loading={loadingDashboardList}
                    optionFilterProp="children"
                    onChange={onChangeDashboard}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ margin: "auto 10px", width: "200px" }}
                    value={selectedDashboard}
                    size="large"
                    placeholder="Dashboard"
                >
                    {dashboardList.map((d) => <Option key={_.get(d, "name")} value={_.get(d, "id")} disabled={!_.get(d, "details.deploymentInfo.name")}>{_.get(d, "name")}</Option>)}
                </Select>
            </Tooltip>
        )
    }
}

export default withContext(DashboardSelect, GlobalDashboardContext);
