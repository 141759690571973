import React, {Component, Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import RoutePaths from "utils/RoutePaths";
import ListImages from "./ListImages";
import CreateImage from "./CreateImage";
import ImageDetails from "./ImageDetails";

class ImageRegistry extends Component {
    render() {
        return (
            <Fragment>
                <Route
                    exact
                    path={RoutePaths.createImageRegistry}
                    component={CreateImage}
                />
                <Route
                    exact
                    path={RoutePaths.getImageRegistry + "/:imageName"}
                    component={ImageDetails}
                />
                <Route
                    exact
                    path={RoutePaths.listImageRegistry}
                    component={ListImages}
                />
                <Route
                    exact
                    path={RoutePaths.defaultImageRegistry}
                    component={() => <Redirect to={RoutePaths.listImageRegistry}/>}
                />
            </Fragment>
        );
    }
}

export default ImageRegistry;