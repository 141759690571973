import fetch from 'fetch-retry';
import {isLoggedIn, getToken} from './authservice';
import { getDashboardHeaderForAPI } from './globalDashboardUtils';

const getHeaders = (headers) => {
  if (!headers) {
    headers = getDashboardHeaderForAPI();
  }
  if (isLoggedIn()) {
    headers.Authorization = `Bearer ${getToken()}`;
  }
  return headers;
};

const GetRequest = (url, signal) => {
  return fetch(url, {
    method: 'GET',
    headers: getHeaders(),
    signal: signal,
  }).then(response => response.json()
    .then(json => (response.ok ? Promise.resolve(json) : Promise.reject(json))))
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export default GetRequest;

export const PostRequest = (url, signal, payload,
                            successCallback, errorCallback, forceAllowPost = false) => {
  if (!isLoggedIn() && !forceAllowPost) {
    return;
  }
  return fetch(url, { // eslint-disable-line
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(payload),
    retryOn: [503],
    retries: 2,
    signal: signal,
  }).then(response => response.json()
    .then(json => (response.ok ? json : Promise.reject(json)))).then((data) => {
    successCallback(data);
  }).catch((err) => {
    if (err.name === 'AbortError') {
      return;
    }
    console.log(err);
    errorCallback(err);
  });
};

export const PostRequestResolve = (url, signal, payload, headers) => {
  if (!isLoggedIn()) {
    return;
  }
  return fetch(url, { // eslint-disable-line
    method: 'POST',
    headers: getHeaders(headers),
    body: JSON.stringify(payload),
    retryOn: [503],
    retries: 2,
    signal: signal,
  }).then(response => response.json()
    .then(json => (response.ok ? Promise.resolve(json) : Promise.reject(json))))
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const MultiplePostRequests = (listOfRequests, successCallback, errorCallback, signal) => {
  if (!isLoggedIn()) {
    return;
  }
  const promises = [];
  // eslint-disable-next-line array-callback-return
  listOfRequests.map((request) => {
    const promise = fetch(request.url, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(request.payload),
      signal: signal,
      retryOn: [503],
      retries: 2,
    }).then(response => response.json().then(json => (response.ok ? json : Promise.reject(json))));
    promises.push(promise);
  });

  Promise.all(promises).then((data) => {
    successCallback(data);
  }).catch((err) => {
    if (err.name === 'AbortError') {
      return;
    }
    console.log(err);
    errorCallback(err);
  });
};
