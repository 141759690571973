
import Overview from "./modules/Overview/index.js";
import Dashboard from "./modules/Dashboards/index.js";
import Accounts from "./modules/Accounts";
import ImageRegistry from "./modules/ImageRegistry";
import VendorRuleset from "./modules/VendorRuleset";
import UnverifiedUsers from "./modules/UnverifiedUsers";
import Console from "./modules/SqlConsole/Console/index.js";
import AuditLogs from "./modules/AuditLogs/index.js";
import AppDetails from "./modules/AppDetails/index.js";
import UserQuarantine from "./modules/UserQuarantine/index.js";
import ServerHealth from "./modules/ServerHealth";
import Deployments from "./modules/Deployments/index.js";
import RoutePaths from "./utils/RoutePaths.js";
import { AppstoreOutlined, BookOutlined, DashboardOutlined, FileImageOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import PrivateRoute from "./common/PrivateRoute/index.js";

const allRoutes = [
  {
    label: "Dashboards",
    icon: <DashboardOutlined />,
    component: Dashboard,
    key: RoutePaths.dashboards,
    isEnabled: (settings) => _.get(settings, "isGlobalDashboard", false) === true
  },
  {
    label: "Overview",
    icon: <DashboardOutlined />,
    component: Overview,
    key: RoutePaths.homePage,
  },
  {
    label: "Deployment",
    icon: <AppstoreOutlined />,
    component: Deployments,
    key: RoutePaths.deployments,
  },
  {
    label: "Accounts",
    icon: <FileImageOutlined />,
    component: Accounts,
    key: RoutePaths.defaultAccounts
  },
  {
    label: "Image Registry",
    icon: <FileImageOutlined />,
    component: ImageRegistry,
    key: RoutePaths.defaultImageRegistry
  },
  {
    label: "Vendor RuleSet",
    icon: <BookOutlined />,
    component: VendorRuleset,
    key: RoutePaths.defaultVendorRuleset
  },
  {
    isEnabled: (settings) => _.get(settings, "isCDODeployment", false) === false,
    label: 'Auth',
    type: 'group',
    children: [
      {
        label: "Unverified Users",
        icon: <AppstoreOutlined />,
        component: UnverifiedUsers,
        key: RoutePaths.unverifiedUsers,
      },
      {
        label: "User Quarantine",
        icon: <AppstoreOutlined />,
        component: UserQuarantine,
        key: RoutePaths.userQuarantine,
      },
    ]
  },
  {
    label: "Tools",
    type: "group",
    children: [
      {
        label: "SQL Console",
        icon: <BookOutlined />,
        component: Console,
        key: RoutePaths.sqlConsole
      },
      {
        label: "Server Health",
        icon: <BookOutlined />,
        component: ServerHealth,
        key: RoutePaths.serverHealth
      },
      {
        label: "Audit Logs",
        icon: <BookOutlined />,
        component: AuditLogs,
        key: RoutePaths.auditLogs
      },
    ]
  },
  {
    label: "About",
    icon: <QuestionCircleOutlined />,
    component: AppDetails,
    key: RoutePaths.about
  },
];

const routeFilterFunc = (route, settings = {}) => route.isEnabled === undefined || (route.isEnabled && typeof route.isEnabled == "function" && route.isEnabled(settings))

export const getPrivateRoutes = (settings) => {
  const privateRoutes = []
  _.forEach(allRoutes, (route) => {
    if (routeFilterFunc(route, settings)) {
      if (_.get(route, "type") === "group") {
        _.forEach(_.get(route, "children", []), c_route => {
          privateRoutes.push(<PrivateRoute
            path={c_route.key}
            component={c_route.component}
            key={route.key} />)
        })
      } else {
        privateRoutes.push(<PrivateRoute
          path={route.key}
          component={route.component}
          key={route.key} />)
      }
    }
  })
  return privateRoutes;
}

export const findSelectedMenuItem = (path) => {
  const selected = [];
  _.forEach(allRoutes, (route) => {
    if (_.get(route, "type") === "group") {
      _.forEach(_.get(route, "children", []), c_route => {
        if (path.includes(c_route.key)) {
          selected.push(c_route.key)
          return selected;
        }
      })
    } else if (path.includes(route.key)) {
      selected.push(route.key)
      return selected;
    }
  })
  return selected;
}

export const getMenuItems = (settings) => {
  const menuItems = []
  _.forEach(allRoutes, (route) => {
    if (routeFilterFunc(route, settings)) {
      const { isEnabled, ...rest } = route;
      menuItems.push(rest)
    }
  })
  return menuItems;
}


export default allRoutes;

