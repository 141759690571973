import React, { Component } from 'react';
import {isLoggedIn} from "../../utils/authservice";
import {Redirect} from "react-router-dom";
import RoutePaths from "../../utils/RoutePaths";

class Callback extends Component {
  state = {
    loggedIn: false,
  }

  interval = null

  componentDidMount() {
    this.interval = setInterval(this.checkIfLoggedIn, 1000);
  }

  checkIfLoggedIn = () => {
    const loggedIn = isLoggedIn();
    if (loggedIn) {
      this.setState({
        loggedIn,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const style = {
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      alignItems: 'center',
      backgroundColor: 'white',
    };

    const { loggedIn } = this.state;

    return loggedIn ? <Redirect to={RoutePaths.homePage}/> : (
      <h1 style={style}>
        Loading...
      </h1>
    );
  }
}

export default Callback;
