import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import { PostRequest } from '../../../utils/FetchHelper';
import URL from '../../../utils/url';
import DashboardContext from './Context';
import { Spin } from 'antd';
import { GetSelectedDashboard, SetIsGlobalDashboard, SetSelectedDashboard } from '../../../utils/globalDashboardUtils';

class DashboardContextWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dashboardList: [],
            isGlobalDashboardLoading: true, // first time to check if global dashboard
            selectedDashboard: undefined,
            onChangeDashboard: this.onChangeDashboard,
            isGlobalDashboard: false,
            loadingDashboardList: false,
        };
    }


    controller = new window.AbortController();

    getAdminDashboards = () => {
        this.setState({
            loadingDashboardList: true,
        });
        PostRequest(URL.listDashboards, this.controller.signal, null, (resp) => {
            // Check if response is array or not
            if (_.isArray(resp)) {
                // Check if selected dashboard is in dashboard list
                let selectedDashboard = GetSelectedDashboard();
                if (selectedDashboard) {
                    if (_.findIndex(resp, r => _.get(r, "id") === selectedDashboard) === -1) {
                        selectedDashboard = "";
                        SetSelectedDashboard("");
                    }
                }
                this.setState({
                    loadingDashboardList: false,
                    dashboardList: resp,
                    isGlobalDashboard: true,
                    isGlobalDashboardLoading: false,
                    selectedDashboard: selectedDashboard,
                })
                SetIsGlobalDashboard(true)
            } else {
                console.error("response is not a array");
                this.setState({
                    loadingDashboardList: false,
                    isGlobalDashboard: false,
                    isGlobalDashboardLoading: false,
                })
            }
        }, (err) => {
            console.error(err);
            this.setState({
                loadingDashboardList: false,
                isGlobalDashboard: false,
                isGlobalDashboardLoading: false,
            })
        })
    };

    onChangeDashboard = (value) => {
        SetSelectedDashboard(value);
        this.setState({
            selectedDashboard: value,
        }, () => {
            window.location.reload(false);
        });
    }

    componentDidMount() {
        this.getAdminDashboards()
    }

    componentWillUnmount() {
        this.controller.abort();
    }

    static propTypes = {
        children: PropTypes.node,
    }

    static defaultProps = {
        children: <Fragment />,
    };

    render() {
        const { children } = this.props;
        const { isGlobalDashboardLoading } = this.state;
        return (
            <DashboardContext.Provider value={this.state}>
                <Fragment key={"dashboardContext"}>
                    {isGlobalDashboardLoading ? <div style={{ display: "flex", height: "100%" }}><Spin style={{ margin: "auto" }} /></div> : children}
                </Fragment>
            </DashboardContext.Provider>
        );
    }
}

export default DashboardContextWrapper;