import React, {Component} from 'react';
import PropTypes from "prop-types";
import Search from "antd/lib/input/Search";

class SearchBar extends Component {
    state = {
        isLoading: false,
        disallowClear: false,
    }

    onSearch = (value) => {
        const { onSearch } = this.props;
        this.toggleLoading(() => {
            onSearch(value, () => {
                this.toggleLoading();
            })
        })
    }

    toggleLoading = (callback) => {
        this.setState((prevState) => ({
            ...prevState,
            isLoading: !prevState.isLoading,
            disabled: !prevState.disabled,
        }), () => {
            callback && callback();
        });
    }

    render() {
        const { placeholder, allowClear, width, title, defaultValue } = this.props;
        const { loading, disabled } = this.state;
        return (
            <Search
                className="custom-search-bar"
                placeholder={placeholder}
                allowClear={allowClear}
                addonBefore={title}
                loading={loading}
                defaultValue={defaultValue}
                disabled={disabled}
                onSearch={this.onSearch}
                enterButton
                style={{ width: width }}
            />
        );
    }
}

SearchBar.propTypes = {
    titile: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    width: PropTypes.number,
    allowClear: PropTypes.bool,
    disabled: PropTypes.bool,
    onSearch: PropTypes.func.isRequired,
    defaultValue: PropTypes.string,
}

SearchBar.defaultProps = {
    width: 200,
    allowClear: true,
    disabled: false,
    defaultValue: ""
}

export default SearchBar;