import React, {Component} from 'react';
import { Card, Form, Select } from "antd";
import CustomButton from '../../../common/CustomButton/Button';
import PropTypes from "prop-types";
import _ from 'lodash';
import { Option } from 'antd/lib/mentions';


class AddInstanceModal extends Component {

    state = {
        formData: {
            az: "",
        },
        validations: [],
        showModal: false,
        loading: true,
        modal: { status: "", message: "" },
    };


    tableRef = React.createRef();

    onFormFieldChange = (e) => {
        this.setState(prevState => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                az: e,
            }
        }));
        if(e && e !== ""){
            this.setState({ loading: false });
        }
    };

    submitData = () => {
        const { addData } =this.props;
        const { formData } =this.state;
        if (formData.az && formData.ax !== ""){
            addData && addData(formData.az);
        }
    }

    render() {
        const { onClose, selectedGateway, loadingInstances } =this.props;
        const { loading } =this.state;
        return (
            <div>
                <Card
                    className="create-modal setting"
                    title={"Add Instance"}
                    extra={
                        <CustomButton className="close-button" type="ghost" onClick={onClose}>x</CustomButton>
                    }
                    bordered
                >
                     <Form
                        ref={this.tableRef}
                        name="Users"
                        style={{ width: "500px", margin: "auto"}}
                        // onFinish={this.handleFinish}
                        
                    >
                        <Form.Item
                            name="Availability Zone"
                            // rules={[this.formValidator.name]}
                            hasFeedback
                        >
                            <Select
                                name="az"
                                placeholder = "Select one Availability zone"
                                onChange={this.onFormFieldChange}
                            >
                                {_.get(selectedGateway, "PlatformConfig.AvailabilityZonesList", []).map(az => { return(<Option value={az}>{az}</Option>) })}
                                
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <CustomButton disabled={loading || loadingInstances} loading= {loadingInstances} type="primary" htmlType="submit" onClick={this.submitData}>
                                {"Add Instance"}
                            </CustomButton>
                        </Form.Item>
                        </Form>
                    
                </Card>
            </div>
        );
    }
}

AddInstanceModal.propTypes = {
    acctName: PropTypes.string.isRequired,
    selectedGateway: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    addData: PropTypes.func.isRequired,
    loadingInstances: PropTypes.bool.isRequired,
};

export default AddInstanceModal;