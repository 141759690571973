import { Component } from "react";
import * as Cookies from 'js-cookie';
import { SAML_ENABLED, setSession } from "../../utils/authservice";
import RoutePaths from "../../utils/RoutePaths";
import { getURLParam } from "../../utils/Utilities";
import "../../layout/Login/login.scss";
import { hardRedirect } from "../../utils/history";

class SamlCallback extends Component {
  state = {
    loggedIn: true,
  }

  componentDidMount() {
    this.handleSamlCallback();
  }

  handleSamlCallback =  async () => {
    const error = getURLParam("error") || '';
    const accessToken = Cookies.get("accessToken");
    const refreshToken = Cookies.get("refreshToken");
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    if (error) {
      hardRedirect(`${RoutePaths.login}?error=${error}`)
    } else {
      if (accessToken) {
        localStorage.setItem(SAML_ENABLED, true)
        setSession(accessToken, refreshToken);
      } else {
        hardRedirect(`${RoutePaths.login}?error=missingToken`)
      }
    }
  }

  render() {
    const style = {
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      alignItems: 'center',
      backgroundColor: 'white',
    };
    return (
      <h1 style={style}>
        Loading...
      </h1>
    );
  }
}

export default SamlCallback;