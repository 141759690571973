import { compare } from "../../../variables/general";
import { getURLParam, replaceURLParam } from "../../../utils/Utilities";
import _ from "lodash";


const typeMap = {
  0: "NONE",
  1: "GROUP",
  11: "STATIC",
  12: "GEO_IP",
  21: "DYNAMIC_VPC",
  23: "DYNAMIC_SECURITY_GROUP",
  24: "DYNAMIC_ASG",
  31: "DYNAMIC_INSTANCE",
  32: "DYNAMIC_SUBNET",
  33: "DYNAMIC_USER_DEFINED_TAG",
  34: "DYNAMIC_APPLICATIONS",
  35: "DYNAMIC_SERVICE_ENDPOINTS",
  41: "SAAS_ENDPOINTS",
};

const getObjectsCount = (d) => {
  return _.get(d, "addressGroupInfo.numberOfEntries", _.get(d, "addressGroupInfo.addressGroups", []).reduce((acc, data) => acc + (data.numberOfEntries ? data.numberOfEntries : 0), 0))
}

const getLastUpdated = (d) => {
  return new Date(new Date() - new Date(_.get(d, "seconds"))).toISOString();
}

const getType = (d) => {
  return _.get(typeMap, _.get(d, "addressGroupInfo.type", "NA"));
}

export const columns = () => [
  {
    title: 'Name',
    dataIndex: ['addressGroupInfo', 'name'],
    key: 'name',
    sortDirections: ['descend', 'ascend', 'descend'],
    sorter: (a, b) => compare(_.get(a, "addressGroupInfo.name"), _.get(b, "addressGroupInfo.name")),
    width: 240,
    defaultSortOrder: getURLParam('name'),
    onHeaderCell: () => ({
      onClick: () => replaceURLParam('name', getURLParam('name') === 'ascend' || !getURLParam('name') ? 'descend' :
        'ascend'),
    })
  },
  {
    title: 'Category',
    key: 'category',
    width: 180,
    render: (d) => (_.get(d, "addressGroupInfo.isBackendAddressGroup") ? "Reverse Proxy Target" : "Src/Dest"),
  },
  {
    title: 'Sub Objects',
    key: 'subObjects',
    sortDirections: ['descend', 'ascend', 'descend'],
    sorter: (a, b) => compare(getObjectsCount(a), getObjectsCount(b)),
    render: (d) => getObjectsCount(d),
    defaultSortOrder: getURLParam('subObjects'),
    onHeaderCell: () => ({
      onClick: () => replaceURLParam('subObjects', getURLParam('subObjects') === 'ascend' || !getURLParam('subObjects') ? 'descend' :
        'ascend'),
    }),
    width: 150,
  },
  {
    title: "Last Updated on",
    dataIndex: ['addressGroupInfo', 'lastUpdated'],
    key: 'lastUpdated',
    render: (d) => getLastUpdated(d),
    width: 180,
  },
  {
    title: "Type",
    key: 'type',
    render: (d) => getType(d),
    width: 180,
  },
];

