import React, { Component, Fragment } from 'react';
import { PostRequestResolve } from "utils/FetchHelper";
import GetRequest from "utils/FetchHelper";
import URL from "utils/url";
import ReCAPTCHA from 'react-google-recaptcha';
import { Alert, Card, Col, Row, Space, Spin, Modal } from "antd";
import _ from "lodash"
import {AccountsColumns, PrivateDeploymentColumns} from "./Columns";
import ValtixTable from "common/Table/Table";
import History, { replaceWith } from "utils/history";
import { Message } from "variables/general";
import './style.css';
import CustomButton from 'common/CustomButton/Button';
import AddTenant from './privateImage/AddTenant';

class ImageDetails extends Component {
  controller = new window.AbortController();

  recaptcharef = React.createRef();

  state = {
    imageDetails: {},
    loading: false,
    image: {},
    showAddModal: false,
    showUpdateModal: false,
    showDeleteModal: false,
    selectedRecord: undefined,
    recaptchaResponse: null,
    captchaKey: undefined,
  };

  childRef = null

  componentDidMount() {
    this.getCaptchaKey();
    let dpImage, image = {};
    if (!dpImage && History.location && History.location.state) {
      dpImage = History.location.state.dpImage;
      image = History.location.state.image;
    }
    const name = this.getImageName();
    if (dpImage && name) {
      replaceWith(
          History.location.pathname,
          {
            dpImage: dpImage,
            image: image,
          },
      );
      this.toggleLoading(true, () => {
        this.setState(prevState => ({
          ...prevState,
          image: image,
        }));
        this.getImageDetails(dpImage);
      })
    } else  {
      History.go(-1);
    }
    this.childRef = React.createRef();
  }


  componentWillUnmount() {
    this.controller.abort();
  }

  getCaptchaKey = () => {
      GetRequest(URL.getCaptchaKey, this.controller.signal)
      .then((resp) =>  {
          this.setState(prevState => ({
              ...prevState,
              captchaKey: _.get(resp, "siteKey", ""),
          }));
      }).catch((err) => {
          Message.error("Getting Captcha Key", err);
      })
  }

  getImageDetails = (dpImage) => {
    const payload = {
      dpImage: dpImage,
    };
    PostRequestResolve(URL.getImage, this.controller.signal, payload)
        .then((resp) => {
          this.setState(prevState => ({
            ...prevState, imageDetails: resp, loading: false,
          }));
        }).catch((err) => {
      this.toggleLoading();
      Message.error("Image Details", err);
    });
  }

  toggleLoading = (value = false, callback) => {
    this.setState((prevState) => ({
      ...prevState,
      loading: !!value,
    }), () => {
      typeof callback === "function" && callback();
    });
  }

  getImageName = () => {
    const { match: {params} } = this.props;
    return params.imageName;
  };

  imageColumns = [
    {
      label: "ID",
      value: "ID",
    },
    {
      label: "Name",
      value: "Name",
    },
    {
      label: "Published",
      value: (image) => image && (image.IsPrivate ? "Private" : "Public"),
    },
    {
      label: "Description",
      value: "Description",
    },
    {
      label: "Type",
      value: "Type",
    },
    {
      label: "Path",
      value: "Path",
    },
    {
      label: "Accounts",
      value: "AccountsCount",
    },
    {
      label: "Gateways",
      value: "GatewaysCount",
    },
  ]

  selectRecord = (record) => {
    this.setState(prevState => ({
        ...prevState,
        selectedRecord: record
    }));
  }



  deleteTenant = (callback) => {
    const { selectedRecord, recaptchaResponse } = this.state;
    const payload = {
      imageToDeployment: {
        serverDeployment: _.get(selectedRecord, "ServerDeployment", ""),
        imageName: _.get(selectedRecord, "ImageName", ""),
      },
      recaptchaResponse,
    };
    PostRequestResolve(URL.deletePrivateImage, this.controller.signal, payload).then((resp) => {
        Message.success("Delete Tenant", "Successfully deleted " + payload.serverDeployment);
        callback && callback();
        History.goBack();
    }).catch((err) => {
        Message.error("Delete Tenant", err);
        History.goBack();
    });
}



  openDeleteModal = () => {
    this.setState((prevState) => ({
        ...prevState,
        showDeleteModal: true,
    }));
  }

    closeDeleteModal = (confirm = false) => {
      if (confirm) {
          this.deleteTenant(() => {
              this.setState((prevState) => ({
                  ...prevState,
                  selectedRecord: null,
                  showDeleteModal: false,
                  recaptchaResponse: null,
              }));
              this.recaptcharef && this.recaptcharef.current && this.recaptcharef.current.reset();
          })
      } else {
          this.setState((prevState) => ({
              ...prevState,
              showDeleteModal: false,
              recaptchaResponse: null,
          }));
          this.recaptcharef && this.recaptcharef.current && this.recaptcharef.current.reset();
      }
  }

  handleCaptchaChange = (value) => {
    this.setState(prevState => ({
        ...prevState,
        recaptchaResponse: value,
    }));
  }

  render() {
    const { imageDetails, loading, image, showUpdateModal,
      selectedRecord, showAddModal, showDeleteModal,
      recaptchaResponse, captchaKey,
    } = this.state;
    const imageTableBody = _.get(imageDetails, "gateways", []) || [];
    const pageSize = (this.childRef && this.childRef.current && this.childRef.current.getPageSize()) || 10;
    const gatewaysUsingImage = _.map(imageTableBody.filter(f => f.account === _.get(selectedRecord, "ServerDeployment", "")), "gateway");
    return (
      <Fragment>
        <Row>
          <Col span={24}>
            { loading && (
                <Spin tip="Loading...">
                  <Alert
                      message="Image Details"
                      description={this.getImageName()}
                      type="info"
                  />
                </Spin>
            )}
            { !loading && (
                <Space direction="vertical" style={{width: "100%"}}>
                  <CustomButton type="primary" onClick={()=>History.goBack()}>Go Back</CustomButton>
                  <Card title="Image Registry Details">
                      <div style={{border:"1px solid rgb(220, 219, 236)", borderBottom:0}}>
                        {this.imageColumns.map(({ label, value }, index) => (
                            <Row className="vertRow" key={index}>
                              <Col className="head" span={3}>{label}</Col>
                              <Col className="value" span={21}>{image && (typeof value === "function" ? value(image) : image[value])}</Col>
                            </Row>
                        ))
                        }
                      </div>
                      <Space 
                        direction="vertical"
                        size="middle"
                        style={{width: "100%"}}
                      >
                        <Card title="Private Deployments">
                        {_.get(image, "IsPrivate", false)
                        && (<Space
                              direction="horizontal"
                              size="middle"
                              style={{width: "100%"}}
                            >
                              <CustomButton
                                type="primary"
                                onClick={() => this.setState({ showAddModal: true })}
                              >
                                  Add
                              </CustomButton>
                              <CustomButton
                                type="primary"
                                onClick={() => this.setState({ showUpdateModal: true })}
                                disabled={!selectedRecord}
                              >
                                  Update
                              </CustomButton>
                              <CustomButton
                                type="danger"
                                onClick={this.openDeleteModal}
                                disabled={!selectedRecord}
                              >
                                  Delete
                              </CustomButton>
                            </Space>
                          )}
                          {showAddModal && <AddTenant image={image} onClose={() => this.setState({ showAddModal: false })} selectedRecord={selectedRecord} />}
                          {showUpdateModal && <AddTenant image={image} isEdit onClose={() => this.setState({ showUpdateModal: false })} selectedRecord={selectedRecord} />}
                          <ValtixTable
                              name="privateDeployments"
                              onRowSelect={this.selectRecord}
                              enableSingleRowSelection
                              data={(_.get(image, "DeploymentsList", []) || []).map((m, ID) => ({...m, ID}))}
                              columns={PrivateDeploymentColumns()}
                              style={{ maxHeight: "unset" }}
                          />
                        </Card>
                      </Space>
                      <ValtixTable
                          name="imageDetails"
                          loading={loading}
                          data={imageTableBody}
                          columns={AccountsColumns(pageSize)}
                          style={{ maxHeight: "unset" }}
                          ref={this.childRef}
                      />
                    </Card>
                </Space>
            )}
          </Col>
        </Row>
        <Modal
          title="Delete Tenant"
          visible={showDeleteModal}
          okText="Delete"
          okButtonProps={{
            danger: true,
            disabled: !recaptchaResponse,
          }}
          cancelButtonProps={{
            disabled: loading,
          }}
          onOk={() => this.closeDeleteModal(true)}
          onCancel={() => this.closeDeleteModal(false)}
          confirmLoading={loading}
        >
          {gatewaysUsingImage.length > 0 ? <span><b>{gatewaysUsingImage.join(",")}</b> are using image.</span>: ""}
          Are you sure. Do you want to delete <b>{_.get(selectedRecord, "ServerDeployment", "")}</b>?
          {(captchaKey) && (
              <ReCAPTCHA
                  ref={this.recaptcharef}
                  sitekey={captchaKey}
                  onChange={this.handleCaptchaChange}
              />
          )}
        </Modal>
      </Fragment>
    );
  }
}

export default ImageDetails;