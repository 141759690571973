import {
    Select, Space, Tabs, List,
    Input, Typography
} from 'antd';
import React, { Component, Fragment } from 'react';
import CustomButton from '../../common/CustomButton/Button';
import SearchBar from '../../common/SearchBar/Search';
import { Message } from "../../variables/general";
import { filterList } from "variables/general";
import { replaceURLParam, getURLParam, deleteURLParam, checkValidJSON } from "../../utils/Utilities";
import ValtixTable from '../../common/Table/Table';
import dayjs from 'dayjs';
import { PostRequestResolve } from '../../utils/FetchHelper';
import { getColumns, quarantineColMap } from './Columns';
import _ from 'lodash';
import URL from '../../utils/url';
import Modal from 'antd/lib/modal/Modal';
import FontAwesome from 'react-fontawesome';
import { DownloadCsv } from 'utils/csvUtils';
import { countryCodesMap } from '../../utils/18utils';
import "./userQuarantine.scss";
const { TextArea } = Input;
const { TabPane } = Tabs

const DEFAULT_REJECT_MESSAGE = "Thank you for your interest in the Valtix Free Tier.  We are unable to approve your request.  For further information and assistance, please contact Valtix at info@valtix.com. Thank you.";
class UserQuarantine extends Component {

    state = {
        users: [],
        loading: false,
        selectedRecord: [],
        filteredData: [],
        modalAction: "",
        selectedReason: DEFAULT_REJECT_MESSAGE,
        reason: DEFAULT_REJECT_MESSAGE,
        excludeDomains: []
    };

    controller = new window.AbortController()


    componentDidMount() {
        this.getRecords(() => {
            this.setState((prevState) => ({
                ...prevState,
                filteredData: filterList(prevState.users, getURLParam("query")),
            }));
        });
    }

    componentWillUnmount() {
        this.controller.abort();
    }

    toggleLoading = (callback) => {
        this.setState((prevState) => ({
            ...prevState,
            loading: !prevState.loading,
        }), () => {
            typeof callback === "function" && callback();
        });
    }



    getRecords = (callback) => {
        this.toggleLoading(() => {
            PostRequestResolve(URL.listUserQuarantine, this.controller.signal, {})
                .then((resp) => {
                    const users = _.get(resp, "users", []).map(user => {
                        user.creationDate = dayjs(user.CreatedAt).format("DD-MMM-YYYY HH:mm:ss");
                        user.id = _.get(user, "ID", "");
                        user.ID = _.get(user, "Email", "");
                        user.orgCode = "valtix";
                        if (user.RequestBody && checkValidJSON(user.RequestBody)) {
                            const requestBody = JSON.parse(_.get(user, "RequestBody", ""));
                            user.firstName = _.get(requestBody, "firstName", "");
                            user.lastName = _.get(requestBody, "lastName", "");
                            user.country = _.get(requestBody, "country", "");
                            user.countryCode = _.get(countryCodesMap, user.country, user.country);
                            user.company = _.get(requestBody, "company", "");
                            user.ipAddress = _.get(requestBody, "ipAddress", "");
                            user.remoteAddr = _.get(requestBody, "remoteAddr", "");
                            user.phoneNumber = _.get(requestBody, "phoneNumber", "");
                        };
                        return user;
                    });

                    this.setState(prevState => ({
                        ...prevState,
                        users: users,
                        filteredData: users,
                        excludeDomains: _.get(resp, "excludeDomains", []),
                    }));
                    this.toggleLoading(callback);
                }).catch((err) => {
                    this.toggleLoading();
                    Message.error("List Quarantine Users", err);
                })
        })

    }

    deleteOrApproveUser = (callback, errorCallback) => {
        const { selectedRecord, modalAction, reason } = this.state;
        const actionDeleteOrReject = modalAction === "delete" || modalAction === "reject";
        const url = actionDeleteOrReject ? URL.deleteUserQuarantine : URL.approveUserQuarantine;
        const payload = {
            email: _.map(selectedRecord, "Email", ""),
            reason,
        };
        if (!payload.email) {
            typeof errorCallback === "function" && errorCallback();
        }
        this.toggleLoading(() => {
            PostRequestResolve(url, this.controller.signal, payload).then((resp) => {
                Message.success(`${actionDeleteOrReject ? "Delete" : "Approve"} User`, `Successfully ${actionDeleteOrReject ? "deleted" : "approved"} ` + payload.email);
                this.toggleLoading(callback);
            }).catch((err) => {
                this.toggleLoading(errorCallback);
                Message.error(`${actionDeleteOrReject ? "Delete" : "Approve"} User`, err);
            });
        });

    }

    onSearch = (value, callback) => {
        const { users } = this.state;
        this.setState((prevState => ({
            ...prevState,
            filteredData: filterList(users, value),
            selectedRecord: [],
        })), () => {
            if (value) replaceURLParam("query", value);
            else {
                deleteURLParam("query");
            }
            callback && callback();
        });
    }

    selectRecord = (selectedRecord) => {
        this.setState(prevState => ({
            ...prevState,
            selectedRecord
        }));
    }

    openModal = (modalAction) => {
        this.setState((prevState) => ({
            ...prevState,
            modalAction: modalAction,
            showModal: true,
        }));
    }



    closeModal = (confirm = false) => {
        if (confirm) {
            this.deleteOrApproveUser(() => {
                this.setState((prevState) => ({
                    ...prevState,
                    selectedRecord: null,
                    showModal: false,
                    modalAction: "",
                    selectedReason: DEFAULT_REJECT_MESSAGE,
                    reason: DEFAULT_REJECT_MESSAGE,
                }), () => {
                    this.getRecords(() => {
                        this.setState((prevState) => ({
                            ...prevState,
                            filteredData: filterList(prevState.users, getURLParam("query")),
                        }));
                    });
                });
            })
        } else {
            this.setState((prevState) => ({
                ...prevState,
                showModal: false,
                modalAction: "",
                selectedReason: DEFAULT_REJECT_MESSAGE,
                reason: DEFAULT_REJECT_MESSAGE,
            }));
        }
    }

    onDownload = () => {
        const { users } = this.state;
        const headers = getColumns().map(v => v.key);
        DownloadCsv(users, headers, "quarantinedUsers", quarantineColMap, true);
    }

    rejectionOptions = [
        { label: "Default", value: DEFAULT_REJECT_MESSAGE },
        { label: "Please use a business email address to signing up.", value: "Please use a business email address to signing up." },
        { label: "Please contact Valtix support (support@valtix.com) for signing up.", value: "Please contact Valtix support (support@valtix.com) for signing up." },
    ]

    render() {
        const {
            filteredData, loading, selectedRecord, showModal, modalAction,
            reason, selectedReason, excludeDomains,
        } = this.state;
        const modalTitle = (modalAction === "reject" && "Reject User") || `${modalAction === "delete" ? "Delete" : "Approve"} User`;
        const modalOkText = `${modalAction === "delete" || modalAction === "reject" ? "Delete" : "Approve"}`;
        const danger = modalAction === "delete" || modalAction === "reject";
        const userEmail = _.get(selectedRecord, "Email", "");
        const disabled = (selectedRecord || []).length === 0;

        return (
            <Fragment>
                <SearchBar
                    placeholder="Search"
                    allowClear
                    width={400}
                    onSearch={this.onSearch}
                />
                <div style={{ marginTop: "8px" }}>
                    <Space>
                        <CustomButton
                            type="primary"
                            onClick={() => this.openModal("approve")}
                            disabled={disabled}
                            enableOnlyForAdmin
                        >Approve
                        </CustomButton>
                        <CustomButton
                            type="primary"
                            danger
                            onClick={() => this.openModal("delete")}
                            disabled={disabled}
                            enableOnlyForAdmin
                        >
                            Delete
                        </CustomButton>
                        <CustomButton
                            type="primary"
                            danger
                            onClick={() => this.openModal("reject")}
                            disabled={disabled}
                            enableOnlyForAdmin
                        >
                            Reject
                        </CustomButton>
                    </Space>
                </div>
                <Tabs defaultActiveKey="quarantine" className="quarantine-tabs-container">
                    <TabPane tab="User Quarantine" key="quarantine">
                        <ValtixTable
                            className="quarantine-tab"
                            title={() => (<div style={{ display: 'flex' }}><span style={{ flex: '1' }}>User Quarantine</span><FontAwesome name="download" className="csv-download-button" onClick={this.onDownload}></FontAwesome></div>)}
                            name="listAccounts"
                            loading={loading}
                            data={filteredData}
                            columns={getColumns()}
                            onRowSelect={this.selectRecord}
                            rowKey="ID"
                            enableMultiRowSelection
                        />
                    </TabPane>
                    <TabPane tab="Blocked Domains" key="blockedDomains">
                        <List
                            size="small"
                            className="blocked-domains-tab"
                            header={<div className="blocked-domains-heading">Blocked Domains</div>}
                            bordered
                            dataSource={excludeDomains}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                    </TabPane>
                </Tabs>
                <Modal
                    title={modalTitle}
                    visible={showModal}
                    okText={modalOkText}
                    okButtonProps={{
                        danger: danger,
                        disabled: loading || (!reason && modalAction === "reject"),
                    }}
                    cancelButtonProps={{
                        disabled: loading,
                    }}
                    onOk={() => this.closeModal(true)}
                    onCancel={() => this.closeModal(false)}
                    confirmLoading={loading}
                >
                    {modalAction === "reject" &&
                        <div>
                            <Typography>
                                Select Reason
                            </Typography>
                            <Select
                                showSearch
                                name="selectedReason"
                                onChange={(value) => { this.setState({ selectedReason: value, reason: value }) }}
                                value={selectedReason}
                                size="large"
                                style={{ width: 400 }}
                                options={this.rejectionOptions}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                            />
                            <br />
                            <br />
                            <Typography>
                                Custom Reason
                            </Typography>
                            <TextArea
                                value={reason}
                                onChange={({ target: { value } }) => { this.setState({ reason: value }) }}
                                autoSize={{ minRows: 2 }}
                            />
                            <br />
                            <br />
                        </div>
                    }
                    Are you sure. Do you want to {modalAction} <b>{userEmail}</b>?
                </Modal>
            </Fragment>
        );
    }
}

export default UserQuarantine;
