import React, { Component } from 'react';
import { Card, Form, Input } from "antd";
import CustomButton from "../../../common/CustomButton/Button";
import { PostRequestResolve } from "../../../utils/FetchHelper";
import URL from "../../../utils/url";
import { UserOutlined } from '@ant-design/icons';
import './users.scss';
import { Message } from "../../../variables/general";
import PropTypes from "prop-types";

class MigrateSmalUsers extends Component {
  state = {
    formData: {
      organization: undefined,
    },
    validations: [],
    showModal: false,
    loading: false,
    modal: { status: "", message: "" },
  };

  formRef = React.createRef()

  formValidator = {
    organization: {
      required: true, message: 'Organization must not be empty!'
    },
  }

  controller = new window.AbortController();

  componentWillUnmount() {
    this.controller.abort();
  }


  onFormFieldChange = (e) => {
    const { name, type } = e.target;
    if (type === 'checkbox') {
      const { checked } = e.target;
      this.setState(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          [name]: checked,
        }
      }));
    } else {
      const { value } = e.target;
      this.setState(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          [name]: value,
        }
      }));
    }
  };

  onClose = () => {
    const { onClose } = this.props;
    const { modal: { status } } = this.state;
    this.setState({ showModal: false }, () => status === "success" && onClose && onClose(true));
  }

  migrateSmalUsers = () => {
    const { account } = this.props;
    const { formData } = this.state;
    const { organization } = formData;
    const payload = {
      acctName: account,
      organization,
    };
    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }), () => {
      PostRequestResolve(URL.migrateSamlUsers, this.controller.signal, payload)
        .then((resp) => {
          this.setState((prevState) => ({
            ...prevState,
            loading: false,
            modal: {
              status: "success",
              message: ""
            }
          }));
          Message.success("Migrate Users", `Users migrated successfully`);
          this.onClose()
        }).catch((err) => {
          this.setState((prevState) => ({
            ...prevState,
            loading: false,
            modal: {
              status: "error",
              message: ""
            }
          }));
          Message.error("Migrate Users", err);
        });
    });
  }

  render() {
    const { formData, loading } = this.state;
    const { onClose } = this.props;
    return (
      <div>
        <Card
          className="create-modal"
          title="Migrate Users"
          extra={
            <CustomButton className="close-button" type="ghost" onClick={onClose}>x</CustomButton>
          }
          bordered
        >
          <Form
            ref={this.formRef}
            name="migrateUsers"
            style={{ width: "500px", margin: "auto" }}
            onFinish={this.migrateSmalUsers}
            initialValues={{ ...formData }}
          >
            <Form.Item
              label="New Organization"
              name="organization"
              rules={[this.formValidator.organization]}
              hasFeedback
            >
              <Input
                name="organization"
                placeholder="Organization"
                onChange={this.onFormFieldChange}
                prefix={<UserOutlined className="form-item-icon" />}
              />
            </Form.Item>
            <Form.Item>
              <CustomButton disabled={loading} loading={loading} type="primary" htmlType="submit">
                Migrate
              </CustomButton>
            </Form.Item>

          </Form>
        </Card>
      </div>
    );
  }
}

MigrateSmalUsers.propTypes = {
  account: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MigrateSmalUsers;