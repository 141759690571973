import React, { Component, Fragment } from 'react';
import URL from "utils/url";
import ValtixTable from '../../../common/Table/Table';
import { Modal, Row, Space } from 'antd';
import CustomButton from '../../../common/CustomButton/Button';
import { PostRequestResolve } from '../../../utils/FetchHelper';
import { Message } from '../../../variables/general';
import _ from 'lodash';
import { userColumns } from '../Columns';
import MigrateSmalUsers from './Migrate';
import CreateUser from './CreateUser';

class ListUsers extends Component {
  controller = new window.AbortController();

  state = {
    usersLoading: false,
  }

  componentDidMount() {
    this.getUsers();
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  getUsers = () => {
    this.setState({
      usersLoading: true,
    });
    const { acctName } = this.props;
    const payload = {
      acctName: acctName,
    };
    PostRequestResolve(URL.listUsers, this.controller.signal, payload)
      .then((resp) => {
        this.setState({
          users: _.get(resp, "users", []).map((user) => {
            const newUser = {
              ...user,
              role:
                _.get(user, "role") || _.get(user, "app_metadata.role_name", ""),
              ID: _.get(user, "user_id") || `${_.get(user, "email")}${_.get(user, "created_at")}`,
              invite: _.get(user, "invite", false),
              saml: _.get(user, "user_id", "").startsWith("samlp|") || _.get(user, "user_id", "").startsWith("waad|") || _.get(user, "invite", false) || _.get(user, "inviteExpired", false),
            }
            if (user.inviteExpired) {
              newUser.status = "Invite Expired";
            } else if (user.invite) {
              newUser.status = "Invite Sent";
              if (user.inviteExpired) { newUser.status = "Invite Expired"; }
            } else if (newUser.status) {
              newUser.status = "Active";
            } else {
              newUser.status = "Inactive";
            }
            return newUser;
          }),
          usersLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          usersLoading: false,
        });
        Message.error("Users", err);
      });
  };

  renewInvite = () => {
    const { acctName } = this.props;
    const { selectedUser } = this.state;
    const { name, email, mfa, role, saml } = selectedUser;
    const payload = {
      acctName: acctName,
      name: name,
      email: email,
      role: role,
      mfa: mfa,
      isSAML: saml,
    };
    PostRequestResolve(URL.createUser, this.controller.signal, payload)
      .then((resp) => {
        Message.success("Renew Invite", `Invite renewed successfully`);
      }).catch((err) => {
        Message.error("Renew Invite", err);
      });
  }

  deleteUser = () => {
    this.setState({
      deleteUserLoading: true,
    });
    const { selectedUser } = this.state;
    if (!selectedUser) return;
    const { user_id, email } = selectedUser;
    const { acctName } = this.props;
    const payload = {
      acctName: acctName,
      user_id: user_id,
      isInvite: _.get(selectedUser, "invite"),
      email: email,
    };
    PostRequestResolve(URL.deleteUser, this.controller.signal, payload)
      .then((resp) => {
        Message.success("Delete User", "Success");
        this.setState({
          showDeleteUserModal: false,
          selectedUser: null,
          deleteUserLoading: false,
        }, () => {
          this.getUsers();
        });
      })
      .catch((err) => {
        this.setState((prevState) => ({
          ...prevState,
          deleteUserLoading: false,
        }));
        Message.error("Delete User", err);
      });
  }

  selectUser = (user) => {
    this.setState({
      selectedUser: user,
    });
  };

  openEditUserModal = () => {
    this.setState({
      showEditUserModal: true,
      showCreateUserModal: false,
    });
  };

  openCreateUserModal = () => {
    this.setState({
      showCreateUserModal: true,
    });
  };

  closeCreateUserModal = (reload) => {
    this.setState({
      showCreateUserModal: false,
      showEditUserModal: false,
    });
    if (reload === true) {
      this.getUsers();
    }
  };

  openDeleteUserModal = () => {
    this.setState({
      showEditUserModal: false,
      showCreateUserModal: false,
      showDeleteUserModal: true,
    });
  };

  closeDeleteUserModal = (deleteUser) => {
    if (deleteUser === true) {
      this.deleteUser();
    } else {
      this.setState({
        showDeleteUserModal: false,
      });
    }
  };

  openMigrateSmalUsersModal = () => {
    this.setState({
      showMigrateSmalUsersModal: true,
    });
  };

  closeMigrateSmalUsersModal = () => {
    this.setState({
      showMigrateSmalUsersModal: false,
    });
  };

  render() {
    const { name, acctName,
    } = this.props;
    const {
      showDeleteUserModal, deleteUserLoading,
      showMigrateSmalUsersModal,
      showCreateUserModal, showEditUserModal,
      selectedUser, users = [], usersLoading = false,
    } = this.state;
    return (
      <Fragment>
        <Row justify="end">
          <Space>
            <CustomButton
              type="primary"
              onClick={this.openMigrateSmalUsersModal}
              enableOnlyForAdmin
            >
              Migrate SSO Users
            </CustomButton>
            <CustomButton
              type="primary"
              onClick={this.renewInvite}
              disabled={!selectedUser || selectedUser?.status !== "Invite Expired"}
              enableOnlyForAdmin
            >
              Renew Invite
            </CustomButton>
            <CustomButton
              type="primary"
              danger
              onClick={this.openDeleteUserModal}
              disabled={!selectedUser}
              enableOnlyForAdmin
            >
              Delete User
            </CustomButton>
            <CustomButton
              type="primary"
              onClick={this.openEditUserModal}
              disabled={!selectedUser}
              enableOnlyForAdmin
            >
              Edit User
            </CustomButton>
            <CustomButton
              type="primary"
              onClick={this.openCreateUserModal}
              enableOnlyForAdmin
            >
              Create User
            </CustomButton>
            <CustomButton
              type="primary"
              onClick={this.getUsers}
            >
              Refresh
            </CustomButton>
          </Space>
        </Row>
        <ValtixTable
          name={name}
          loading={usersLoading}
          data={users}
          columns={userColumns}
          style={{ maxHeight: "unset" }}
          title={() => "Users"}
          onRowSelect={this.selectUser}
          enableSingleRowSelection
        />
        {showMigrateSmalUsersModal && (
          <MigrateSmalUsers
            account={acctName}
            onClose={this.closeMigrateSmalUsersModal}
          />
        )}
        {showCreateUserModal && (
          <CreateUser
            account={acctName}
            onClose={this.closeCreateUserModal}
          />
        )}
        {showEditUserModal && (
          <CreateUser
            account={acctName}
            onClose={this.closeCreateUserModal}
            user={selectedUser}
          />
        )}
        <Modal
          title="Delete User"
          visible={showDeleteUserModal}
          okText="Delete"
          okButtonProps={{
            danger: true,
            disabled: deleteUserLoading,
          }}
          cancelButtonProps={{
            disabled: deleteUserLoading,
          }}
          onOk={() => this.closeDeleteUserModal(true)}
          onCancel={() => this.closeDeleteUserModal()}
          confirmLoading={deleteUserLoading}
        >Are you sure?</Modal>
      </Fragment>
    )
  }

}

export default ListUsers;