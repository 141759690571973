import React from "react";
import { Select } from "antd";
const Option = Select.Option;

class CustomSelect extends React.Component {
  state = {
    newOption: false,
  };

  render() {
    const { newOption } = this.state;
    const { options, onChange, name, allowNewOption = true } = this.props;
    const list1SelectOptions = options.map((o) => <Option key={o}>{o}</Option>);

    const finalProps = { ...this.props, options: undefined };

    return (
      <Select
        showSearch
        optionFilterProp="children"
        onSearch={(e) => {
          if (e.length > 0) this.setState({ newOption: e });
        }}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        {...finalProps}
        onChange={(value) => onChange({ target: { name, value } })}
      >
        { allowNewOption && newOption && options.filter((o) => o === newOption).length === 0 && (
          <Option key={newOption} value={newOption}>
            {newOption}
          </Option>
        )}
        {list1SelectOptions}
      </Select>
    );
  }
}

export default CustomSelect;
