import React, { Component } from 'react';

import IdleTimer from 'react-idle-timer'
import { isLoggedIn, logout } from './authservice';


const LAST_ACTIVE_TIME = "LAST_ACTIVE_TIME";
const ALLOWED_IDLE_TIME = 30 * 60 * 1000; // 30 mins
class AppIdleTimer extends Component {
    constructor(props){
        super(props);
        if (isLoggedIn() && this.isLastActiveExpired()) {
            this.onIdle()
        }
    }

    onIdle = () => {
        console.info("Logging out as user is idle")
        logout();
    }

    isLastActiveExpired = () => {
        let lastActiveExpired = false;
        const currentTime = new Date();
        const lastActiveItem = localStorage.getItem(LAST_ACTIVE_TIME);
        const lastActiveTime = new Date(lastActiveItem);
        const diffTime = Math.abs(currentTime.getTime() - lastActiveTime.getTime());
        if (diffTime > ALLOWED_IDLE_TIME) {
            lastActiveExpired = true;
        }
        return lastActiveExpired
    }

    updateActiveTime = () => {
        if (isLoggedIn()) {
            localStorage.setItem(LAST_ACTIVE_TIME, new Date());
        }
    }

    render() {
        const { children } = this.props;
        return (
            <IdleTimer
                onIdle={this.onIdle}
                debounce={100}
                crossTab
                timeout={ALLOWED_IDLE_TIME}
                onAction={this.updateActiveTime}
            >
                {children}
            </IdleTimer>
        )
    }
}

export default AppIdleTimer;