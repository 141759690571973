import React, { Fragment } from "react";
import { Row, Space, } from "antd";
import CustomButton from "../../../common/CustomButton/Button";
import "./AccountDetails.scss";
import { acctSettingsColumns } from "../Columns";
import ValtixTable from "../../../common/Table/Table";


class AccountSettings extends React.Component {
    componentDidMount() {
        const { getAccountSettings } = this.props;
        getAccountSettings();
    }

    render() {
        const { selectedSetting, openEditSettingModal, openCreateSettingModal, loading, accountSettings, selectSetting, openDeleteAccountSettingsModal  } = this.props;
        return (
            <Fragment>
                <Row justify="end">
                    <Space>
                        <CustomButton
                        type="primary"
                        danger
                        onClick={openDeleteAccountSettingsModal}
                        disabled={!selectedSetting}
                        enableOnlyForAdmin
                      >
                        Delete Setting
                      </CustomButton>
                        <CustomButton
                            type="primary"
                            onClick={openEditSettingModal}
                            disabled={!selectedSetting}
                            enableOnlyForAdmin
                        >
                            Edit Setting
                        </CustomButton>
                        <CustomButton
                            type="primary"
                            onClick={openCreateSettingModal}
                            enableOnlyForAdmin
                        >
                            Add Setting
                        </CustomButton>
                    </Space>
                </Row>

                <ValtixTable
                    name="accountSettings"
                    loading={loading}
                    data={accountSettings}
                    columns={acctSettingsColumns}
                    style={{ maxHeight: "unset" }}
                    title={() => "Account Settings"}
                    onRowSelect={selectSetting}
                    enableSingleRowSelection />
            </Fragment>

        );
    }

}

export default AccountSettings;

