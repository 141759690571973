import React, { Component } from 'react';
import { PostRequest } from "../../utils/FetchHelper";
import URL from "../../utils/url";
import { Card, Col, Layout, Row, DatePicker, Space, Spin } from 'antd';
import "./serverHealth.scss";
import _ from 'lodash';
import HealthChart from './healthChart';
import dayjs from "dayjs";
import { Message } from '../../variables/general';
import { getAggregationTime } from '../../utils/Utilities';
import CustomButton from '../../common/CustomButton/Button';
import DeploymentContext from '../../common/ContextWrappers/Deployment/Context';
import withContext from '../../hoc/withContext';

const { RangePicker } = DatePicker;
class ServerHealth extends Component {
    controller = new window.AbortController();
    static contextType = DeploymentContext;
    state = {
        serverHealthData: [],
        isLoading: false,
        endTime: new Date().toISOString(),
        startTime: (new Date(new Date() - (15 * 60 * 1000))).toISOString(),
        chartReady: [],
        entriesCount: 0,
        enablePrint: false,
    }
    componentDidMount() {
        const { onChangeCallback } = this.context;
        onChangeCallback(this.getHealthData)
        this.getHealthData();
    }

    componentWillUnmount() {
        this.controller.abort();
        const { clearOnChangeCallback } = this.context;
        clearOnChangeCallback();
    }


    onDeploymentSelect = (value) => {
        const { onChangeDeployment } = this.context;
        onChangeDeployment(value);
    }

    onDateChange = (dates) => {
        try {
            const startTime = dates[0].toISOString();
            const endTime = dates[1].toISOString();
            this.setState({
                startTime: startTime,
                endTime: endTime,
            }, () => {
                this.getHealthData();
            });
        } catch (err) {
            console.log(err);
        }
    }

    getHealthData = () => {
        const { startTime, endTime } = this.state;
        const { selectedDeployment } = this.context;
        if (!selectedDeployment) {
            return;
        }
        const aggreagationTime = getAggregationTime(startTime, endTime);
        this.setState((prevState) => ({
            ...prevState,
            isLoading: true,
        }))
        const payload = {
            startTime: startTime,
            endTime: endTime,
            aggregationTime: aggreagationTime,
            serverDeployment: selectedDeployment,
        };
        PostRequest(URL.getServerHealth, this.controller.signal, payload, (resp) => {
            const filteredEntries = _.get(resp, "Entries", []).filter(p => !(_.get(p, "Name") === "API Router" && (_.get(p, "Datapoints", []) || []).length === 0));
            const entriesCount = (filteredEntries || []).length;
            const serverHealthData = filteredEntries.map(profile => (
                {
                    ...profile,
                    Datapoints: [{ MetricValue: 1, Timestamp: new Date(Math.floor(new Date(startTime).getTime() / (1000 * 10)) * 1000 * 10) }, ...(_.get(profile, "Datapoints", []) || []).map(datapoint => (
                        {
                            ...datapoint,
                            Timestamp: new Date(_.get(datapoint, "Timestamp", "")),
                            MetricValue: _.get(datapoint, "MetricValue", "") > 1 ? 1 : _.get(datapoint, "MetricValue", ""),
                        }
                    )), { MetricValue: 1, Timestamp: new Date(Math.floor((new Date(endTime).getTime()) / (1000 * 10)) * 1000 * 10) }]
                }
            ))
            this.setState(prevState => ({
                ...prevState,
                serverHealthData: serverHealthData,
                isLoading: false,
                entriesCount: entriesCount,
            }));
        }, (err) => {
            this.setState({
                isLoading: false,
            });
            Message.error("Error Loading Server Health data", _.get(err, "message", ""));
        });
    }

    refreshHandle = () => {
        const endTime = new Date().toISOString();
        const startTime = (new Date(new Date() - (15 * 60 * 1000))).toISOString();
        this.setState((prevState) => ({
            ...prevState,
            startTime: startTime,
            endTime: endTime,
            entriesCount: 0,
            enablePrint: false,
            chartReady: [],
        }), this.getHealthData);
    }

    chartReady = () => {
        const { chartReady, entriesCount } =this.state;
        const readyCount = _.cloneDeep(chartReady);
        readyCount.push(true);
        this.setState(prevState => ({
            ...prevState,
            chartReady: readyCount,
            ...readyCount.length === entriesCount && { enablePrint: true },
        }));
    }

    print = () => {
        const title = document.title;
        document.title = `Server Health Report ${new Date().toISOString()}`;
        window.print();
        document.title = title;
    }
    render() {
        const { serverHealthData, isLoading, startTime, endTime, enablePrint } = this.state;
        return (
            <div id='server-health-layout'>
                <Row justify="space-between">
                    <Col>
                        <Space>
                            Time Range
                            <RangePicker
                                showTime
                                value={[dayjs(startTime), dayjs(endTime)]}
                                defaultValue={[dayjs().add(-15, "minutes").add(-1, "seconds"), dayjs()]}
                                ranges={{
                                    "Last 15 min": [dayjs().add(-15, "minutes").add(-1, "seconds"), dayjs()],
                                    "Last 1 Hour": [dayjs().add(-1, "hours").add(-1, "seconds"), dayjs()],
                                    "Last 6 Hours": [dayjs().add(-6, "hours").add(-1, "seconds"), dayjs()],
                                    "Last 1 Day": [dayjs().add(-1, "days").add(-1, "seconds"), dayjs()],
                                    "Last 1 Week": [dayjs().add(-7, "days").add(-1, "seconds"), dayjs()],
                                }}
                                onChange={this.onDateChange} />
                        </Space>
                    </Col>
                    <Row id='no-print-server-health'>
                        {enablePrint && (
                        <CustomButton
                            shape="round"
                            type="default"
                            onClick={this.print}
                        >Print
                        </CustomButton>
                        )}
                        <CustomButton
                            shape="round"
                            type="default"
                            onClick={this.refreshHandle}
                        >Refresh
                        </CustomButton>
                    </Row>
                </Row>
                <Layout className="server-health-layout">
                    {isLoading && <Spin />}
                    <Row gutter={[16, 16]}>
                        {!isLoading && serverHealthData.map((profile, key) => (
                            <Col span={12}>
                                <Card key={key} className={"health-card"} title={_.get(profile, "Name", "")}>
                                    <HealthChart
                                        data={_.get(profile, "Datapoints", []) || []}
                                        id={_.get(profile, "Name", "")}
                                        key={key}
                                        aggregationTime={getAggregationTime(startTime, endTime)}
                                        onReady={this.chartReady}
                                    />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Layout>
            </div>
        );
    }
}


export default withContext(ServerHealth, DeploymentContext);