import React from "react";
import { Table, Input } from "antd";
import "./SqlTable.scss";
import FontAwesome from "react-fontawesome";
import _ from "lodash";
import { DownloadCsv } from "../../../utils/csvUtils";


class SqlTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = { filterTable: null, columns: props.columns, baseData: props.data };
  }
  
  onChange = (e) => {
    const { value } = e.target;
    this.setState(prevState => ({
      ...prevState,
      filterTable: value,
    }), () => {
      this.processSearch();
    });
  }
  onSearch = value => {
    const { baseData } = this.state;

    const filterTable = baseData.filter(o =>
      Object.keys(o).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );

    this.setState({ filterTable });
  };
  
  downloadCsv = () => {
    const { baseData } = this.state;
    const headerKeys = _.keys(_.get(baseData, "0", []));
    DownloadCsv(baseData, headerKeys, "result");
  }

  render() {
    const { filterTable, columns, baseData } = this.state;

    return (
      <div className="table-container">
        <div style={{ display: "flex" }}>
          <Input.Search
            placeholder="Search"
            enterButton
            onSearch={this.onSearch}
            className="searchbar"
          />
          <div
              role="presentation"
              onClick={this.downloadCsv}
              className="csv-download-button"
            >
              <FontAwesome
                className="download" 
                name="download"
              />
          </div>
        </div>
        
        <Table
          columns={columns}
          className="sql-table"
          dataSource={filterTable == null ? baseData : filterTable}
          scroll={{ x: "max-content" }}
        />
      </div>
    );
  }
}

export default SqlTable;
