import { getURLParam, replaceURLParam } from "../../utils/Utilities";
import { compare } from "../../variables/general";

export const getColumns = () => [
    getColumnWithCustomSorter('Email', 'Email'),
    getColumnWithCustomSorter('First Name', 'firstName', 200, true, val => val, 'firstName'),
    getColumnWithCustomSorter('Last Name', 'lastName', 200, true, val => val, 'lastName'),
    getColumnWithCustomSorter('Company', 'company', 200, true, val => val, 'company'),
    getColumnWithCustomSorter('Country', 'country', 200, true, val => val, 'country'),
    getColumnWithCustomSorter('IP Address', 'ipAddress', 200, true, val => val, 'ipAddress'),
    getColumnWithCustomSorter('Creation Date', 'creationDate', 800, true, val => val, 'creationDate'),
    getColumnWithCustomSorter('Phone Number', 'phoneNumber', 200, true, val => val, 'phoneNumber'),
    getColumnWithCustomSorter('Remote Address', 'remoteAddr', 200, true, val => val, 'remoteAddr'),
];

const getColumnWithCustomSorter = (title, key, width, sortByCompare = false, render = (text => text), dataIndex) => ({
    title,
    dataIndex: dataIndex || title,
    key: key,
    sorter: (a, b) => sortByCompare ? compare(a[dataIndex || title], b[dataIndex || title]) : a[dataIndex || title] - b[title],
    sortDirections: ['descend', 'ascend', 'descend'],
    render: render,
    ...["name"].includes(key) ? { fixed: "left" } : {},
    width: width || 220,
    defaultSortOrder: getURLParam(key),
    onHeaderCell: () => ({
        onClick: () => replaceURLParam(key, getURLParam(key) === 'ascend' || !getURLParam(key) ? 'descend' : 
      'ascend'),
    })
})

export const quarantineColMap = {
    "orgCode": "ORG_CODE",
    "id": "PTNR_ID",
    "sourceSystemPartnerID": "SOURCE_SYSTEM_PARTNER_ID",
    "firstName": "FIRST_NAME",
    "lastName": "LAST_NAME",
    "company": "COMPANY_NAME",
    "designation": "END_USER_DESIGNATION",
    "address_line_1": "ADDRESS_LINE1",
    "address_line_2": "ADDRESS_LINE2",
    "address_line_3": "ADDRESS_LINE3",
    "address_line_4": "ADDRESS_LINE4",
    "city": "CITY",
    "stateCode": "STATE_CODE",
    "countryCode": "COUNTRY_CODE",
    "postalCode": "POSTAL_CODE",
    "Email": "EMAIL",
}

