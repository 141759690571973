import React, { Component } from 'react';

const withContext = (WrappedComponent, Context) => {
  class CompWithContext extends Component {
    render() {
      // eslint-disable-next-line react/prop-types
      const { forwardedRef, ...rest } = this.props;
      return (
        <Context.Consumer>
          {
            data => (
              <WrappedComponent
                {...data}
                {...rest}
                ref={forwardedRef} // eslint-disable-line
              />
            )
          }
        </Context.Consumer>
      );
    }
  }

  return React.forwardRef((props, ref) => <CompWithContext {...props} forwardedRef={ref} />);
};

export default withContext;
