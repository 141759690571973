import React, { Component } from 'react';
import { Select, Tooltip } from 'antd';
import withContext from '../../hoc/withContext';
import DeploymentContext from '../ContextWrappers/Deployment/Context';
import _ from "lodash";

const { Option } = Select;

class DeploymentSelect extends Component {
    static contextType = DeploymentContext;

    onDeploymentSelect = (value) => {
        const { onChangeDeployment } = this.context;
        onChangeDeployment(value);
    }

    render() {
        const { deploymentsList = [], showServerDevelopment, selectedDeployment } = this.context;
        if (!showServerDevelopment) {
            return <></>
        }
        return (
            <Tooltip
                title={showServerDevelopment && !selectedDeployment ? "Please select dashboard" : null}
                placement='bottom' open={showServerDevelopment && !selectedDeployment}
                color='red'
            >
                <Select
                    showSearch
                    placeholder={deploymentsList.length === 1 ? "Loading..." : "Select Deployment"}
                    optionFilterProp="children"
                    onChange={this.onDeploymentSelect}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ margin: "auto 10px", width: "200px" }}
                    value={selectedDeployment || undefined}
                >
                    {_.map(deploymentsList, (name) => <Option key={name} value={name}>{name}</Option>)}
                </Select>
            </Tooltip>
        )
    }
}

export default withContext(DeploymentSelect, DeploymentContext);
