import React from "react";
import { Card, Descriptions, } from "antd";
import "./AccountDetails.scss";


class DefaultSettings extends React.Component {
    render() {
        const { defaultAccountSettings } = this.props;
        return (
            <Card title="Default Settings" style={{ height: "100%" }}>
                <Descriptions bordered labelStyle={{ fontWeight: "600" }} size="small">
                    {defaultAccountSettings.map(({ Name, Value }) => (
                        <Descriptions.Item label={Name}>{Value}</Descriptions.Item>
                    ))}
                </Descriptions>
            </Card>
        );
    }
}

export default DefaultSettings;