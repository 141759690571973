import React, { Component } from "react";
import { Card, Form, Input, Select, Radio } from "antd";
import CustomButton from "../../../common/CustomButton/Button";
import { PostRequestResolve } from "../../../utils/FetchHelper";
import URL from "../../../utils/url";
import {
  Message,
  validateSelect,
} from "../../../variables/general";
import PropTypes from "prop-types";
import _ from "lodash";

class TerminateInstance extends Component {
  state = {
    formData: {
      gatewayName: this.props.selectedGateway,
      acctName: this.props.acctName,
      terminate: true,
      instanceId: undefined,
    },
    instanceIds: [],
    loading: false,
    loadingList: false,
    modal: { status: "", message: "" },
  };

  formValidator = {
    instanceId: {
      validator: (_, value) => validateSelect(value, this.state.instanceIds),
    },
  };

  controller = new window.AbortController();

  componentDidMount() {
    this.listInstanceIds();
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  onFormFieldChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  onClose = () => {
    const { onClose } = this.props;
    onClose(false);
  };

  listInstanceIds = async () => {
    try {
      this.setState({
        loadingList: true,
      })
      const { formData } = this.state;
      const { gatewayName, acctName } = formData;
      const list = await PostRequestResolve(
        URL.listGatewayInstanceIds,
        this.controller.signal,
        { gatewayName, acctName }
      );
      this.setState({
        instanceIds: _.get(list, "instanceIDs", []).map((id) => ({
          label: id,
          value: id,
        })),
        loadingList: false,
      });
    } catch (err) {
      this.setState({
        loadingList: false,
      });
      console.log(err);
    }
  };

  terminateInstance = () => {
    const { formData } = this.state;
    const { gatewayName, acctName, terminate, instanceId } = formData;
    const payload = {
      gatewayName,
      acctName,
      terminate,
      instanceId,
    };
    this.setState(
      (prevState) => ({
        ...prevState,
        loading: true,
      }),
      () => {
        PostRequestResolve(
          URL.terminateInstance,
          this.controller.signal,
          payload
        )
          .then((resp) => {
            this.setState((prevState) => ({
              ...prevState,
              loading: false,
              modal: {
                status: "success",
                message: "",
              },
            }));
            Message.success(
              "Terminate Instance",
              `Instance Terminated successfully`
            );
            this.onClose();
            // this.onClose()
            // }), () => {onClose && onClose()});
          })
          .catch((err) => {
            this.setState((prevState) => ({
              ...prevState,
              loading: false,
              modal: {
                status: "error",
                message: "",
              },
            }));
            Message.error("Terminate Instance", err);
          });
      }
    );
  };

  render() {
    const { formData, loading, instanceIds, loadingList } = this.state;
    return (
      <div>
        <Card
          className="create-modal setting"
          title="Terminate Instance"
          extra={
            <CustomButton
              className="close-button"
              type="ghost"
              onClick={this.onClose}
            >
              x
            </CustomButton>
          }
          bordered
        >
          <Form
            ref={this.formRef}
            name="Settings"
            style={{ width: "500px", margin: "auto" }}
            onFinish={this.terminateInstance}
            initialValues={{ ...formData }}
          >
            <Form.Item name="gatewayName" hasFeedback>
              <Input
                name="gatewayName"
                placeholder="gatewayName"
                disabled
                size={"large"}
              />
            </Form.Item>
            <Form.Item name="acctName" hasFeedback>
              <Input
                name="acctName"
                placeholder="acctName"
                disabled
                size={"large"}
              />
            </Form.Item>
            <Form.Item
              name="instanceId"
              rules={[this.formValidator.instanceId]}
            >
              <Select
                name="instanceId"
                placeholder="Select Instance ID"
                options={instanceIds}
                onChange={(value) =>
                  this.onFormFieldChange({
                    target: { name: "instanceId", value },
                  })
                }
                loading={loadingList}
                size={"large"}
              />
            </Form.Item>
            <Form.Item name="terminate">
              <Radio
                name="terminate"
                placeholder="terminate"
                defaultChecked
                size={"large"}
              >
                Terminate
              </Radio>
            </Form.Item>

            <Form.Item>
              <CustomButton
                disabled={loading}
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                Terminate
              </CustomButton>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

TerminateInstance.propTypes = {
  account: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setting: PropTypes.object,
};

export default TerminateInstance;
