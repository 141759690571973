import _ from "lodash";
import { StateCell } from '../../common/Table/StateCell';
import { hardRedirect } from "../../utils/history";
import RoutePaths from "../../utils/RoutePaths";
import { getURLParam, replaceURLParam } from "../../utils/Utilities";
import { compare } from "../../variables/general";

export const getColums = (showEmails) => [
    getColumnWithCustomSorter('Name', 'name', 200, true, (value) => ({
        children: value,
        props: {
            className: "link-cell",
            onClick: () => {
                hardRedirect(`${RoutePaths.getAccount}/${value}`);
            }
        }
    })),
    ...showEmails ? [getSimpleColumn("Users", 'Users', (val) => <ul>{_.map(val, e => <li>{e}</li>)}</ul>, 300)] : [],
    getColumnWithCustomSorter('ID', 'id'),
    getColumnWithCustomSorter('State', 'state', 200, true, val => <StateCell text={val}/>,),
    getColumnWithCustomSorter('Server Deployment', 'serverDeployment', 200, true, val => val, "ServerDeployment"),
    getSimpleColumnWithSort('CSP Accounts', "CSPAccountsCount", d => d, 200),
    getSimpleColumnWithSort('Gateways', "GatewaysCount", d => d, 200),
    getColumnWithCustomSorter('Date Created', 'dateCreated', 200, true, val => val === "0001-01-01T00:00:00Z" ? "" : new Date(val).toUTCString(), "CreatedTime"),
    getColumnWithCustomSorter('Company', 'company', 200, true),
    getColumnWithCustomSorter('Organization', 'organization', 200, true),
    getColumnWithCustomSorter('Description', 'description', 200, true),
];

const getColumnWithCustomSorter = (title, key, width, sortByCompare = false, render = (text => text), dataIndex) => ({
    title,
    dataIndex: dataIndex || title,
    key: key,
    sorter: (a, b) => sortByCompare ? compare(a[dataIndex || title], b[dataIndex || title]) : a[dataIndex || title] - b[title],
    sortDirections: ['descend', 'ascend', 'descend'],
    render: render,
    ...["name"].includes(key) ? { fixed: "left" } : {},
    width: width || 220,
    defaultSortOrder: getURLParam(key),
    onHeaderCell: () => ({
        onClick: () => replaceURLParam(key, getURLParam(key) === 'ascend' || !getURLParam(key) ? 'descend' : 
      'ascend'),
    })
})

const getSimpleColumnWithSort = (title, key, render = (text => text), width) => ({
    title: title,
    dataIndex: key,
    key: key,
    sorter: (a, b) => compare(a[key], b[key]),
    sortDirections: ['descend', 'ascend', 'descend'],
    render: render,
    ...["name"].includes(key) ? { fixed: "left" } : {},
    width: width || 220,
    defaultSortOrder: getURLParam(key),
    onHeaderCell: () => ({
        onClick: () => replaceURLParam(key, getURLParam(key) === 'ascend' || !getURLParam(key) ? 'descend' : 
        'ascend'),
    })

})

const getSimpleColumn = (title, key, render = (text => text), width) => ({
    title: title,
    dataIndex: key,
    key: key,
    render: render,
    width: width || 100,
})

const userIdUserSource = {
    samlp: "SAML",
    waad: "Azure AD",
  };
  
const getUserSource = (d) => {
    const userId = _.get(d, "user_id", "");
    let userSource = "LOCAL";
    Object.keys(userIdUserSource).forEach((key) => {
        if (userId.startsWith(`${key}|`)) {
        userSource = userIdUserSource[key];
        }
    });
    return userSource;
};

export const userColumns = [
    getSimpleColumnWithSort('Name', 'name'),
    getSimpleColumnWithSort('Email', 'email', (value) => {
        return value && <a href ={`mailto: ${value}`}>{value}</a>
    }),
    getSimpleColumnWithSort('Role', 'role', d => d, 180),
    getSimpleColumnWithSort('Date Added', 'created_at'),
    getSimpleColumnWithSort('Last Login', 'last_login'),
    getSimpleColumnWithSort('Last Active', 'last_active'),
    getSimpleColumn('MFA', 'mfa', d => (d ? "true" : "false")),
    getSimpleColumn('Is Logged In', 'active_sessions', d => `(${d} Sessions)`, 120),
    getSimpleColumn('Status', 'status', d => d),
    getSimpleColumn('Source', 'user_id', d => getUserSource(d)),
];

export const acctSettingsColumns = [
    getSimpleColumnWithSort('Name', 'Name'),
    getSimpleColumnWithSort('Value', 'Value'),
    getSimpleColumnWithSort('Description', 'Description'),
]
