import React, {Component, Fragment} from 'react';
import ListAccounts from "./ListAccounts";
import {Route} from "react-router-dom";
import RoutePaths from "utils/RoutePaths";
import CreateAccount from "./CreateAccount";
import AccountDetails from "./AccountDetails";

class Accounts extends Component {
    render() {
        return (
            <Fragment>
                <Route
                    exact
                    path={RoutePaths.createAccounts}
                    component={CreateAccount}
                />
                <Route
                    exact
                    path={RoutePaths.editAccount}
                    component={CreateAccount}
                />
                <Route
                    exact
                    path={RoutePaths.getAccount + "/:acctName"}
                    component={AccountDetails}
                />
                <Route
                    exact
                    path={RoutePaths.listAccounts}
                    component={ListAccounts}
                />
                <Route
                    exact
                    path={RoutePaths.defaultAccounts}
                    component={ListAccounts}
                />
            </Fragment>
        );
    }
}

export default Accounts;