import React, {Component} from 'react';
import { Card, Col, Form, Input, Row, Select, Tooltip } from "antd";
import CustomButton from '../../../common/CustomButton/Button';
import { PostRequestResolve } from '../../../utils/FetchHelper'; 
import URL from '../../../utils/url';
import './address.scss';
import '../users/users.scss';
import {
    Message,
    validateMultiSelect,
    validateName
} from "../../../variables/general";
import PropTypes from "prop-types";
import _ from 'lodash';
import { Option } from 'antd/lib/mentions';
import FontAwesome from 'react-fontawesome';


class CreateAddress extends Component {
    state = {
        formData: {
            name: "",
            description: "",
            addressGroupIDs: [],
            excludedAddressGroupIDs: [],
        },
        validations: [],
        showModal: false,
        loading: false,
        modal: { status: "", message: "" },
    };

    tableRef = React.createRef()

    componentDidMount() {
        const { selectedAddressGroup, addressList } = this.props;
        const intialAddressGroupIds = (_.get(selectedAddressGroup, "[0].addressGroupInfo.addressGroups", []).map(
            (o) => _.get(o, "name", ""))).map((e) => (_.get(_.find(addressList, (address) => (_.get(address, "addressGroupInfo.name")=== e)), "addressGroupInfo.id") ));
        const initialExcludedAddressGroupIds = (_.get(selectedAddressGroup, "[0].addressGroupInfo.excludedAddressGroups", []).map(
            (o) => _.get(o, "name", ""))).map((e) => (_.get(_.find(addressList, (address) => (_.get(address, "addressGroupInfo.name")=== e)), "addressGroupInfo.id") ));;
        if (selectedAddressGroup) {
            const formData = {
                    name: _.get(selectedAddressGroup, "[0].name", ""),
                    description: _.get(selectedAddressGroup, "[0].addressGroupInfo.description", ""),
                    addressGroupIDs: intialAddressGroupIds,
                    excludedAddressGroupIDs: initialExcludedAddressGroupIds,
            }
            this.setState({
                initialFromData: formData,
                formData: formData,
                edit : true,
                }, () => {
                    const { formData } = this.state;
                    this.tableRef.current?.setFieldsValue(formData);
            })
            
        }
    }


    controller = new window.AbortController();

    componentWillUnmount() {
        this.controller.abort();
    }

    options = this.props.addressList.map(l => { return (_.get(l, "id", "")) });

    formValidator = {
        name: {
            validator: (_, value) => validateName(value, false),
        },
        addressGroupIDs: {
            validator: (_,value) => validateMultiSelect(value, this.options ),
        },
    }

    getObjectDiff = (obj1, obj2)  => {
    const diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
            result.push(key);
        } else if (_.isEqual(obj1[key], obj2[key])) {
            const resultKeyIndex = result.indexOf(key);
            result.splice(resultKeyIndex, 1);
        }
        return result;
    }, Object.keys(obj2));

    return diff;
    }


    


    onFormFieldChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                [name]: value,
            }
        }));
    };

    onClose = () => {
        const { onClose } = this.props;
        const { modal: { status } } = this.state;
        this.setState({showModal: false}, () => status === "success" && onClose && onClose(true));
    }

    updateType = {
         UNKNOWN: 0,
		 REFRESH_LIST: 1,
		 UPDATE_VALUE: 2,
		 description: 3,
		 addressGroupIDs: 4,
		 UPDATE_TAGS: 5,
		 name: 6,
		 UPDATE_MATCH_XFF: 7,
    }

    createAddress = () => {
        const { accountName } = this.props;
        const { formData } = this.state;
        const { name, description, addressGroupIDs, excludedAddressGroupIDs } = formData;
        const payload = {
            addressGroupIDs: addressGroupIDs,
            excludedAddressGroupIDs: excludedAddressGroupIDs,
            common: {
                acctName: accountName
            },
            type: 1,
            name: name,
            description: description,
            isBackendAddressGroup: false,
        };
        
        this.setState((prevState) => ({
            ...prevState,
            loading: true,
        }), () => {
            PostRequestResolve(URL.createAddress, this.controller.signal, payload)
                .then((resp) => {
                    this.setState((prevState) => ({
                        ...prevState,
                        loading: false,
                        modal: {
                            status : "success",
                            message: ""
                        }
                    }));
                    Message.success("Create Address", `Address Group ${name} is created successfully`);
                    this.onClose();
                }).catch((err) => {
                this.setState((prevState) => ({
                    ...prevState,
                    loading: false,
                    modal: {
                        status : "error",
                        message: ""
                    }
                }));
                    Message.error("Create Address Group", err);
            });
        });
    }

    updateAddress = () => {
        const { accountName, selectedAddressGroup } = this.props;
        const { formData, initialFromData } = this.state;
        const { name, description, addressGroupIDs, excludedAddressGroupIDs } = formData;
        const typeValues = [];
        this.getObjectDiff(initialFromData, formData).forEach((key) => { typeValues.push(this.updateType[key]) });
        this.setState((prevState) => ({
            ...prevState,
            loading: true,
        }), () => {
                const updatePromises = typeValues.map((type) => {
                const payload = {
                    addressGroupIDs: addressGroupIDs,
                    excludedAddressGroupIDs: excludedAddressGroupIDs,
                    common: {
                        acctName: accountName
                    },
                    type: type,
                    name: name,
                    id: _.get(selectedAddressGroup, "[0].addressGroupInfo.id"),
                    description: description,
                    isBackendAddressGroup: false,
                }
                return PostRequestResolve(URL.updateAddress, this.controller.signal, payload)

                })
            Promise.all(updatePromises).then((resp) => {
            this.setState((prevState) => ({
                ...prevState,
                loading: false,
                modal: {
                    status: "success",
                    message: ""
                }
            }));
            Message.success("Update Address", `Address Group ${name} is updated successfully`);
            this.onClose();
        }).catch((err) => {
            this.setState((prevState) => ({
                ...prevState,
                loading: false,
                modal: {
                    status: "error",
                    message: ""
                }
            }));
            Message.error("Update Address Group", err);
        })
        }) 
    }


    handleFinish = () => {
        const { edit } = this.state;
        if (edit) {
            this.updateAddress();
            return
        }
        this.createAddress();
    }

    filterOption = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;


    render() {
        const { formData, loading, edit } = this.state;
        const { onClose, addressList } = this.props;
        return (
            <div>
                <Card
                    className="create-modal setting"
                    title={edit ? "Edit" : "Create"}
                    extra={
                        <CustomButton className="close-button" type="ghost" onClick={onClose}>x</CustomButton>
                    }
                    bordered
                >

                    <Form
                        ref={this.tableRef}
                        name="Users"
                        style={{ width: "500px", margin: "auto"}}
                        onFinish={this.handleFinish}
                        initialValues={{...formData}}
                        
                    >
                        <Form.Item
                            name="name"
                            rules={[this.formValidator.name]}
                            hasFeedback
                        >
                            <Input
                                name="name"
                                placeholder="Name must be unique"
                                onChange={this.onFormFieldChange}
                                size={"large"}
                            />
                        </Form.Item>

                        <Form.Item
                            name="description"
                            hasFeedback
                        >
                            <Input.TextArea
                                name="description"
                                placeholder="Description"
                                onChange={this.onFormFieldChange}
                                size={"large"}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Type"
                            hasFeedback
                        >
                            <Select
                                defaultValue="group"
                                disabled
                            >
                            <Select.Option value="group">group</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="addressGroupIDs"
                            rules={[
                                this.formValidator.addressGroupIDs,
                            ]}
                            hasFeedback
                        >
                            <Row gutter={8}>
                                <Col flex="auto">
                                <Select
                                    showSearch
                                    mode="multiple"
                                    name="addressGroupIDs"
                                    placeholder="Address Groups"
                                    optionFilterProp="children"
                                    onChange={(array) => {
                                        const e = {
                                            target: {
                                                name: "addressGroupIDs",
                                                value: array,
                                            }
                                        };
                                        this.onFormFieldChange(e);
                                    }}
                                    filterOption={this.filterOption}
                                >
                                {addressList.filter(d => _.get(d, "addressGroupInfo.type") !== 1).map(l => { return(<Option value={_.get(l, "id", "")}>{_.get(l, "name", "")}</Option>) })}
                                
                                </Select>
                                </Col>
                                <Col flex="none">
                                    <Tooltip title= "type GROUP are not allowed">
                                        <FontAwesome
                                        className="info"
                                        name="info"
                                        />
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item
                            name="excludedAddressGroupIDs"
                            rules={[
                                this.formValidator.addressGroupIDs,
                            ]}
                            hasFeedback
                        >
                            <Row gutter={8}>
                                <Col flex="auto">
                                    <Select
                                        showSearch
                                        mode="multiple"
                                        name="excludedAddressGroupIDs"
                                        optionFilterProp="children"
                                        placeholder = "Exclude Address Groups"
                                        onChange={(array) => {
                                            const e = {
                                                target: {
                                                    name: "excludedAddressGroupIDs",
                                                    value: array,
                                                }
                                            };
                                            this.onFormFieldChange(e);
                                        }}
                                        filterOption={this.filterOption}
                                    >
                                {addressList.filter(d => _.get(d, "addressGroupInfo.type") !== 1).map(l => { return(<Option value={_.get(l, "id", "")}>{_.get(l, "name", "")}</Option>) })}
                                
                                    </Select>
                                </Col>
                                <Col flex="none">
                                    <Tooltip title= "type GROUP are not allowed">
                                        <FontAwesome
                                        className="info"
                                        name="info"
                                        />
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item>
                            <CustomButton disabled={loading} loading={loading} type="primary" htmlType="submit">
                                {edit ? "Update" : "Create"}
                            </CustomButton>
                        </Form.Item>

                    </Form>
                </Card>
            </div>
        );
    }
}

CreateAddress.propTypes = {
    account: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.object,
};

export default CreateAddress;