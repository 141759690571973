import React, { Component } from 'react';
import { PostRequestResolve } from "utils/FetchHelper";
import URL from "utils/url";
import Table from "common/Table/Table";
import { Select, Spin, Card } from 'antd';
import { columns } from './Columns';
import CustomButton from '../../common/CustomButton/Button';
import { Message } from "../../variables/general";
import _ from 'lodash';
import withContext from '../../hoc/withContext';
import DeploymentContext from '../../common/ContextWrappers/Deployment/Context';

class RuleSet extends Component {
    state = {
        id: undefined,
        ruleSetDetails: undefined,
        vendor: undefined,
        ruleSetType: undefined,
        ruleSets: undefined,
        loadingRuleSets: false,
        loadingRuleSetDetails: false,
    };

    controller = new window.AbortController();

    componentDidMount() {
        const { onChangeCallback } = this.props;
        onChangeCallback(this.submit)
    }

    componentWillUnmount() {
        if (this.controller) this.controller.abort();
        const { clearOnChangeCallback } = this.props;
        clearOnChangeCallback && clearOnChangeCallback();
    }

    onDeploymentSelect = (value) => {
        const { onChangeDeployment } = this.props;
        onChangeDeployment && onChangeDeployment(value);
    }

    onFormFieldChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            ...prevState, [name]: value,
        }));
    };

    getRuleSets = () => {
        const { vendor, ruleSetType } = this.state;
        const payload = {
            vendor: vendor,
            ruleSetType: ruleSetType,
        }
        this.setState(prevState => ({
            ...prevState,
            ruleSets: undefined,
            loadingRuleSets: true,
        }), () => {
            PostRequestResolve(URL.listVendorRulesets, this.controller.signal, payload)
                .then((resp) => {
                    this.setState(prevState => ({
                        ...prevState, ruleSets: resp,
                        loadingRuleSets: false,
                    }));
                })
                .catch((err) => {
                    this.setState(prevState => ({
                        ...prevState,
                        loadingRuleSets: false,
                    }));
                    Message.error("Get RuleSets", err);
                });
        })
    }

    submit = () => {
        const { id, ruleSets } = this.state;
        const { selectedDeployment } = this.props;
        try {
            if (!selectedDeployment) throw new Error("Please select deployment");
            // eslint-disable-next-line no-throw-literal
            if (!id) throw new Error("Please select a valid ruleset");
            const rule = _.find(ruleSets, r => r.ID === id);
            const name = _.get(rule, "Name", "")
            if (!name) throw new Error("selected ruleset name not found");
            const payload = {
                name: name,
                serverDeployment: selectedDeployment,
            };

            this.setState(prevState => ({
                ...prevState,
                loadingRuleSetDetails: true,
            }), () => {
                PostRequestResolve(URL.getVendorRuleset, this.controller.signal, payload)
                    .then((resp) => {
                        this.setState(prevState => ({
                            ...prevState,
                            ruleSetDetails: _.get(resp, "profiles", []),
                            loadingRuleSetDetails: false,
                        }));
                    })
                    .catch((err) => {
                        this.setState(prevState => ({
                            ...prevState,
                            loadingRuleSetDetails: false,
                        }));
                        Message.error("Vendor RuleSet", err);
                    });
            });


        } catch (err) {
            Message.error("Vendor RuleSet", err);
        }
    };

    render() {
        const {
            id, ruleSetDetails, vendor, ruleSetType, ruleSets,
            loadingRuleSets, loadingRuleSetDetails,
        } = this.state;
        return (
            <div>
                <Card>
                    <div>
                        <Select
                            style={{ minWidth: '150px' }}
                            id="ruleset-type-select"
                            placeholder="RuleSet Type"
                            value={ruleSetType}
                            margin="normal"
                            name="ruleSetType"
                            onChange={(v) => {
                                this.onFormFieldChange({ target: { name: "ruleSetType", value: v } });
                                this.onFormFieldChange({ target: { name: "ruleSets", value: undefined } });
                            }}
                            options={[
                                { label: 'IPS', value: 'TYPE_IPS' },
                                { label: 'WAF', value: 'TYPE_WAF' },
                                { label: 'MALICIOUS SRC', value: 'TYPE_MALICIOUSSRC' },
                                { label: 'AV', value: 'TYPE_AV' },
                            ]}
                        />
                        <Select
                            style={{ minWidth: '150px', margin: '8px' }}
                            placeholder="Vendor"
                            id="vendor-select"
                            value={vendor}
                            name="vendor"
                            onChange={(v) => {
                                this.onFormFieldChange({ target: { name: "vendor", value: v } });
                                this.onFormFieldChange({ target: { name: "ruleSets", value: undefined } });
                            }}
                            options={[
                                { label: 'TALOS', value: 'VENDOR_TALOS' },
                                { label: 'CRS', value: 'VENDOR_CRS' },
                                { label: 'ETPRO', value: 'VENDOR_ETPRO' },
                                { label: 'TRUSTWAVE', value: 'VENDOR_TRUSTWAVE' },
                            ]}
                        />
                        <div>
                            <CustomButton shape="round"
                                type="primary" onClick={this.getRuleSets}>
                                Get RuleSets
                            </CustomButton>
                            {loadingRuleSets && (<Spin style={{ marginLeft: "20px", width: "30px", height: "30px" }} />)}
                        </div>
                    </div>
                    {ruleSets && (
                        <div>
                            <Select
                                style={{ minWidth: '150px', margin: '8px 0px' }}
                                placeholder="RuleSet"
                                required
                                id="ruleset-select"
                                value={id}
                                name="id"
                                onChange={v => this.onFormFieldChange({ target: { name: "id", value: v } })}
                                options={ruleSets.map(r => ({
                                    label: r.Version,
                                    value: r.ID,
                                }))}
                            />
                            <div style={{ alignItems: "center", display: "flex" }}>
                                <CustomButton shape="round"
                                    type="primary" onClick={this.submit}>
                                    Get Details
                                </CustomButton>
                                {loadingRuleSetDetails && (
                                    <Spin style={{ marginLeft: "20px", width: "30px", height: "30px" }} />)}
                            </div>
                        </div>
                    )}
                </Card>
                {
                    ruleSetDetails && ruleSetDetails.length > 0 && (
                        <Table
                            loading={loadingRuleSetDetails}
                            columns={columns}
                            data={ruleSetDetails}
                        />
                    )
                }
                {
                    ruleSetDetails && ruleSetDetails.length === 0 && (
                        <div>No account and profiles with id {id}</div>
                    )
                }
            </div>
        );
    }
}

export default withContext(RuleSet, DeploymentContext);