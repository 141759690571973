export const columns = () => [
  {
    title: "Date and Time",
    dataIndex: "timestamp",
    key: "timestamp",
  },
  {
    title: "Account",
    dataIndex: "acctName",
    key: "acctName"
  },
  {
    title: "Username",
    dataIndex: "userName",
    key: "userName",
  },
  {
    title: "Role Name",
    dataIndex: "roleName",
    key: "roleName", 
  },
  {
    title: "Resource Name",
    dataIndex: "resourceName",
    key: "resourceName",
  },
  {
    title: "Source IP",
    dataIndex: "ipAddress",
    key: "ipAddress",
  },
  {
    title: "Source",
    dataIndex: "source",
    key: "source",
  },
  {
    title: "Client Version",
    dataIndex: "clientVersion",
    key: "clientVersion",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
  {
    title: "Type",
    dataIndex: "eventType",
    key: "eventType",
  },
];

