import {message} from "antd";
import _ from "lodash";

const contains = (item, value) => {
    if (!value) {
        return true;
    } else if (typeof value === "string" && typeof item === "string") {
        return item.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    } else if ( typeof item === "number") {
        return item.toString().indexOf(value) !== -1;
    }
    return false;
}


export const filterList = (list, value, keys) => {
    return list.filter((item) => {
        if (typeof item === "object") {
            const keyValues = (keys || Object.keys(item)).filter(k => k !== "key");
            for (let i = 0; i < keyValues.length; i++) {
                if (contains(item[keyValues[i]], value)) {
                    return true;
                }
            }
            return false;
        }
        return contains(item, value);
    });
}


export const compare = (a, b, compareVersion = false) => {
    if (compareVersion) {
      const parsedA = parseVersion(a);
      const parsedB = parseVersion(b);
      if (parsedA < parsedB) {
        return -1;
      } else if (parsedA > parsedB) {
        return 1;
      }
    } else {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      }
    }
    return 0;
}

const parseVersion = (value) => {
    return (
      value
        .split(/[.-]/)
        .map((val) => {
          let parsedValue = val;
          try {
            let x = parseInt(val, 10);
            if (isNaN(x)) {
              parsedValue = `0`;
            } else {
              if (x > 99) {
                parsedValue = `${x}`;
              } else if (x > 9) {
                parsedValue = `0${x}`;
              } else {
                parsedValue = `00${x}`;
              }
            }
          } catch (err) {
            console.error("error while parsing ", val, " to integer");
          }
          return parsedValue;
        })
        .join("") || value
    );
}

export const validateName = (value, allowEmpty, maxLength = 64) => {
    if (!value && allowEmpty) {
        return Promise.resolve();
    } else if (!value) {
        return Promise.reject(new Error('*Value must not be empty!'));
    } else if (typeof value === "string" ) {
        if (value.length <= maxLength) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('*Value size must be less than ' + maxLength));
    }
    return Promise.reject(new Error('*Invalid value'));
}

export const validateDescription = (value, maxLength = 128) => {
    if (!value || value.length <= maxLength) {
        return Promise.resolve();
    }
    return Promise.reject(new Error('*Description size must be less than ' + maxLength));
}

export const validatePassword = (value, minLength = 8, maxLength = 128) => {
    if (value && typeof value === "string") {
        if (maxLength && value.length > maxLength) {
            return Promise.reject(new Error('*Password size must be less than ' + maxLength))
        }
        if (minLength && value.length < minLength) {
            return Promise.reject(new Error('*Password size must be greater than ' + minLength))
        }
        return Promise.resolve();
    }
    return Promise.reject(new Error('*Password is invalid'));
}

export const validateSelect = (value, options) => {
    if (value && options.some(option => option.value === value)) {
        return Promise.resolve();
    }
    return Promise.reject(new Error(
        value ? '*Invalid option selected' : '*Please select an option'
    ));
}

export const validateMultiSelect = (value, options) => {
    if (!_.isEmpty(value)) {
        return Promise.resolve();
    }
    return Promise.reject(new Error(
       '*Please select atleast one option'
    ));
}

export const validateBool = (value, mustChecked) => {
    if (typeof value === "boolean") {
        if (!mustChecked || value) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('This must be checked'));
    }
    return Promise.reject(new Error('Invalid value'));
}

export const validateEmpty = (value) => {
    if (value) {
        return Promise.resolve();
    }
    return Promise.reject(new Error('empty value'));
}

export const Message = {
    success: (title, content) => {
        message.success(`${title}: ${content}`);
    },
    error: (title, err) => {
        if (typeof err === "object" && err.name === "AbortError") {
            return;
        }
        message.error(`${title}: ${typeof err === "object" ? err.message : JSON.stringify(err)}`);
    }
}
