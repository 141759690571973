import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import Login from "./layout/Login";
import PrivateRoute from "./common/PrivateRoute";
import Callback from "./common/Callback";
import App from "./App";
import History, { redirectTo } from "./utils/history";
import { ACCESS_TOKEN_KEY, handleAuthentication } from './utils/authservice';
import AppIdleTimer from "./utils/AppIdleTimer";
import SamlCallback from "./common/SamlCallback/samlcallback";

const storageListener = (event) => {
  if (event.key === ACCESS_TOKEN_KEY) {
    if (!event.newValue) {
      redirectTo("/login");
    } else if (!event.oldValue && event.newValue && ["/login", "/"].includes(window.location.pathname)) {
      window.location.reload();
    }
  }
}

window.addEventListener('storage', storageListener)

ReactDOM.render(
  <Router history={History}>
    <AppIdleTimer>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/saml/callback" component={SamlCallback} />
        <Route path="/callback" render={(props) => {
          handleAuthentication(props);
          return <Callback {...props} />
        }} />
        <PrivateRoute path="/admin" component={App} />
        <Redirect from="/" to="/admin" />
      </Switch>
    </AppIdleTimer>
  </Router>,
  document.getElementById("root")
);
