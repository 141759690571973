import { Button, Popover, Tag } from "antd";
import { ExportOutlined } from '@ant-design/icons';

import _ from "lodash";

export const columns = [
    {
        title: "ID",
        dataIndex: "ID",
        key: "ID",
        render: (value, data) => (<>{value}&nbsp;{_.get(data, "IsDefault") ? <Tag color="green">Default</Tag> : <></>}</>)
    },
    {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
    },
    {
        title: "Links",
        key: "actions",
        render: (data) => (
            <div>
                {_.get(data, "DashboardURL") && <Button type="text" href={_.get(data, "DashboardURL")}>Dashboard <ExportOutlined/></Button>}
                {_.get(data, "SREDashboardURL") && <Button type="text" href={_.get(data, "SREDashboardURL")}>SRE <ExportOutlined/></Button>}
            </div>
        )
    },
    {
        title: "CreatedTime",
        dataIndex: "CreatedTime",
        key: "CreatedTime",
    },
    {
        title: "Description",
        dataIndex: "Description",
        key: "Description",
    },
    {
        title: "DBSecretName",
        dataIndex: "DBSecretName",
        key: "DBSecretName",
    },
    {
        title: "VersionToAccount",
        dataIndex: "VersionToAccount",
        key: "VersionToAccount",
        render: (value) => (<div style={{ width: "fit-content" }}>
            {_.map(value, (accounts = [], version) => {
                const content = _.map(accounts, (account, i) => (
                    <div>{i+1}. {account}</div>
                ))
                return (
                    <Popover content={content} title="Accounts">
                        <div>&#8226;{version || "NA"} ({_.isArray(accounts) ? accounts.length : "0"})</div>
                    </Popover>
                );
            })
            }</div>)
    },
];
