import React, {Component} from 'react';
import { Card, Checkbox, Form, Input, Select } from "antd";
import CustomButton from "../../../common/CustomButton/Button";
import { PostRequestResolve } from "../../../utils/FetchHelper";
import URL from "../../../utils/url";
import { UserOutlined, MailOutlined } from '@ant-design/icons';
import './users.scss';
import {
    Message,
    validateBool,
    validateName
} from "../../../variables/general";
import { validateSelect} from "../../../variables/general";
import PropTypes from "prop-types";
import _ from 'lodash';


class CreateUser extends Component {
    state = {
        formData: {
            name: "",
            email: "",
            role: undefined,
            mfa: false,
            saml: false,
        },
        roles: [
            {
                name: "admin super",
                value: "admin_super",
            },
            {
                name: "admin read-only",
                value: "admin_read-only",
            },
            {
                name: "admin read write",
                value: "admin_rw",
            },
        ],
        validations: [],
        showModal: false,
        loading: false,
        modal: { status: "", message: "" },
    };

    formRef = React.createRef()

    formValidator = {
        name: {
            validator: (_, value) => validateName(value, false),
        },
        email: {
            required: true, message: 'Email must not be empty!'
        },
        role: {
            validator: (_, value) => validateSelect(value, this.state.roles),
        },
        mfa: {
            validator: (_, value) => validateBool(value, false),
        },
        saml: {
            validator: (_, value) => validateBool(value, false),
        },
    }

    componentDidMount() {
        const { user } = this.props;
        if (user) {
            const formData = {
                    name: _.get(user, "name", ""),
                    email: _.get(user, "email", ""),
                    role: _.get(user, "role", ""),
                    mfa: _.get(user, "user_metadata.use_mfa", false),
                    user_id: _.get(user, "user_id")
            }
            this.setState({
                formData: formData,
                edit : true,
            }, () => {
                this.formRef.current?.setFieldsValue(formData);
            })
            
        }
    }


    controller = new window.AbortController();

    componentWillUnmount() {
        this.controller.abort();
    }


    onFormFieldChange = (e) => {
        const {name, type} = e.target;
        if (type === 'checkbox') {
            const { checked } = e.target;
            this.setState(prevState => ({
                ...prevState,
                formData: {
                    ...prevState.formData,
                    [name]: checked,
                }
            }));
        } else {
            const { value } = e.target;
            this.setState(prevState => ({
                ...prevState,
                formData: {
                    ...prevState.formData,
                    [name]: value,
                }
            }));
        }
    };

    onClose = () => {
        const { onClose } = this.props;
        const { modal: { status } } = this.state;
        this.setState({showModal: false}, () => status === "success" && onClose && onClose(true));
    }

    createUser = () => {
        const { account } = this.props;
        const { formData, edit } = this.state;
        const {name, email, mfa, role, user_id, saml} = formData;
        const payload = {
            acctName: account,
            name: name,
            email: email,
            role: role,
            mfa: mfa,
            isSAML: saml,
            ...edit? {user_id : user_id} : {},
        };
        this.setState((prevState) => ({
            ...prevState,
            loading: true,
        }), () => {
            PostRequestResolve(edit ? URL.updateUser :URL.createUser, this.controller.signal, payload)
                .then((resp) => {
                    this.setState((prevState) => ({
                        ...prevState,
                        loading: false,
                        modal: {
                            status : "success",
                            message: ""
                        }
                    }));
                    if(edit) {
                        Message.success("Update User", `User ${name} is updated successfully`);
                    } else {
                        Message.success("Create User", `User ${name} is created successfully`);
                    }
                    this.onClose()
                    // this.onClose()
                    // }), () => {onClose && onClose()});
                }).catch((err) => {
                this.setState((prevState) => ({
                    ...prevState,
                    loading: false,
                    modal: {
                        status : "error",
                        message: ""
                    }
                }));
                if(edit) {
                    Message.error("Update User", err);
                } else {
                    Message.error("Create User", err);
                }
            });
        });
    }

    render() {
        const { formData, roles, loading, edit } = this.state;
        const { onClose } = this.props;
        return (
            <div>
                <Card
                    className="create-modal"
                    title={edit ? "Edit" : "Create"}
                    extra={
                        <CustomButton className="close-button" type="ghost" onClick={onClose}>x</CustomButton>
                    }
                    bordered
                >

                    <Form
                        ref={this.formRef}
                        name="Users"
                        style={{ width: "500px", margin: "auto"}}
                        onFinish={this.createUser}
                        initialValues={{...formData}}
                        
                    >
                        <Form.Item
                            name="name"
                            rules={[this.formValidator.name]}
                            hasFeedback
                        >
                            <Input
                                name="name"
                                placeholder="Name"
                                onChange={this.onFormFieldChange}
                                size={"large"}
                                prefix={<UserOutlined className="form-item-icon" />}
                            />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            rules={[this.formValidator.email, { type: "email", message: 'Please enter valid email'}]}
                            hasFeedback
                        >
                            <Input
                                name="email"
                                placeholder="Email"
                                onChange={this.onFormFieldChange}
                                size={"large"}
                                prefix={<MailOutlined className="form-item-icon" />}
                            />
                        </Form.Item>

                        <Form.Item
                            name="role"
                            rules={[this.formValidator.role]}
                            hasFeedback
                        >
                            <Select
                                onChange={(value) => this.onFormFieldChange(
                                    {
                                        target: {
                                            name: "role",
                                            value: value,
                                        }
                                    })}
                                placeholder="Select Role"
                            >
                                {
                                    roles.map((r) => (
                                        <Select.Option key={r.value} value={r.value}>{r.name}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="mfa"
                            rules={[this.formValidator.mfa]}
                            valuePropName="checked"
                            label="MFA"
                        >
                            <Checkbox
                                name="mfa"
                                onChange={this.onFormFieldChange}
                            >
                            </Checkbox>
                        </Form.Item>
                        {!edit &&
                        <Form.Item
                            name="saml"
                            rules={[this.formValidator.saml]}
                            valuePropName="checked"
                            label="SSO"
                        >
                            <Checkbox
                                name="saml"
                                onChange={this.onFormFieldChange}
                            >
                            </Checkbox>
                        </Form.Item>
                        }
                        <Form.Item>
                            <CustomButton disabled={loading} loading={loading} type="primary" htmlType="submit">
                                {edit ? "Update" : "Create"}
                            </CustomButton>
                        </Form.Item>

                    </Form>
                </Card>
            </div>
        );
    }
}

CreateUser.propTypes = {
    account: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.object,
};

export default CreateUser;