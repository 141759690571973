import React, { Component, Fragment } from "react";
import { Route } from "react-router-dom";
import RoutePaths from "utils/RoutePaths";
import ListUsers from "./ListUsers";

export default class UnverifiedUsers extends Component {
  render() {
    return (
      <Fragment>
        <Route
          exact
          path={RoutePaths.unverifiedUsers}
          component={ListUsers}
        />
      </Fragment>
    );
  }
}