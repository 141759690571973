import React, { Component } from 'react';
import { Avatar, Button, Layout, Popover } from "antd";
import Title from "antd/lib/typography/Title";
import './navbar.scss';
import Logo from '../../assets/images/valtix_logo_non_linear.png';
import { logout } from '../../utils/authservice';
import RoutePaths from "../../utils/RoutePaths";
import DeploymentSelect from "../../common/SelectDeployment";
import DashboardSelect from "../../common/SelectDashboard";
import { UserOutlined } from '@ant-design/icons';
import UserInfo from './UserInfo/userinfo';
import withContext from '../../hoc/withContext';
import GlobalDashboardContext from '../../common/ContextWrappers/Dashboard/Context';


class Navbar extends Component {
    render() {
        const { isGlobalDashboard } = this.props;
        return (
            <Layout.Header className="header" style={isGlobalDashboard ? {} : { background: "#5cb4bd" }}>
                <div className="display-flex h-100">
                    <a className="navbar-title" href={isGlobalDashboard ? RoutePaths.dashboards : RoutePaths.homePage}>
                        <div className="navbar-logo">
                            <img src={Logo} alt="logo" width="40" height="30" />
                        </div>
                        {!isGlobalDashboard && <Title className="navbar-heading" level={3}>VALTIX DASHBOARD</Title>}
                    </a>
                    {isGlobalDashboard && <DashboardSelect />}
                    <span style={{ flex: 1 }} />
                    <DeploymentSelect />
                    <Button style={{ margin: 'auto' }} onClick={logout}>Logout</Button>
                    <div style={{ margin: "auto", paddingLeft: "8px" }}>
                        <Popover content={<UserInfo />} placement="bottomRight">
                            <Avatar shape="square" icon={<UserOutlined />} />
                        </Popover>
                    </div>
                </div>
            </Layout.Header>
        );
    }
}

export default withContext(Navbar, GlobalDashboardContext);