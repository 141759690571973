import { Button, Spin } from 'antd';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import auth0Login, { SAML_ENABLED, isLoggedIn } from "../../utils/authservice";
import "./login.scss";
import { getURLParam } from '../../utils/Utilities';
import GetRequest from '../../utils/FetchHelper';
import URL from '../../utils/url';
import _ from 'lodash';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: getURLParam("error") || '',
      samlLoginUrl: "",
      isAuth0Allowed: false
    }
  }

  componentDidMount() {
    this.getLoginOptions()
  }

  getLoginOptions = ()=>{
    localStorage.removeItem(SAML_ENABLED);
    this.setState({ loading: true })
    GetRequest(URL.getAuth0Settings, null).then(settings => {
      const audience = _.get(settings, "audience");
      const samlLoginUrl = _.get(settings, "samlLoginUrl");
      this.setState({
        samlLoginUrl,
        isAuth0Allowed: audience ? true : false,
        loading: false
      })
    }).catch(err => {
      console.log("Error while getting auth settings " + err);
      this.setState({
        loading: false,
        error: `${err}`
      })
    })
  }

  onLogin = () => {
    this.setState({ loading: true })
    auth0Login().catch((err) => {
      this.setState({ error: `${err}`, loading: false  })
    });
  }

  onSamlLogin = ()=>{
    const { samlLoginUrl } = this.state;
    window.location.href = samlLoginUrl;
  }

  render() {
    const { loading, error, isAuth0Allowed, samlLoginUrl } = this.state;
    if (isLoggedIn()) {
      return (
        <Redirect to="/admin" />
      );
    }
    return (
      <div className="body-bg" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div className="login-card" style={{ minWidth: '400px', padding: '40px 36px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
          <h2 style={{ textAlign: 'center', fontWeight: 'bold' }}>Admin Dashboard</h2>
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            {loading ? <Spin></Spin> : (
              <>
                <div style={{ marginBottom: '20px' }}>Please Sign In to Access the Admin Dashboard</div>
                {error && <div style={{ marginBottom: '20px', color: 'red' }}>{error}</div>}
                {isAuth0Allowed && <Button type="primary" style={{ width: '100%', marginBottom: '10px' }} onClick={this.onLogin} disabled={loading}>Login via Auth0</Button>}
                {samlLoginUrl && <Button type="ghost" style={{ width: '100%' }} onClick={this.onSamlLogin} disabled={loading}>Login Via Saml</Button>}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
