import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import DeploymentContextWrapper from './Deployment';
import DashboardContextWrapper from './Dashboard';

// Loads all the context wrappers used by the app
class ContextWrappers extends Component {
  static propTypes = {
    children: PropTypes.node,
  }

  static defaultProps = {
    children: <Fragment />,
  };

  render() {
    const { children } = this.props;
    return (
      <DashboardContextWrapper>
        <DeploymentContextWrapper>
          <Fragment key={"contextWrapper"}>
            {children}
          </Fragment>
        </DeploymentContextWrapper>
      </DashboardContextWrapper>
    );
  }
}

export default ContextWrappers;
