import React, {Component, Fragment} from 'react';
import GetRequest from "../../utils/FetchHelper";
import URL from "../../utils/url";
import RoutePaths from "../../utils/RoutePaths";
import SearchBar from "../../common/SearchBar/Search";
import ValtixTable from "../../common/Table/Table";
import {columns} from "./Columns";
import { filterList, Message } from "../../variables/general";
import { redirectTo } from "../../utils/history";
import { replaceURLParam, getURLParam, deleteURLParam } from "../../utils/Utilities";
import _ from 'lodash';

class ListImages extends Component {
    controller = new window.AbortController();

    state = {
        images: [],
        filteredImages: [],
        loadingImages: false,
    };

    componentDidMount() {
        this.getImageList(() => {
            this.setState((prevState) => ({
                ...prevState,
                filteredImages: filterList(prevState.images, getURLParam("query")),
            }));
        });
    }

    componentWillUnmount() {
        this.controller.abort();
    }

    toggleLoading = (callback) => {
        this.setState((prevState) => ({
            ...prevState,
            loadingImages: !prevState.loadingImages,
        }), () => {
            typeof callback === "function" && callback();
        })
    }

    addCustomKey = (list) => {
        const regex = /[\d]+.[\d]+-[\d]*/;
        list.forEach((d) => {
            let value = _.get(d, "Name", "");
            if (value && value.match(regex)) {
            const matched = value.match(regex);
            value = (typeof matched === "object" && matched[0]
            && typeof matched[0] === "string"
            && matched[0].split(/[.-]/).map((val) => {
                let parsedValue = val;
                try {
                const x = parseInt(val, 10);
                if (x > 99) {
                    parsedValue = `${x}`;
                } else if (x > 9) {
                    parsedValue = `0${x}`;
                } else {
                    parsedValue = `00${x}`;
                }
                } catch (err) {
                console.log("error while parsing ", val, " to integer");
                }
                return parsedValue;
            }).join("")) || value;
            }
            if (typeof d === "object") {
            d.customKey = value;
            }
        });
    }

    getImageList = (callback) => {
        this.toggleLoading(() => {
            GetRequest(URL.listImages, this.controller.signal)
                .then((resp) => {
                    const images = _.get(resp, "images", []);
                    this.addCustomKey(images);
                    this.setState(prevState => ({
                        ...prevState,
                        images: images,
                        filteredImages: images,
                    }));
                    this.toggleLoading(callback);
                }).catch((err) => {
                this.toggleLoading();
                Message.error("List Images", err);
            })
        })

    }

    onSearch = (value, callback) => {
        const { images } = this.state;
        this.setState((prevState => ({
            ...prevState,
            filteredImages: filterList(images, value),
        })), () => {
            if (value) replaceURLParam("query", value);
            else {
                deleteURLParam("query");
            }
            callback && callback();
        });
    }

    onSelectRow = (selectedRows) => {
        this.setState((prevState) => ({
            ...prevState,
            selectedRows: [...selectedRows],
        }));
    }

    render() {
        const { filteredImages, loadingImages } = this.state;
        return (
            <Fragment>
                <SearchBar
                    placeholder="Image Registry"
                    allowClear
                    width={400}
                    onSearch={this.onSearch}
                    defaultValue={getURLParam("query") || ""}
                />
                <ValtixTable
                    name="listImages"
                    loading={loadingImages}
                    data={filteredImages}
                    columns={columns()}
                    onRowClick={(d) => {
                        redirectTo (
                            `${RoutePaths.getImageRegistry}/${d.Name}`,
                            {
                                dpImage: d.Path,
                                image: d,
                            },
                        );
                    }}
                    enableHoverClass
                />
            </Fragment>
        );
    }
}

export default ListImages;