import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Checkbox } from "antd";
import './table.scss';
import { getURLParam, replaceURLParam } from '../../utils/Utilities';
import _ from 'lodash';

class ValtixTable extends Component {

    state = {
        selectedRowKeys: [],
        selectedRows: [],
        pageSize: 10,
        currentPage: 1,
    }

    componentDidMount() {
        const { data } = this.state;
        const { pageSize } = this.state;
        const count = this.getPageSize();
        let currentPage = 1;
        if (getURLParam("page")) {
            currentPage = +getURLParam("page");
        }
        if (pageSize !== count || (currentPage > 1 && data && data.length > (currentPage - 1) * pageSize)) {
            this.setState(prevState => ({
                ...prevState,
                pageSize: count,
                ...currentPage > -1 ? { currentPage: currentPage } : {},
            }));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let currentPage = 1;
        if (getURLParam("page")) {
            currentPage = +getURLParam("page");
        }
        const { data: prevData } = prevProps;
        const { data, name } = this.props;
        const { currentPage: prevPage } = prevState;  
        if (currentPage > 0 && currentPage !== prevPage
            && data && data.length > (currentPage - 1) * this.getPageSize()) {
            if (name) {
                replaceURLParam("page", currentPage);
            }
        }
        if (!_.isEqual(data, prevData)) {
            this.setState((prevState) => ({
                ...prevState,
                selectedRowKeys: [],
                selectedRows: [],
            }));
        }
    }


    onSelectChange = (selectedRowKeys, selectedRows) => {
        const { onRowSelect, enableMultiRowSelection } = this.props;
        this.setState({
            selectedRowKeys: (enableMultiRowSelection || !selectedRowKeys)
                ? selectedRowKeys
                : [selectedRowKeys[selectedRowKeys.length - 1]],
            selectedRows: (enableMultiRowSelection || !selectedRows)
                ? selectedRows
                : [selectedRows[selectedRows.length - 1]],
        }, () => {
            onRowSelect && onRowSelect(
                (enableMultiRowSelection || !selectedRows)
                    ? selectedRows
                    : selectedRows[selectedRows.length - 1],
            );
        });
    };

    onRowClick = (record) => {
        const { onRowSelect, enableMultiRowSelection } = this.props;
        const { selectedRowKeys = [], selectedRows = [] } = this.state;
        const isExisting = selectedRowKeys.find(r => r === (record || {}).key);
        let modifiedKeys = [...selectedRowKeys];
        let modifiedRows = [...selectedRows];
        if (isExisting) {
            modifiedKeys = modifiedKeys.filter(k => k !== record.key);
            modifiedRows = modifiedRows.filter(r => r.key !== record.key);
        } else if (enableMultiRowSelection) {
            modifiedKeys = [...modifiedKeys, record.key || record.id];
            modifiedRows = [...modifiedRows, record];
        } else {
            modifiedKeys = [record.key];
            modifiedRows = [record];
        }
        this.setState({
            selectedRowKeys: modifiedKeys,
            selectedRows: modifiedRows,
        }, () => {
            onRowSelect && onRowSelect(
                enableMultiRowSelection ? modifiedRows : modifiedRows[0],
            );
        });
    }

    clearAllSelections = () => {
        this.setState({
            selectedRowKeys: [],
        });
    }

    listSizeChanged = (x, listSize) => {
        this.setState(prevState => ({
            ...prevState,
            pageSize: listSize,
        }));
        replaceURLParam("size", listSize)
    }

    getPageSize = () => {
        return +getURLParam("size") === 0 ? 10 : +getURLParam("size");
    }

    onPageChange = (page) => {
        const { name } = this.props;
        if (name) {
            replaceURLParam("page", page);
        }
        this.setState(prevState => ({
            ...prevState,
            currentPage: page,
        }));
    }

    render() {
        const {
            data, columns, style, className, width, loading,
            enableMultiRowSelection, enableSingleRowSelection, onRowClick,
            size, enableHoverClass, title, rowKey
        } = this.props;
        data && data.map(d => d.key = d.ID);
        const { selectedRowKeys, pageSize, currentPage } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        }
        return (
            <Table
                style={{ width: width ? `${width}px` : "100%", minHeight: "200px", maxHeight: 550, ...style }}
                className={`custom-table ${enableHoverClass && "row-selection"} ${className}`}
                dataSource={data}
                columns={columns}
                loading={loading}
                rowKey={rowKey}
                rowSelection={(enableSingleRowSelection || enableMultiRowSelection) && {
                    type: enableMultiRowSelection ? "checkbox" : "checkbox",
                    ...enableSingleRowSelection ? { columnTitle: <Checkbox disabled /> } : {},
                    ...rowSelection,
                }}
                onRow={(...d) => ({
                    onClick: () => {
                        (enableSingleRowSelection || enableMultiRowSelection)
                            ? this.onRowClick(...d)
                            : (typeof onRowClick === "function" && onRowClick(...d));
                    },
                })}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: pageSize,
                    pageSize: pageSize,
                    onShowSizeChange: this.listSizeChanged,
                    onChange: this.onPageChange,
                    defaultPage: currentPage,
                    current: currentPage,
                }}
                bordered
                scroll={{ x: 200 }}
                size={size}
                {
                ...title ? { title: title } : {}
                }
            />
        );
    }
}

ValtixTable.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    style: PropTypes.object,
    width: PropTypes.number,
    loading: PropTypes.bool,
    enableSingleRowSelection: PropTypes.bool,
    enableMultiRowSelection: PropTypes.bool,
    onRowSelect: PropTypes.func,
    onRowClick: PropTypes.func,
    name: PropTypes.string.isRequired,
    size: PropTypes.string,
    rowKey: PropTypes.string,
    enableHoverClass: PropTypes.bool,
    route: PropTypes.string,
    title: PropTypes.func,
}

ValtixTable.defaultProps = {
    data: [],
    columns: [],
    style: {},
    width: null,
    loading: false,
    enableMultiRowSelection: false,
    enableSingleRowSelection: false,
    onRowSelect: () => { },
    onRowClick: () => { },
    rowKey: undefined,
    size: "large",
    enableHoverClass: false,
    route: "",
    title: null,
}

export default ValtixTable;
